import { useState } from "react";
import { useQueryClient } from "react-query";

import { Type_RequestConfig } from "src/api/fetch";
import { PeopleTaskAreaKeys } from "src/api/tms-scheduling/keys";
import {
    mutationCreateTaskAreaPeople,
    mutationDeleteTaskAreaPeople,
    useIndexTaskAreaPeople,
} from "src/api/tms-scheduling/peopleTaskArea";
import { updateTaskAreaIdOnShowCache } from "src/api/tms-scheduling/taskArea";
import { useProject } from "src/contexts/project";
import { PeopleTaskForm } from "src/forms/peopleTasks/PeopleTaskForm";
import { Styled_PaperContextualLayout } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

type Type_Props_PeopleTaskAreaContextualDrawer = {
    onClose: () => void;
    areaId: number;
    taskId: number;
    taskAreaId?: number;
    focus: boolean;
};

export const PeopleTaskAreaContextualDrawer = ({
    onClose,
    taskId,
    areaId,
    taskAreaId: id,
    focus = false,
}: Type_Props_PeopleTaskAreaContextualDrawer) => {
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();

    // if taskAreaId is not defined, we need to update it after attaching the first person
    const [taskAreaId, setTaskAreaId] = useState<number | undefined>(id);
    const taskAreaConfig: Type_RequestConfig = {
        taskId: taskId,
        areaId: areaId,
        taskAreaId: taskAreaId,
    };

    const { isLoading, data: peopleTaskArea } =
        useIndexTaskAreaPeople(taskAreaConfig);

    const {
        mutateAsync: createTaskAreaPerson,
        isLoading: isCreatingTaskAreaPerson,
    } = mutationCreateTaskAreaPeople(
        taskAreaConfig,
        (newTaskAreaId: number) => {
            if (newTaskAreaId !== taskAreaId) {
                setTaskAreaId(newTaskAreaId);
                // update taskAreaShow cache
                updateTaskAreaIdOnShowCache(
                    newTaskAreaId,
                    taskId,
                    areaId,
                    requestConfig,
                    queryClient,
                );
            }
        },
    );

    const { mutateAsync: removeTaskAreaPerson } =
        mutationDeleteTaskAreaPeople(taskAreaConfig);

    const invalidateIndex = [PeopleTaskAreaKeys.INDEX, taskAreaConfig];

    return (
        <Styled_PaperContextualLayout id="peopleTask">
            <PeopleTaskForm
                onClose={onClose}
                focus={focus}
                peopleTask={peopleTaskArea}
                isLoadingPeopleTask={isLoading}
                createTaskPerson={createTaskAreaPerson}
                isCreatingTaskPerson={isCreatingTaskAreaPerson}
                removeTaskPerson={removeTaskAreaPerson}
                invalidateQueryAfterCreate={invalidateIndex}
            />
        </Styled_PaperContextualLayout>
    );
};
