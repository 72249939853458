import { IconButton } from "@mui/material";
import { IconButtonProps } from "@mui/material/IconButton/IconButton";
import React, { Dispatch, SetStateAction } from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";

type Type_Props_CtaHandleModalMatrix = {
    name: string;
    setModalMatrix: Dispatch<SetStateAction<false | string>>;
    tooltipTitle: string;
    size?: IconButtonProps["size"];
};

export const CtaHandleModalMatrix = ({
    name,
    setModalMatrix,
    tooltipTitle,
    size = "small",
}: Type_Props_CtaHandleModalMatrix) => (
    <IconButton
        data-testid={`Matrix-Cta-${name}`}
        size={size}
        title={tooltipTitle}
        onClick={(e) => {
            e.stopPropagation();
            setModalMatrix(name);
        }}
        color="inherit"
    >
        <Icon variant="light" icon="table" fontSize={size} />
    </IconButton>
);
