import { useTheme } from "@mui/material";
import { useMemo } from "react";

import {
    Enum_typeChart,
    Type_PlanningWorkforceButton,
} from "src/components/Components_Teamoty/Planning/Workforce/PlanningWorkforce.type";
import { COLORS } from "src/theme/stylesheet";

import { Type_PlanningZoomButton } from "./Zooms/PlanningZoom.type";

export const pictogramCaretRight: string =
    "M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z";
export const pictogramCaretDown: string =
    "M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z";

export const pictogramMinus: string =
    "M432 256c0 13.3-10.7 24-24 24L40 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l368 0c13.3 0 24 10.7 24 24z";
export const pictogramPlus: string =
    "M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z";

export const pictogramLock: string =
    "M144 128v64H304V128c0-44.2-35.8-80-80-80s-80 35.8-80 80zM96 192V128C96 57.3 153.3 0 224 0s128 57.3 128 128v64h32c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H96zM48 256V448c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V256c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16z";
export const pictogramUnlock: string =
    "M352 128c0-44.2 35.8-80 80-80s80 35.8 80 80v72c0 13.3 10.7 24 24 24s24-10.7 24-24V128C560 57.3 502.7 0 432 0S304 57.3 304 128v64H64c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V256c0-35.3-28.7-64-64-64H352V128zM64 240H384c8.8 0 16 7.2 16 16V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V256c0-8.8 7.2-16 16-16z";

export const pictogramClose: string =
    "M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z";

export const pictogramDense: string =
    "M0 116H123.5c6.6 0 12 5.4 12 12s-5.4 12-12 12H0l0-24zm79.5 76c0-6.6 5.4-12 12-12h128c6.6 0 12 5.4 12 12s-5.4 12-12 12H91.5c-6.6 0-12-5.4-12-12zm108 52c-6.6 0-12 5.4-12 12s5.4 12 12 12h128c6.6 0 12-5.4 12-12s-5.4-12-12-12h-128zm84 76c0-6.6 5.4-12 12-12h128c6.6 0 12 5.4 12 12s-5.4 12-12 12h-128c-6.6 0-12-5.4-12-12zm108 52c-6.6 0-12 5.4-12 12s5.4 12 12 12H512V372H379.5z";
export const pictogramNormal: string =
    "M15.5 120c0-19.9 16.1-36 36-36l208 0c19.9 0 36 16.1 36 36l0 16c0 19.9-16.1 36-36 36l-208 0c-19.9 0-36-16.1-36-36l0-16zm36-12c-6.6 0-12 5.4-12 12l0 16c0 6.6 5.4 12 12 12l208 0c6.6 0 12-5.4 12-12l0-16c0-6.6-5.4-12-12-12l-208 0zm60 140c0-19.9 16.1-36 36-36l208 0c19.9 0 36 16.1 36 36l0 16c0 19.9-16.1 36-36 36l-208 0c-19.9 0-36-16.1-36-36l0-16zm36-12c-6.6 0-12 5.4-12 12l0 16c0 6.6 5.4 12 12 12l208 0c6.6 0 12-5.4 12-12l0-16c0-6.6-5.4-12-12-12l-208 0zm96 104c-19.9 0-36 16.1-36 36l0 16c0 19.9 16.1 36 36 36l208 0c19.9 0 36-16.1 36-36l0-16c0-19.9-16.1-36-36-36l-208 0zm-12 36c0-6.6 5.4-12 12-12l208 0c6.6 0 12 5.4 12 12l0 16c0 6.6-5.4 12-12 12l-208 0c-6.6 0-12-5.4-12-12l0-16z";
export const pictogramLarge: string =
    "M83.5 116c-19.9 0-36 16.1-36 36l0 47.7c0 19.9 16.1 36 36 36l208 0c19.9 0 36-16.1 36-36l0-47.7c0-19.9-16.1-36-36-36l-208 0zm-12 36c0-6.6 5.4-12 12-12l208 0c6.6 0 12 5.4 12 12l0 47.7c0 6.6-5.4 12-12 12l-208 0c-6.6 0-12-5.4-12-12l0-47.7zm140 124c-19.9 0-36 16.1-36 36l0 47.7c0 19.9 16.1 36 36 36l208 0c19.9 0 36-16.1 36-36l0-47.7c0-19.9-16.1-36-36-36l-208 0zm-12 36c0-6.6 5.4-12 12-12l208 0c6.6 0 12 5.4 12 12l0 47.7c0 6.6-5.4 12-12 12l-208 0c-6.6 0-12-5.4-12-12l0-47.7z";

export const pictogramWorkforce: string =
    "M160 80a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm0-80a56 56 0 1 1 0 112A56 56 0 1 1 160 0zM128 164l0 156 64 0 0-156c-6-2.6-12.5-4-19.3-4l-25.4 0c-6.8 0-13.3 1.4-19.3 4zm0 188l0 144c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-293.9L46.1 295.5c-4.2 7.8-13.9 10.7-21.7 6.6s-10.7-13.9-6.6-21.7L76.7 170.3c13.9-26 41-42.3 70.6-42.3l25.4 0c29.5 0 56.7 16.3 70.6 42.3l58.8 110.2c4.2 7.8 1.2 17.5-6.6 21.7s-17.5 1.2-21.7-6.6L224 202.2 224 496c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-144-64 0z";
export const pictogramSubTrades: string =
    "M240 80l0 192c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-192c0-26.5 21.5-48 48-48l64 0c26.5 0 48 21.5 48 48l0 192c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-192c0-8.8-7.2-16-16-16l-64 0c-8.8 0-16 7.2-16 16zm-64-1.3l0 34.7C109.9 143.7 64 210.5 64 288l0 32-32 0 0-32c0-95.5 59.8-177.1 144-209.3zm224 34.7l0-34.7c83.5 31.9 143 112.4 144 206.9l0 2.4 0 32-32 0 0-34.3c-.9-76.5-46.5-142.3-112-172.3zM537.3 384L38.7 384c-3.7 0-6.7 3-6.7 6.7c0 .4 0 .7 .1 1C58.5 408.5 136.6 448 288 448s229.5-39.5 255.9-56.3c0-.2 .1-.6 .1-1c0-3.7-3-6.7-6.7-6.7zM38.7 352l498.5 0c21.4 0 38.7 17.3 38.7 38.7c0 11-4.7 21.4-13.9 27.3C531.3 437.9 447.1 480 288 480s-243.3-42.1-274.1-61.9C4.7 412.1 0 401.7 0 390.7C0 369.3 17.3 352 38.7 352z";
export const pictogramCompanies: string =
    "M128 64a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm0 80a56 56 0 1 0 0-112 56 56 0 1 0 0 112zM0 280c0 43.3 26.4 80.4 64 96l0 48c0 13.3 10.7 24 24 24l80 0c13.3 0 24-10.7 24-24l0-40c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 32-64 0 0-207.6c2.6-.3 5.3-.4 8-.4l48 0c9.3 0 18.1 1.7 26.2 4.9c8.2 3.2 17.5-.9 20.7-9.1s-.9-17.5-9.1-20.7c-11.7-4.6-24.5-7.1-37.8-7.1l-48 0C46.6 176 0 222.6 0 280zm64 59.9C44.7 327 32 304.9 32 280c0-25 12.7-47 32-59.9l0 119.8zM512 64a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm0 80a56 56 0 1 0 0-112 56 56 0 1 0 0 112zM640 280c0-57.4-46.6-104-104-104l-48 0c-13.3 0-26.1 2.5-37.8 7.1c-8.2 3.2-12.3 12.5-9.1 20.7s12.5 12.3 20.7 9.1c8.1-3.2 16.9-4.9 26.2-4.9l48 0c2.7 0 5.4 .1 8 .4L544 416l-64 0 0-32c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 40c0 13.3 10.7 24 24 24l80 0c13.3 0 24-10.7 24-24l0-48c37.6-15.7 64-52.7 64-96zm-64 59.9l0-119.8C595.3 233 608 255 608 280c0 24.9-12.7 47-32 59.9zM288 96a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm96 0A64 64 0 1 0 256 96a64 64 0 1 0 128 0zm-80 96c-57.4 0-104 46.6-104 104c0 40.1 22.7 74.9 56 92.3l0 67.7c0 13.3 10.7 24 24 24l80 0c13.3 0 24-10.7 24-24l0-67.7c33.3-17.3 56-52.1 56-92.3c0-57.4-46.6-104-104-104l-32 0zm-48 50.3l0 107.3c-14.7-13.2-24-32.4-24-53.7c0-21.3 9.3-40.5 24-53.7zM288 448l0-222.2c5.1-1.2 10.5-1.8 16-1.8l32 0c5.5 0 10.9 .6 16 1.8L352 448l-64 0zm96-98.3l0-107.3c14.7 13.2 24 32.3 24 53.7c0 21.3-9.3 40.5-24 53.7z";

export const borderDashStylePlanningSequence: number[] = [3, 4];
export const borderDashStylePlanningTask: number[] = [3, 2];

export const durationPlanningDrawerTitlePictogramOver: number = 0.2;
export const durationPlanningScrollCursor: number = 0.2;

export const fontSizeButton: number = 12;
export const fontSizeDiamondDayKey: number = 10;
export const fontSizeFactorPlanningTask: number = 2.2;
export const fontSizeMaxPlanningTask: number = 6 * fontSizeFactorPlanningTask;
export const fontSizeMinPlanningTask: number = 4 * fontSizeFactorPlanningTask;

export const heightPlanningDate: number = 20;
export const heightPlanningDrawerTitle: number = 20;
export const heightPlanningMonth: number = 20;
export const heightPlanningTitle: number = 20;
export const heightPlanningWorkforceCompaniesTabLine: number = 25;
export const heightPlanningWorkforceSubTradesTabLine: number = 25;
export const hitStrokeWidth: number = 5;

export const marginPanningAreaLineBottom: number = 8;
export const marginPanningAreaLineTop: number = 8;
export const marginPlanningDiamondDayKey: number = 2;
export const marginPlanningElements: number = 5;
export const marginPlanningWorkforceCurve: number = 4;
export const marginPlanningZoom: number = 22;
export const marginPlanningZoomCursor: number = 3;
export const marginWithPlanningTaskCircle: number = 15;

export const minPlanningDist: number = 12;
export const minPlanningDrawerHeight: number = 100;

export const offsetPlanningArea: number = 12;
export const offsetPlanningTaskCircle: number = 10;
export const offsetPlanningTaskSelect: number = 1;
export const offsetPlanningTaskTitle: number = 12;

export const paddingPlanningTask: number = 2;
export const paddingPlanningWorkforceCurveLabel: number = 6;

export const pointerPlanningWorkforceCurveLabel: number = 8;

export const radiusCircleDiamondDayKey: number = 2;
export const radiusPlanningCircle: number = 4;
export const radiusPlanningCircleOver: number = 6;
export const radiusPlanningLink: number = 3;

export const sizePlanningAreaPictogram: number = 12;
export const sizePlanningButtonPictogram: number = 18;
export const sizePlanningDrawerTitlePictogram: number = 12;
export const sizePlanningLockPictogram: number = 12;
export const sizePlanningResizer: number = 50;
export const sizePlanningScroll: number = 24;
export const sizePlanningTaskSummaryTip: number = 8;
export const sizePlanningWorkforceCurveLabel: number = 30;
export const sizePlanningWorkforceCurvePoint: number = 3;
export const sizePlanningZoom: number = 200;
export const sizePlanningZoomPictogram: number = 14;

export const stepPlanningWorkforceCurve: number = 10;

export const strokeOffsetPlanningTask: number = 1;
export const strokePlanningZoomCursorWidth: number = 3;
export const strokePlanningZoomWidth: number = 3;
export const strokeWidthPlanningMilestone: number = 2;
export const strokeWidthPlanningSequence: number = 0.5;
export const strokeWidthPlanningTask: number = 1;
export const strokeWidthPlanningTaskSelected: number = 2;

export const verticalOffsetFactorPlanningTask: number = 6;

export const widthDiamondDayKey: number = heightPlanningMonth / 2;
export const widthGripPlanningTask: number = 4;
export const widthPlanningAreaCel: number = 200;
export const widthPlanningButton: number = 64;
export const widthPlanningCircleStroke: number = 1;
export const widthPlanningDateMinForDay: number = 20;
export const widthPlanningDateMinForWeek: number = 10;
export const widthPlanningDateWithNameDay: number = 36;
export const widthPlanninglink: number = 1;

interface PlanningTheme {
    backgroundColorPlanningTask: string;
    backgroundPlanningDrawerTitlePictogram: string;
    backgroundPlanningDrawerTitlePictogramOver: string;
    colorBackgroundTextDiamondDayKey: string;
    colorPanningAreaIcon: string;
    colorPanningAreaTexte: string;
    colorPanningLockIcon: string;
    colorPlanningBackground: string;
    colorPlanningButtonText: string;
    colorPlanningButtonTextSelected: string;
    colorPlanningCircleFill: string;
    colorPlanningCursorHighlight: string;
    colorPlanningDate: string;
    colorPlanningDateDayOff: string;
    colorPlanningDateWeekend: string;
    colorPlanningDateYear: string;
    colorPlanningDrawerResizerOver: string;
    colorPlanningDrawerTitle: string;
    colorPlanningDrawerTitlePictogram: string;
    colorPlanningGridBackground: string;
    colorPlanningLine: string;
    colorPlanningPunchlist: string;
    colorPlanningSequenceFill: string;
    colorPlanningSequenceFillAlpha: string;
    colorPlanningSequenceText: string;
    colorPlanningScroll: string;
    colorPlanningScrollCursor: string;
    colorPlanningScrollCursorOver: string;
    colorPlanningSelected: string;
    colorPlanningTaskSummary: string;
    colorPlanningTitleText: string;
    colorPlanningWorkforceCurveLabel: string;
    colorPlanningWorkforceCurveLine: string;
    colorPlanningWorkforceCurvePoint: string;
    colorPlanningZoom: string;
    colorPlanningZoomBackground: string;
    colorPlanningZoomCursor: string;
    colorStrokeDiamondDayKey: string;
    colorTextDiamondDayKey: string;
    fillPlanningDrawerTitle: string;
    fillPlanningLink: string;
    fillPlanningLinkOver: string;
    fillPlanningWorkforceCurveLabel: string;
    fontColorPlanningTask: string;
    strokePlanningMilestone: string;
    strokePlanningPunchlist: string;
    strokePlanningTaskDone: string;
    strokePlanningTaskDoneLine: string;
    strokePlanningTaskDoneText: string;
    transparent: string;
}

export const usePlanningTheme = (): PlanningTheme => {
    const theme = useTheme();
    return useMemo(
        () => ({
            backgroundColorPlanningTask: theme.palette.common.white,
            backgroundPlanningDrawerTitlePictogram: theme.palette.moon[200],
            backgroundPlanningDrawerTitlePictogramOver: theme.palette.moon[500],

            colorBackgroundTextDiamondDayKey: theme.palette.common.white,
            colorPanningAreaIcon: theme.palette.moon[400],
            colorPanningAreaTexte: theme.palette.common.black,
            colorPanningLockIcon: theme.palette.common.black,
            colorPlanningBackground: theme.palette.common.white,
            colorPlanningButtonText: theme.palette.common.black,
            colorPlanningButtonTextSelected: theme.palette.moon[50],
            colorPlanningCircleFill: theme.palette.common.white,
            colorPlanningCursorHighlight: theme.palette.moonAlpha[100],
            colorPlanningDate: theme.palette.common.black,
            colorPlanningDateDayOff: theme.palette.moon[100],
            colorPlanningDateWeekend: theme.palette.moon[100],
            colorPlanningDateYear: theme.palette.moon[500],
            colorPlanningDrawerResizerOver: theme.palette.common.black,
            colorPlanningDrawerTitle: theme.palette.common.black,
            colorPlanningDrawerTitlePictogram: theme.palette.common.black,
            colorPlanningGridBackground: theme.palette.moon[50],
            colorPlanningLine: theme.palette.moon[200],
            colorPlanningPunchlist: theme.palette.common.blue!,
            colorPlanningSequenceFill: theme.palette.common.blue!,
            colorPlanningSequenceFillAlpha: `${theme.palette.common.blue!}10`,
            colorPlanningSequenceText: theme.palette.common.white,
            colorPlanningScroll: theme.palette.common.white,
            colorPlanningScrollCursor: theme.palette.moon[500],
            colorPlanningScrollCursorOver: theme.palette.moon[700],
            colorPlanningSelected: theme.palette.primary.main,
            colorPlanningTaskSummary: theme.palette.common.black,
            colorPlanningTitleText: theme.palette.moon[500],
            colorPlanningWorkforceCurveLabel: theme.palette.common.black,
            colorPlanningWorkforceCurveLine: theme.palette.common.orange!,
            colorPlanningWorkforceCurvePoint: theme.palette.common.blue!,
            colorPlanningZoom: theme.palette.common.black,
            colorPlanningZoomBackground: theme.palette.common.white,
            colorPlanningZoomCursor: theme.palette.common.blue!,
            colorStrokeDiamondDayKey: theme.palette.common.black,
            colorTextDiamondDayKey: theme.palette.common.black,

            fillPlanningDrawerTitle: theme.palette.moon[200],
            fillPlanningLink: theme.palette.common.black,
            fillPlanningLinkOver: theme.palette.common.blue!,
            fillPlanningWorkforceCurveLabel: theme.palette.moon[500],

            fontColorPlanningTask: theme.palette.common.black,

            strokePlanningMilestone: theme.palette.common.black,
            strokePlanningPunchlist: theme.palette.common.white,
            strokePlanningTaskDone: theme.palette.moon[200],
            strokePlanningTaskDoneLine: theme.palette.moon[400],
            strokePlanningTaskDoneText: theme.palette.moon[400],

            transparent: COLORS.transparent,
        }),
        [theme],
    );
};

export const planningEvents: string[] = [
    "initSubProject",
    "addArea",
    "updateArea",
    "deleteArea",
    "updateSequence",
    "deleteSequence",
    "duplicateSequence",
    "updateTask",
    "removeTask",
    "addLink",
    "updateLink",
    "removeLink",
    "postTaskArea",
    "updatePlanning",
    "updatePlanningAreas",
    "updatePlanningSettings",
    "updatePlanningMatrix",
    "updateDayKeys",
    "deleteKeyDate",
    "updatePlanningFilters",
    "postPunchlist",
];

export const zoomYLevels: Array<Type_PlanningZoomButton> = [
    { pictogram: pictogramDense, size: 16 },
    { pictogram: pictogramNormal, size: 32 },
    { pictogram: pictogramLarge, size: 64 },
];

export const zoomXLevels: Array<Type_PlanningZoomButton> = [
    { text: "ButtonWeek", width: widthPlanningButton, size: 44 },
    { text: "ButtonMonth", width: widthPlanningButton, size: 24 },
    { text: "ButtonQuarter", width: widthPlanningButton, size: 10 },
    { text: "ButtonProject", width: widthPlanningButton, size: 4 },
];

export const workforceCharts: Array<Type_PlanningWorkforceButton> = [
    {
        pictogram: pictogramWorkforce,
        typeChart: Enum_typeChart.withWorkforceCurve,
        titleKey: "WorkforceCurveTitle",
        width: 320,
        height: 512,
    },
    {
        pictogram: pictogramCompanies,
        typeChart: Enum_typeChart.withWorkforceCompaniesTab,
        titleKey: "WorkforceCompaniesTabTitle",
        width: 640,
        height: 512,
    },
    {
        pictogram: pictogramSubTrades,
        typeChart: Enum_typeChart.withWorkforceSubTradesTab,
        titleKey: "WorkforceSubTradesTabTitle",
        width: 576,
        height: 512,
    },
];
