import { Stack } from "@mui/material";
import { GridRowModel } from "@mui/x-data-grid-premium";
import React, { Dispatch, SetStateAction, useState } from "react";

import {
    mutationImportAreas,
    useExportAreas,
} from "src/api/tms-projects/areas";
import { TMC_ExportImportDropDown } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import { CtaHandleModalMatrix } from "src/components/Components_Scheduling/CtaHandleModalMatrix";
import { MatrixAreasFullScreen } from "src/components/Components_Scheduling/Matrix/MatrixAreas/MatrixAreasFullScreen";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { TableAreasContainer } from "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_Areas/Table_Areas/TableAreas.container";

export const Page_Areas = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Areas",
    );
    const page = "areas";

    const [modalMatrix, setModalMatrix] = useState<string | false>(false);

    const { refetch, isFetching: isExportingAreas } = useExportAreas();

    const {
        mutateAsync: mutateImportAreas,
        error: importErrors,
        isLoading: isImportingAreas,
        reset: resetImportAreasState,
    } = mutationImportAreas();

    const handleExport = () => {
        refetch();
    };

    const [selectedArea, setSelectedArea] = useState<number | null>(null);
    const [openAreaDrawer, setOpenAreaDrawer] = useState(false);
    const [action, setAction] = useState<Type_action>("create");

    const openDrawerArea = (id: number | null, action: Type_action) => {
        setSelectedArea(id);
        setAction(action);
        setOpenAreaDrawer(true);
    };

    const handleAreaCreate = () => {
        openDrawerArea(null, "create");
    };

    const handleAreaEdit = (row: GridRowModel) => {
        openDrawerArea(row.id, "update");
    };

    const handleCloseAreaDrawer = () => {
        setOpenAreaDrawer(false);
    };

    return (
        <>
            <HeaderGrid>
                <Stack
                    direction="row"
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={2}
                >
                    <CtaHandleModalMatrix
                        setModalMatrix={() => setModalMatrix("areas")}
                        name={"areas"}
                        tooltipTitle={""}
                        size={"medium"}
                    />
                    <TMC_ExportImportDropDown
                        title={fmt(`Drawer.ImportAreas`)}
                        importTypes={["xlsx", "xls"]}
                        exportType="xlsx"
                        isLoading={isExportingAreas || isImportingAreas}
                        errors={importErrors}
                        onAbort={resetImportAreasState}
                        onExport={handleExport}
                        onImport={mutateImportAreas}
                    />
                    <AddButton
                        data-testid="Areas-AddOne-btn"
                        onClick={handleAreaCreate}
                        startIcon={<Icon variant="solid" icon="plus" />}
                        page={page}
                    />
                </Stack>
            </HeaderGrid>
            <TableAreasContainer
                page={page}
                handleAreaEdit={handleAreaEdit}
                handleAreaCreate={handleAreaCreate}
                handleCloseAreaDrawer={handleCloseAreaDrawer}
                handleOpenAreaDrawer={openDrawerArea}
                selectedArea={selectedArea}
                openAreaDrawer={openAreaDrawer}
                action={action}
            />
            {modalMatrix === "areas" && (
                <MatrixAreasFullScreen
                    page={page}
                    openDialog={true}
                    setToggleDialog={
                        setModalMatrix as Dispatch<SetStateAction<boolean>>
                    }
                    title={fmt("MatrixName")}
                />
            )}
        </>
    );
};
