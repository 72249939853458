import { formatterMultiLanguageForMatrixToFront } from "src/api/formatters";
import {
    ExtendedType_taskArea_forMatrix_data_task,
    ExtendedType_taskArea_forMatrix_dataTaskArea_task,
    Type_index_taskArea,
    Type_post_taskArea,
    Type_progress_taskArea,
    Type_sch_index_taskArea,
    Type_sch_index_taskArea_forMatrix,
    Type_sch_index_taskArea_forMatrix_task,
    Type_sch_index_taskArea_forMatrix_taskArea,
    Type_sch_post_taskArea,
    Type_sch_progress_taskArea,
    Type_sch_show_taskArea,
    Type_show_taskArea,
} from "src/api/tms-scheduling/taskArea/types";
import {
    Type_sch_index_taskArea_forMatrix_area,
    Type_sch_taskArea_forMatrix_path,
} from "src/api/types";
import {
    Type_taskArea_forMatrix,
    Type_taskArea_forMatrix_area,
    Type_taskArea_forMatrix_dataTaskArea,
} from "src/components/Components_Scheduling/Matrix/helpers/MatrixTaskAreasHelper";
import {
    formatDateForBack,
    formatDateForFront,
    formatDateTimeForBack,
    toDate,
} from "src/utils/date";

export const formatterIndexTaskAreas = (
    taskAreas: Type_sch_index_taskArea[],
): null | Type_index_taskArea[] => {
    if (!taskAreas) return null;
    return taskAreas.map(formatterIndexTaskArea);
};

export const formatterIndexTaskArea = (
    taskArea: Type_sch_index_taskArea,
): Type_index_taskArea => {
    return {
        areaId: taskArea.area_id,
        areaName: taskArea.areaName,
        areaQuantity: taskArea.areaQuantity,
        color: taskArea.color,
        companyId: taskArea.company ? taskArea.company.id : null,
        duration: taskArea.duration,
        earliestDate: formatDateForFront(taskArea.earliestDate),
        endDate: formatDateForFront(taskArea.endDate),
        ganttParent: taskArea.ganttParent,
        ganttWbsCode: taskArea.ganttWbsCode,
        id: taskArea.id,
        names: taskArea.names,
        order: taskArea.order,
        pin: taskArea.pin,
        progressLastDate: formatDateForFront(taskArea.progressLastDate),
        progressValue: taskArea.progressValue,
        sequenceName: taskArea.sequenceName,
        startDate: taskArea.startDate,
        subProject: taskArea.subProject,
        subTrade: taskArea.subTrade,
        taskId: taskArea.task_id,
        taskName: taskArea.taskName,
        team: taskArea.team,
        type: taskArea.type,
        waitingDay: taskArea.waitingDay,
        waitingDayMode: taskArea.waitingDayMode,
        withPunchlist: taskArea.withPunchlist,
        withTradeColor: taskArea.withTradeColor,
        workforce: taskArea.workforce,
    };
};

export const formatterShowTaskArea = (
    taskArea: Type_sch_show_taskArea,
): Type_show_taskArea => {
    return {
        areaId: taskArea.area_id,
        areaName: taskArea.areaName,
        areaQuantity: taskArea.areaQuantity,
        color: taskArea.color,
        company: taskArea.company ?? null,
        duration: taskArea.duration,
        earliestDate: formatDateForFront(taskArea.earliestDate),
        endDate: formatDateForFront(taskArea.endDate),
        ganttParent: taskArea.ganttParent,
        ganttWbsCode: taskArea.ganttWbsCode,
        id: taskArea.id,
        names: taskArea.names,
        order: taskArea.order,
        pin: taskArea.pin,
        progressLastDate: formatDateForFront(taskArea.progressLastDate),
        progressValue: taskArea.progressValue,
        realColor: taskArea.realColor,
        sequenceName: taskArea.sequenceName,
        startDate: formatDateForFront(taskArea.startDate),
        subProject: taskArea.subProject,
        subTrade: taskArea.subTrade,
        taskId: taskArea.task_id,
        taskName: taskArea.taskName,
        team: taskArea.team,
        type: taskArea.type,
        waitingDay: taskArea.waitingDay,
        waitingDayMode: taskArea.waitingDayMode,
        withPunchlist: taskArea.withPunchlist,
        withTradeColor: taskArea.withTradeColor,
        workforce: taskArea.workforce,
        notesCount: taskArea.notesCount,
    };
};

export const formatterShowToPostTaskArea = (
    taskArea: Type_show_taskArea,
): Type_post_taskArea => {
    return taskArea;
};

export const formatterCreateTaskArea = (
    taskArea: Type_post_taskArea,
): Type_sch_post_taskArea => {
    return {
        // HEADER
        type: taskArea.type ?? undefined,
        color: taskArea.color ?? undefined,
        withTradeColor: taskArea.withTradeColor ?? undefined,
        names: taskArea.names ?? undefined,
        // MainForm
        areaQuantity: taskArea.areaQuantity ?? undefined,
        company_id: taskArea?.company?.id ?? undefined,
        duration: taskArea.duration ?? undefined,
        earliestDate: taskArea.earliestDate
            ? formatDateForBack(taskArea.earliestDate)
            : undefined,
        endDate: taskArea.endDate
            ? formatDateForBack(taskArea.endDate)
            : undefined,
        ganttParent_id: taskArea.ganttParent?.id ?? undefined,
        ganttWbsCode: taskArea.ganttWbsCode ?? undefined,
        order: taskArea.order ?? undefined,
        pin: taskArea.pin ?? undefined,
        startDate: taskArea.startDate
            ? formatDateForBack(taskArea.startDate)
            : undefined,
        team: taskArea.team ?? undefined,
        waitingDay: taskArea.waitingDay ?? undefined,
        waitingDayMode: taskArea.waitingDayMode ?? undefined,
        workforce: taskArea.workforce ?? undefined,
        progressValue: taskArea.progressValue ?? undefined,
    };
};

export const formatterProgressTaskArea = (
    progressTaskArea: Type_progress_taskArea,
): Type_sch_progress_taskArea => {
    return {
        progressValue: progressTaskArea.progressValue,
        progressLastDate: formatDateTimeForBack(
            progressTaskArea.progressLastDate,
        )!,
    };
};

export const formatterIndexTaskAreaForMatrixArea = (
    area: Type_sch_index_taskArea_forMatrix_area,
): Type_taskArea_forMatrix_area => {
    return {
        id: area.area_id,
        deep: area.area_deep,
        order: area.order,
        path: area.path.map((level: Type_sch_taskArea_forMatrix_path) => {
            return {
                areaName: level.area_name,
                areaTypeName: level.areaType_name,
            };
        }),
    };
};

const formatterIndexTaskAreasForMatrixTask = (
    task: Type_sch_index_taskArea_forMatrix_task,
) => {
    return {
        id: task.id,
        type: task.type,
        ...formatterMultiLanguageForMatrixToFront("names", task.names),
        team: task.team,
        workforce: task.workforce,
        duration: task.duration,
        companyId: task.company_id,
        subTradeId: task.subTrade_id,
        earliestDate: toDate(task.earliestDate),
    };
};

const formatterIndexTaskAreasForMatrixTaskArea = (
    taskArea: Type_sch_index_taskArea_forMatrix_taskArea,
) => {
    return {
        id: taskArea.id,
        areaId: taskArea.area_id,
        ...formatterMultiLanguageForMatrixToFront("names", taskArea.names),
        team: taskArea.team,
        workforce: taskArea.workforce,
        duration: taskArea.duration,
        companyId: taskArea.company_id,
        subTradeId: taskArea.subTrade_id,
        earliestDate: toDate(taskArea.earliestDate),
    };
};

export const formatterIndexTaskAreasForMatrix = (
    taskAreas: Type_sch_index_taskArea_forMatrix,
): Type_taskArea_forMatrix<
    ExtendedType_taskArea_forMatrix_data_task,
    ExtendedType_taskArea_forMatrix_dataTaskArea_task
> => {
    return {
        areas: taskAreas.areas.map(formatterIndexTaskAreaForMatrixArea),
        task: formatterIndexTaskAreasForMatrixTask(taskAreas.task),
        taskAreas: taskAreas.taskAreas.map(
            formatterIndexTaskAreasForMatrixTaskArea,
        ),
    };
};

export const formatterCreateTaskAreaForMatrix = (
    taskArea: Type_taskArea_forMatrix_dataTaskArea,
): Type_sch_post_taskArea => {
    return {
        team: taskArea.team as number | undefined,
        workforce: taskArea.workforce as number | undefined,
        duration: taskArea.duration as number | undefined,
        company_id: taskArea.companyId as number | undefined,
        earliestDate:
            taskArea.earliestDate !== null
                ? formatDateForBack(taskArea.earliestDate as Date)
                : taskArea.earliestDate,
    };
};
