import { formatterIndexTaskAreaForMatrixArea } from "src/api/tms-scheduling/taskArea/formatters";
import {
    Type_index_taskAreaProductType,
    Type_post_taskAreaProductType,
    Type_post_taskAreaProductType_forFormatter,
    Type_sch_index_taskAreaProductType,
    Type_sch_index_taskAreaProductTypesMatrix,
    Type_sch_index_taskAreaProductTypesMatrix_taskAreasProductType,
    Type_sch_index_taskAreaProductTypesMatrix_taskProductType,
    Type_sch_post_taskAreaProductType,
} from "src/api/tms-scheduling/taskAreaProductTypes/types";
import { sortProductByName } from "src/api/tms-scheduling/taskProductTypes/formatter";
import {
    formatterIndexMatrix_task,
    formatterMatrix_dataColumns,
    formatterMatrix_taskAreas,
} from "src/components/Components_Scheduling/Matrix/helpers/formatters";
import {
    Type_index_dataColumns,
    Type_index_taskAreaMatrix_data,
} from "src/components/Components_Scheduling/Matrix/helpers/types";

export const formatterIndexTaskAreaProductType = (
    taskAreaProductTypes: Type_sch_index_taskAreaProductType[],
): Type_index_taskAreaProductType[] => {
    if (!taskAreaProductTypes) return [];

    const indexTaskAreaProductTypes = taskAreaProductTypes.map(
        (taskAreaProductType) => {
            return {
                id: taskAreaProductType.id,
                productType: taskAreaProductType.productType,
                quantity: taskAreaProductType.quantity,
                taskAreaId: taskAreaProductType.task_area_id,
            };
        },
    );

    return sortProductByName(indexTaskAreaProductTypes);
};

export const formatterIndexTaskAreaProductTypesMatrix = (
    data: Type_sch_index_taskAreaProductTypesMatrix,
): Type_index_taskAreaMatrix_data => {
    return {
        areas: data.areas.map(formatterIndexTaskAreaForMatrixArea),
        task: formatterIndexMatrix_task<Type_sch_index_taskAreaProductTypesMatrix_taskProductType>(
            data.taskProductType,
            "productType",
            "productType_id",
        ),
        taskAreas:
            formatterMatrix_taskAreas<Type_sch_index_taskAreaProductTypesMatrix_taskAreasProductType>(
                data.taskAreasProductType,
                "productType",
                "productType_id",
            ),
        dataColumns: data.taskProductType
            ? data.taskProductType.map(
                  (
                      item: Type_sch_index_taskAreaProductTypesMatrix_taskProductType,
                  ): Type_index_dataColumns =>
                      formatterMatrix_dataColumns<Type_sch_index_taskAreaProductTypesMatrix_taskProductType>(
                          item,
                          "productType",
                          "productType_id",
                          "unit_symbol",
                      ),
              )
            : [],
    };
};

export const formatterCreateTaskAreaProductTypeForMatrix = (
    taskAreaProductType: Type_post_taskAreaProductType_forFormatter,
): Type_sch_post_taskAreaProductType => {
    const formattedObject: Type_sch_post_taskAreaProductType = {
        quantity: 0,
    };

    for (const [key, value] of Object.entries(taskAreaProductType.data)) {
        const splitKey: string[] = key.split("_"); // Extracts 'key' and 'id' from 'key_id'
        formattedObject["productType_id"] = Number(splitKey[1]);
        formattedObject["quantity"] = value;
    }

    return formattedObject;
};

export const formatterCreateTaskAreaProductType = (
    taskAreaProductType: Type_post_taskAreaProductType,
): Type_sch_post_taskAreaProductType => {
    return {
        productType_id: taskAreaProductType.productTypeId,
        quantity: taskAreaProductType.quantity,
    };
};
