import { SelectProps } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Type_selectList } from "src/api/types";
import { TMC_TextField } from "src/components/Components_Common/_MuiComponentsVariants";
import { FormHelperMessage } from "src/components/Components_Common/_MuiComponentsVariants/FormHelperText/FormHelperMessage";
import { Type_Props_TextField } from "src/components/Components_Common/_MuiComponentsVariants/TextField/TextField";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";
import { INVISIBLE_VALUE, SELECT_NONE_VALUE } from "src/configurations/app";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_SelectOptionItem = {
    label: string;
    value: string | number;
    disabled?: boolean;
    optionGroupLabel?: React.ReactNode;
};

export const formatSelectListToSelectOptionItem = (
    data: Type_selectList[],
    hasNoneValue?: boolean,
): Type_SelectOptionItem[] => {
    const formattedData: Type_SelectOptionItem[] = data.map((item) => ({
        label: item.name,
        value: item.id,
        disabled: item.disabled,
        optionGroupLabel: item.optionGroupLabel,
    }));

    if (hasNoneValue)
        formattedData.unshift({
            value: SELECT_NONE_VALUE,
            label: INVISIBLE_VALUE,
        });

    return formattedData;
};

export type Type_Props_Select = Type_Props_TextField &
    Type_ReadonlyComponent &
    Omit<SelectProps, "onChange"> & {
        name: string;
        options: Type_SelectOptionItem[];
        hasNoneValue?: boolean;
    };

export const Select = ({
    name,
    options,
    placeholder,
    inputProps,
    hasNoneValue = false,
    ...props
}: Type_Props_Select) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Components.Select");

    const PLACEHOLDER_VALUE: string = "placeholder";

    const { formState, control } = useFormContext(); // retrieve those props
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { ref, value, ...field } }) => {
                return (
                    <>
                        <TMC_TextField
                            select
                            value={
                                (value !== null && value !== undefined) ||
                                (value === undefined && hasNoneValue) // We should accept 0 as a valid option
                                    ? value
                                    : placeholder
                                      ? PLACEHOLDER_VALUE
                                      : ""
                            }
                            {...props}
                            inputProps={{
                                ...inputProps,
                                "data-testid": `SelectMenuItem-${name}-selected`,
                            }}
                            // ReactHookForm
                            inputRef={ref}
                            {...field}
                            onChange={(e) => {
                                field.onChange(e);
                                props.onChange && props.onChange(e);
                            }}
                            error={!!formState.errors[name]}
                            helperText={
                                formState.errors[name] && (
                                    <FormHelperMessage
                                        inputName={name}
                                        message={
                                            formState?.errors[name]
                                                ?.message as string
                                        }
                                    />
                                )
                            }
                            sx={{
                                // Permet de colorer le placeholder en "gris" si aucune valeur
                                "& .MuiSelect-select": {
                                    color:
                                        value !== null && value !== undefined
                                            ? "inherit"
                                            : "text.secondary",
                                },
                            }}
                        >
                            {placeholder && (
                                <MenuItem
                                    disabled
                                    sx={{ display: "none" }}
                                    key={`Select-MenuItem-${name}-${placeholder}`}
                                    value={PLACEHOLDER_VALUE}
                                    data-testid={`Select-MenuItem-${name}-${placeholder}`}
                                    color="text.secondary"
                                >
                                    {placeholder}
                                </MenuItem>
                            )}
                            {hasNoneValue && (
                                <MenuItem
                                    disabled={!value}
                                    key={"None"}
                                    value={undefined}
                                    data-testid={`Select-MenuItem-${name}-none`}
                                >
                                    {fmt("NoValue")}
                                </MenuItem>
                            )}
                            {options.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                    disabled={option?.disabled}
                                    data-testid={`Select-MenuItem-${name}-${option.value}`}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TMC_TextField>
                    </>
                );
            }}
        />
    );
};
