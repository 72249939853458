import { yupResolver } from "@hookform/resolvers/yup";
import { debounce } from "@mui/material";
import { Dayjs } from "dayjs";
import React, { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as Yup from "yup";

import { ProjectCompanyKeys, SubTradeKeys } from "src/api/tms-projects/keys";
import {
    ConstraintKeys,
    PeopleTaskAreaKeys,
    ProgressHistoryKeys,
    TaskAreaKeys,
    TaskAreaProductTypeKeys,
    TaskLinkKeys,
} from "src/api/tms-scheduling/keys";
import {
    mutationSetProgressTaskArea,
    mutationUpsertTaskArea,
} from "src/api/tms-scheduling/taskArea";
import { formatterShowToPostTaskArea } from "src/api/tms-scheduling/taskArea/formatters";
import {
    Type_post_taskArea,
    Type_show_taskArea,
} from "src/api/tms-scheduling/taskArea/types";
import { TaskLinkUniqueKeys } from "src/api/tms-scheduling/taskLinks";
import { languagesList } from "src/assets/translations";
import { DEBOUNCE_TIME, useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { TaskAreaFormAccordionList } from "src/forms/taskArea/TaskAreaFormComponents/TaskAreaFormAccordionList";
import { TaskAreaFormContent } from "src/forms/taskArea/TaskAreaFormComponents/TaskAreaFormContent";
import { TaskAreaFormHeader } from "src/forms/taskArea/TaskAreaFormComponents/TaskAreaFormHeader";
import {
    Type_event_idsTaskArea,
    Type_event_message,
    useChannel,
} from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Content,
    Form,
    useContextualDrawer,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { getDate } from "src/utils/date";
import { ShortKeys } from "src/utils/shortcuts";

const validationSchema = Yup.lazy(() => {
    return Yup.object().shape({
        companyId: Yup.number().nullable(),
        duration: Yup.number()
            .typeError(FORM_ERR_FMT.MUST_BE_NUMBER)
            .min(0.01, FORM_ERR_FMT.MUST_BE_POSITIF),
        earliestDate: Yup.mixed<Dayjs>().nullable(),
        endDate: Yup.mixed<Dayjs>().nullable(),
        names: Yup.object().shape(
            Object.entries(languagesList).reduce((acc: any, [languageCode]) => {
                acc[languageCode] = Yup.string().nullable();
                return acc;
            }, {}),
        ),
        order: Yup.number().typeError(FORM_ERR_FMT.MUST_BE_NUMBER).nullable(),
        pin: Yup.boolean(),
        startDate: Yup.mixed<Dayjs>().nullable(),
        team: Yup.number().typeError(FORM_ERR_FMT.MUST_BE_NUMBER),
        waitingDay: Yup.number().typeError(FORM_ERR_FMT.MUST_BE_NUMBER),
        waitingDayMode: Yup.number()
            .typeError(FORM_ERR_FMT.MUST_BE_NUMBER)
            .nullable(),
        workforce: Yup.number().typeError(FORM_ERR_FMT.MUST_BE_NUMBER),
    });
});

type Type_Props_TaskAreaForm = {
    data: Type_show_taskArea;
};

export const TaskAreaForm = ({ data: taskArea }: Type_Props_TaskAreaForm) => {
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { closePaper } = useContextualDrawer();

    ///////////////////////////////////////
    ///         Queries                 ///
    ///////////////////////////////////////

    const { mutateAsync } = mutationUpsertTaskArea(false);
    const { mutateAsync: mutateAsyncSetProgressValue } =
        mutationSetProgressTaskArea();

    ///////////////////////////////////////
    ///         Refresh         ///
    ///////////////////////////////////////

    // React Query refresh function to invalidate the cache
    const refresh = useCallback(() => {
        // Data
        queryClient.invalidateQueries([
            TaskAreaKeys.SHOW,
            requestConfig,
            taskArea.id,
            taskArea.taskId,
            taskArea.areaId,
        ]);
        // TaskLinks
        queryClient.invalidateQueries([
            TaskLinkKeys.INDEX,
            requestConfig,
            { taskId: taskArea.taskId }, // TODO add taskAreaId
            TaskLinkUniqueKeys.ACCORDION,
        ]);
        // ProgressionHistories
        queryClient.invalidateQueries([
            ProgressHistoryKeys.INDEX,
            requestConfig,
            taskArea.id,
        ]);
        // TaskObservations
        queryClient.invalidateQueries([
            ConstraintKeys.INDEX,
            requestConfig,
            {
                taskAreaId: taskArea.id,
                taskId: taskArea.taskId,
                areaId: taskArea.areaId,
            },
        ]);
        // PeopleTaskArea
        queryClient.invalidateQueries([
            PeopleTaskAreaKeys.INDEX,
            requestConfig,
            {
                taskAreaId: taskArea.id,
                taskId: taskArea.taskId,
                areaId: taskArea.areaId,
            },
        ]);
        // ProductTypesTaskArea
        queryClient.invalidateQueries([
            TaskAreaProductTypeKeys.INDEX,
            requestConfig,
            {
                taskAreaId: taskArea.id,
                taskId: taskArea.taskId,
                areaId: taskArea.areaId,
            },
        ]);

        // Inputs lists
        queryClient.invalidateQueries([
            SubTradeKeys.SHOW,
            requestConfig.projectId,
            requestConfig.subProjectId,
            taskArea?.subTrade?.id,
        ]);
        queryClient.invalidateQueries([
            ProjectCompanyKeys.SHOW,
            taskArea?.company?.id,
            requestConfig,
        ]);
    }, [
        requestConfig,
        taskArea.id,
        taskArea.taskId,
        taskArea.areaId,
        taskArea.company,
        taskArea.subTrade,
    ]);

    ///////////////////////////////////////
    ///         Form                    ///
    ///////////////////////////////////////

    const formatterTaskArea = formatterShowToPostTaskArea(taskArea);

    const form = useForm<Type_post_taskArea>({
        ...useFormDefaultConfig,
        defaultValues: formatterTaskArea,
        values: formatterTaskArea,
        mode: "onBlur",
        resolver: yupResolver<any>(validationSchema),
    });

    const updateProgressValue = async (values: Type_post_taskArea) => {
        try {
            await mutateAsyncSetProgressValue({
                origin: "Progress",
                areaId: values.areaId ?? null,
                taskId: values.taskId ?? null,
                taskAreaId: values.id ?? null,
                progressValue: Number(values.progressValue),
                progressLastDate: getDate(),
            });
        } catch (error) {
            console.error("Failed to update progress value", error);
            return;
        }
    };

    const onSubmit = async (values: Type_post_taskArea): Promise<void> => {
        // Force the form validation
        await form.trigger();

        if (Object.keys(form.formState.dirtyFields).length > 0) {
            if (form.formState.dirtyFields.progressValue) {
                await updateProgressValue(values);

                // Remove dirty status
                form.resetField("progressValue", {
                    defaultValue: values.progressValue,
                });
            }

            const result = Object.fromEntries(
                Object.keys(form.formState.dirtyFields).map((key) => [
                    key,
                    values[key as keyof Type_post_taskArea],
                ]),
            ) as Type_post_taskArea;

            // check if result is empty
            if (Object.keys(result).length === 0) {
                console.debug(
                    "TaskAreaForm ====> onSubmit:dirty fields but result empty",
                );
                return;
            }

            // Add ids for request headers
            result.id = values.id;
            result.taskId = values.taskId;
            result.areaId = values.areaId;

            console.debug(
                "TaskAreaForm ====> onSubmit: dirty fields to submit",
                result,
            );
            await mutateAsync(result).then((data) => {
                if (data.success) {
                    console.debug("mutateAsync then => reset form");
                    form.reset(values);
                }
            });
        } else {
            console.debug("TaskAreaForm ====> onSubmit:no dirty fields");
        }
    };

    const debounceOnSubmit = useCallback(
        debounce((props) => onSubmit(props), DEBOUNCE_TIME),
        [],
    );

    // On dismount, submit the form
    useEffect(() => {
        return () => {
            console.debug("TaskAreaForm dismount, submit the form");
            onSubmit(form.getValues()).catch((e) => {
                console.error("Error on dismount form", e);
                addWarning({
                    description: fmtErr("GenericError"),
                });
            });
        };
    }, []);

    // Submit form with Enter key
    const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if (event.key === ShortKeys.ENTER) {
            (event.target as HTMLElement).blur();
        }
    };

    ///////////////////////////////////////
    ///         Events                  ///
    ///////////////////////////////////////

    useChannel({
        eventHandler: ({ event, data }: Type_event_message) => {
            const punchlistEventData = data as Type_event_idsTaskArea;
            if (
                event === "postPunchlist" &&
                punchlistEventData.areaId === taskArea.areaId &&
                punchlistEventData.taskId === taskArea.taskId
            ) {
                refresh();
            }
        },
    });

    return (
        <FormProvider {...form}>
            <Form
                onKeyDown={handleKeyDown}
                onBlur={form.handleSubmit(debounceOnSubmit, (props) =>
                    console.error("handleSubmit: invalid response", props),
                )}
            >
                <TaskAreaFormHeader
                    taskArea={taskArea}
                    onRefresh={refresh}
                    onClose={() => closePaper("taskArea")}
                    onSubmit={onSubmit}
                />
                <Content data-testid="TaskAreaForm-Content">
                    <TaskAreaFormContent taskArea={taskArea} />
                    <TaskAreaFormAccordionList taskArea={taskArea} />
                </Content>
            </Form>
        </FormProvider>
    );
};
