import {
    Box,
    debounce,
    Grid,
    Input,
    List,
    Stack,
    Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import {
    mutationUpsertTaskAreaProductTypes,
    useIndexTaskAreaProductTypes,
} from "src/api/tms-scheduling/taskAreaProductTypes";
import { Type_index_taskAreaProductType } from "src/api/tms-scheduling/taskAreaProductTypes/types";
import { Empty } from "src/components";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { DEBOUNCE_TIME } from "src/configurations/app";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { HeaderToolbar } from "src/layouts/Layout_ContextualDrawer/components/HeaderToolbar";
import {
    Content,
    Form,
    Header,
    Styled_PaperContextualLayout,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

type Type_Props_ProductTypeTaskAreaContextualDrawer = {
    onClose: () => void;
    areaId: number;
    taskId: number;
    taskAreaId?: number;
};

export const ProductTypeTaskAreaContextualDrawer = ({
    onClose,
    taskId,
    areaId,
    taskAreaId,
}: Type_Props_ProductTypeTaskAreaContextualDrawer) => {
    const { sendEvent } = useChannel({});

    // i18n
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerProductTypes",
    );
    const { formatMessageWithPartialKey: fmtDrawer } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.ProductType",
    );

    const [productsTypesTaskArea, setProductsTaskArea] = useState<
        Type_index_taskAreaProductType[]
    >([]);

    const { data, isFetching: isFetchingProductTypesTaskArea } =
        useIndexTaskAreaProductTypes({ taskId, areaId, taskAreaId });

    const { mutateAsync: updateProductTypesTasks } =
        mutationUpsertTaskAreaProductTypes({ taskId, areaId }, false);

    useEffect(() => {
        if (data && !isFetchingProductTypesTaskArea) {
            setProductsTaskArea(data);
        }
    }, [isFetchingProductTypesTaskArea]);

    useEffect(() => {
        if (!isFetchingProductTypesTaskArea) {
            sendEvent("updateProductTypesTaskArea", productsTypesTaskArea);
        }
    }, [productsTypesTaskArea]);

    const handleQuantityChange = useCallback(
        debounce(
            async ({
                event,
                productTypeId,
            }: {
                event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                >;
                productTypeId: number;
            }) => {
                const newQuantity = +event.target.value;
                await updateProductTypesTasks({
                    productTypeId: productTypeId,
                    quantity: newQuantity,
                });
                setProductsTaskArea((prevProducts) => {
                    return prevProducts.map((product) => {
                        if (product.productType.id === productTypeId) {
                            return {
                                ...product,
                                quantity: newQuantity,
                            };
                        } else {
                            return product;
                        }
                    });
                });
            },
            DEBOUNCE_TIME,
        ),
        [],
    );

    // React hook form
    const form = useForm<any>({
        defaultValues: {
            productTypes: [],
        },
        mode: "onSubmit",
    });

    return (
        <Styled_PaperContextualLayout id="productTypeTaskArea">
            <FormProvider {...form}>
                <Form>
                    <Header>
                        <HeaderToolbar onClose={onClose} />
                        <Typography data-testid={"ProductTypes-TaskArea"}>
                            {fmt("Title")}
                        </Typography>
                    </Header>
                    <Content padding={4}>
                        {isFetchingProductTypesTaskArea ? (
                            <FullSpinner />
                        ) : productsTypesTaskArea.length > 0 ? (
                            <List>
                                {productsTypesTaskArea.map((product) => (
                                    <Box key={`product_${product.id}`}>
                                        <Grid
                                            container
                                            p={2}
                                            alignItems={"center"}
                                            flexGrow={1}
                                            spacing={2}
                                        >
                                            <Grid item flexGrow={1} xs={6}>
                                                <Typography
                                                    data-testid={`ProductTypes-TaskArea-Product-Name-${product.id}`}
                                                >
                                                    {product.productType.name}
                                                </Typography>
                                            </Grid>

                                            <Grid item flexGrow={1} xs={6}>
                                                <Stack
                                                    spacing={1}
                                                    direction="row"
                                                    alignItems={"baseline"}
                                                >
                                                    <Input
                                                        data-testid={`ProductTypes-TaskArea-Product-Input-${product.id}`}
                                                        sx={{
                                                            width: "25%",
                                                            "::before": {
                                                                borderBottom:
                                                                    "none",
                                                            },
                                                        }}
                                                        onChange={(e) =>
                                                            handleQuantityChange(
                                                                {
                                                                    event: e,
                                                                    productTypeId:
                                                                        product
                                                                            .productType
                                                                            .id,
                                                                },
                                                            )
                                                        }
                                                        defaultValue={
                                                            product.quantity
                                                        }
                                                        name={`product_${product.id}_quantity`}
                                                        type="number"
                                                    />
                                                    <Typography
                                                        sx={{
                                                            width: "50%",
                                                        }}
                                                    >
                                                        {
                                                            product.productType
                                                                .unit.name
                                                        }
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))}
                            </List>
                        ) : (
                            <Empty
                                message={fmtDrawer("EmptyProductTypeMessage")}
                                dataTestIdRef={"ProductTypesTaskAreaForm"}
                            />
                        )}
                    </Content>
                </Form>
            </FormProvider>
        </Styled_PaperContextualLayout>
    );
};
