import {
    GridColDef,
    GridRenderCellParams,
    GridRenderEditCellParams,
    GridTreeNodeWithRender,
} from "@mui/x-data-grid-premium";

import { Type_selectList } from "src/api/types";
import { formatSelectListToSelectOptionItem } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { SelectEditCell } from "src/components/Components_Common/matrix/components/SelectEditCell";

type Type_Props_selectColumn = GridColDef & {
    options: Type_selectList[];
    hasNoneValue?: boolean;
    filterOptions?: (
        params: GridRenderEditCellParams<any, any, any, GridTreeNodeWithRender>,
    ) => Type_selectList[];
};

export const selectColumn = ({
    options,
    hasNoneValue = false,
    filterOptions,
    ...props
}: Type_Props_selectColumn) => {
    const getFilteredOption = (
        params: GridRenderCellParams | GridRenderEditCellParams,
    ) => {
        return filterOptions ? filterOptions(params) : options;
    };

    return {
        ...props,
        renderCell: (params: GridRenderCellParams) => {
            return (
                getFilteredOption(params).find(
                    (item) => item.id == params.value,
                )?.name ?? ""
            );
        },
        renderEditCell: (
            params: GridRenderEditCellParams<
                any,
                any,
                any,
                GridTreeNodeWithRender
            >,
        ) => {
            return SelectEditCell({
                ...params,
                options: formatSelectListToSelectOptionItem(
                    getFilteredOption(params),
                    hasNoneValue,
                ),
            });
        },
    };
};
