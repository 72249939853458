import {
    formatterMultiLanguageForMatrixToBack,
    formatterMultiLanguageForMatrixToFront,
} from "src/api/formatters";
import {
    Type_index_subTrade,
    Type_index_subTrade_matrix,
    Type_post_subTrade,
    Type_prj_index_subTrade,
    Type_prj_index_subTrade_matrix,
    Type_prj_post_subTrade,
    Type_prj_put_subTrade,
    Type_prj_selectList_subTrade,
    Type_prj_show_subTrade,
    Type_put_subTrade_matrix,
    Type_selectList_subTrade,
    Type_show_subTrade,
} from "src/api/tms-projects/subTrades/types";

export const formatterCreateSubTrade = (
    data: Type_post_subTrade,
): Type_prj_post_subTrade => {
    return {
        names: data.names,
        code: data.code,
        companies: data.companies
            ? data.companies.map((company: any) => company.id)
            : [],
        trade_id: data.tradeId,
        params: data.params,
    };
};

export const formatterUpdateSubTrade = (
    data: Type_show_subTrade,
): Type_prj_put_subTrade => {
    return {
        id: data.id,
        names: data.names,
        code: data.code,
        companies: data.companies
            ? data.companies.map((company: any) => company.id)
            : [],
        team: data.team,
        enabled: data.enabled,
        params: data.params,
        trade_id: data.trade,
    };
};

export const formatterShowSubTrade = (
    data: Type_prj_show_subTrade,
): Type_show_subTrade => {
    return {
        id: data.id,
        names: data.names,
        code: data.code,
        team: data.team,
        trade: data.trade.id,
        companies: data.companies,
        enabled: data.enabled,
    };
};

export const formatterIndexSubTrade = (
    data: Type_prj_index_subTrade,
): Type_index_subTrade => {
    return {
        id: data.id,
        name: data.name,
        team: data.team,
        code: data.code,
        trade: data.trade,
        subProject: data.subProject,
        enabled: data.enabled,
    };
};

export const formatterSelectListSubTrades = (
    data: Type_prj_selectList_subTrade[],
): Type_selectList_subTrade[] => {
    if (!data) return [];
    return data.map((item: Type_prj_selectList_subTrade) => ({
        id: item.id,
        name: item.name,
        team: item.team,
        code: item.code,
        tradeId: item.trade_id,
        enabled: item.enabled,
    }));
};

const formatterIndexSubTradeMatrix = (
    subTrade: Type_prj_index_subTrade_matrix,
): Type_index_subTrade_matrix => {
    return {
        id: subTrade.id,
        color: subTrade.trade.color,
        parentName: subTrade.trade.name,
        ...formatterMultiLanguageForMatrixToFront("names", subTrade.names),
        code: subTrade.code,
        team: subTrade.team,
    };
};

export const formatterIndexSubTradesMatrix = (
    subTrades: Type_prj_index_subTrade_matrix[],
): Type_index_subTrade_matrix[] => {
    return subTrades.map(formatterIndexSubTradeMatrix);
};

export const formatterUpdateSubTradeMatrix = (
    data: Type_put_subTrade_matrix,
) => {
    return {
        id: data.data.id,
        names: formatterMultiLanguageForMatrixToBack("names", data.data),
        code: data.data.code,
        team: data.data.team,
    };
};
