import { Stack } from "@mui/material";
import {
    GridColDef,
    GridPreProcessEditCellProps,
} from "@mui/x-data-grid-premium";
import { useEffect, useMemo, useState } from "react";

import {
    mutationUpsertTaskAreaForMatrix,
    useIndexTaskAreasForMatrix,
} from "src/api/tms-scheduling/taskArea";
import { multiLanguesColumns } from "src/components/Components_Common/matrix/columnDefinitions/multiLanguesColumn";
import { Matrix } from "src/components/Components_Common/matrix/Matrix";
import { getMaxDepth } from "src/components/Components_Scheduling/Matrix/helpers/MatrixSequenceAreasHelpers";
import {
    getTaskAreaRows,
    processRowUpdateTaskAreaMatrix,
} from "src/components/Components_Scheduling/Matrix/helpers/MatrixTaskAreasHelper";
import { MatrixSequenceAreasColumns } from "src/components/Components_Scheduling/Matrix/MatrixSequenceAreas/MatrixSequenceAreasColumns";
import { useProject } from "src/contexts/project";
import { useUser } from "src/contexts/user";
import { shouldDisplay } from "src/forms/tasks/utils";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_MatrixTaskAreasTask = {
    taskId: number;
    onClose: () => void;
};

export const MatrixTaskAreasTask = ({
    taskId,
    onClose,
}: Type_Props_MatrixTaskAreasTask) => {
    const { projectLanguages = [] } = useProject();

    ///////////////////////////////////////
    ///           Permission            ///
    ///////////////////////////////////////
    const page = "tasks";
    const { checkPermission, user } = useUser();
    const editable = useMemo(() => {
        return page
            ? checkPermission(page, "create") || checkPermission(page, "update")
            : true;
    }, [page, user]);

    ///////////////////////////////////////
    ///             i18                 ///
    ///////////////////////////////////////
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");

    ///////////////////////////////////////
    ///            States               ///
    ///////////////////////////////////////
    const [taskType, setTaskType] = useState<number | null>(null);
    const [maxDepth, setMaxDepth] = useState<number | null>(null);

    ///////////////////////////////////////
    ///          Fetched data           ///
    ///////////////////////////////////////
    const { data: taskAreas, isFetching } =
        useIndexTaskAreasForMatrix(taskId) || {};

    useEffect(() => {
        if (taskAreas?.areas) {
            setMaxDepth(getMaxDepth(taskAreas.areas));
            setTaskType(taskAreas.task.type);
        }
    }, [taskAreas]);

    ///////////////////////////////////////
    ///          Mutation               ///
    ///////////////////////////////////////
    const { mutateAsync: mutateUpdate } =
        mutationUpsertTaskAreaForMatrix(taskId) || {};

    ///////////////////////////////////////
    ///          Columns                ///
    ///////////////////////////////////////
    const areaColumns: GridColDef[] = [
        ...MatrixSequenceAreasColumns({
            depth: maxDepth!,
            enableOrderEdition: false,
            withSelected: false,
        }),
    ];

    const dataColumns: GridColDef[] = useMemo(
        () => [
            ...multiLanguesColumns({
                field: "names",
                headerName: fmtTableColumn("Name"),
                languages: projectLanguages,
                editable: editable,
            }),
            {
                field: "earliestDate",
                headerName: fmtTableColumn("EarliestDate"),
                resizable: true,
                flex: 1,
                type: "date",
                editable: editable,
                align: "center",
            },
            ...(shouldDisplay({
                name: "duration",
                taskType: taskType,
            })
                ? [
                      {
                          field: "duration",
                          headerName: fmtTableColumn("Duration"),
                          resizable: true,
                          flex: 1,
                          type: "number",
                          editable: editable,
                          headerAlign: "left",
                          preProcessEditCellProps: (
                              params: GridPreProcessEditCellProps,
                          ) => {
                              return {
                                  ...params.props,
                                  error: params.props.value <= 0,
                              };
                          },
                      } as GridColDef,
                  ]
                : []),
            ...(shouldDisplay({
                name: "team",
                taskType: taskType,
            })
                ? [
                      {
                          field: "team",
                          headerName: fmtTableColumn("Team"),
                          resizable: true,
                          flex: 1,
                          type: "number",
                          editable: editable,
                          headerAlign: "left",
                          preProcessEditCellProps: (
                              params: GridPreProcessEditCellProps,
                          ) => {
                              return {
                                  ...params.props,
                                  error: params.props.value < 0,
                              };
                          },
                      } as GridColDef,
                  ]
                : []),
            ...(shouldDisplay({
                name: "workforce",
                taskType: taskType,
            })
                ? [
                      {
                          field: "workforce",
                          headerName: fmtTableColumn("Workforce"),
                          resizable: true,
                          flex: 1,
                          type: "number",
                          editable: editable,
                          headerAlign: "left",
                          preProcessEditCellProps: (
                              params: GridPreProcessEditCellProps,
                          ) => {
                              return {
                                  ...params.props,
                                  error: params.props.value < 0,
                              };
                          },
                      } as GridColDef,
                  ]
                : []),
            // TODO demande au back pour récupérer directement les companies dans index matrix
            // ...(shouldDisplay({
            //     name: "companyId",
            //     taskType: taskType,
            // }) && taskAreas.task.companies.length
            //     ? [
            //           selectColumn({
            //               field: "companyId",
            //               headerName: fmtTableColumn("Company"),
            //               options: taskAreas.task.companies,
            //               hasNoneValue: true,
            //               editable: editable,
            //               display: "flex",
            //               flex: 1,
            //           }),
            //       ]
            //     : []),
        ],
        [isFetching, taskType],
    );

    const columns = [...areaColumns, ...dataColumns];

    ///////////////////////////////////////
    ///              Rows               ///
    ///////////////////////////////////////
    // UseMemo avoids the rerender when modifying cell values
    const dataRows = useMemo(
        () =>
            getTaskAreaRows({
                data: taskAreas,
                dataColumns: dataColumns,
                maxDepth: maxDepth,
            }),
        [taskAreas?.areas, dataColumns, maxDepth],
    );

    return (
        <Stack mt={5} spacing={5}>
            <Matrix
                columns={columns}
                rows={dataRows}
                mutateUpdate={mutateUpdate}
                processRowUpdateAdditionalBehaviors={
                    processRowUpdateTaskAreaMatrix
                }
                onClose={onClose}
            />
        </Stack>
    );
};
