import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
    InputSliderTextSize,
    Type_Props_InputSliderTextSize,
} from "src/components/Components_Teamoty/forms/InputSliderTextSize/InputSliderTextSize";

type Type_Props_InputSliderTextSizeRhf = Type_Props_InputSliderTextSize & {
    name: string;
    endAdornment?: Element;
};

export const InputSliderTextSizeRhf = ({
    name,
    label,
    ...props
}: Type_Props_InputSliderTextSizeRhf) => {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { ...rest } }) => (
                <InputSliderTextSize label={label} {...props} {...rest} />
            )}
        />
    );
};

InputSliderTextSizeRhf.displayName = "InputSliderTextSizeRhf";
