import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Type_Params } from "src/api/filters";
import { SubTradeKeys, TradeKeys } from "src/api/tms-projects/keys";
import {
    formatterIndexSubTrade,
    formatterIndexSubTradesMatrix,
    formatterSelectListSubTrades,
    formatterShowSubTrade,
    formatterUpdateSubTradeMatrix,
} from "src/api/tms-projects/subTrades/formatters";
import {
    createSubTrade,
    deleteSubTrade,
    indexSubTrades,
    selectListSubTrades,
    showSubTrade,
    statusSubTrade,
    updateSubTrade,
} from "src/api/tms-projects/subTrades/services";
import {
    Type_prj_post_subTrade,
    Type_prj_put_subTrade,
    Type_put_subTrade_matrix,
    Type_selectList_subTrade,
    Type_show_subTrade,
} from "src/api/tms-projects/subTrades/types";
import {
    MEDIUM_STALE_TIME,
    NO_STALE_TIME,
    Type_stale_times,
} from "src/configurations/app";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const mutationCreateSubTrade = () => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.SubTrades");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (subTrade: Type_prj_post_subTrade) =>
            createSubTrade(subTrade, requestConfig),
        onSuccess: async (data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: mutationCreateSubTrade");
            }

            const formattedData = formatterIndexSubTrade(data.data.data);

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        subTrade: formattedData.name,
                    },
                }),
            });

            await queryClient.invalidateQueries({
                queryKey: [TradeKeys.INDEX, requestConfig],
            });
        },
        onError: (err: any): void => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateSubTrade = ({
    forMatrix = false,
}: {
    forMatrix?: boolean;
}) => {
    const { requestConfig } = useProject();
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.SubTrades");
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (
            subTradeToUpdate: Type_prj_put_subTrade | Type_put_subTrade_matrix,
        ) => {
            if (forMatrix) {
                return updateSubTrade(
                    formatterUpdateSubTradeMatrix(
                        subTradeToUpdate as Type_put_subTrade_matrix,
                    ),
                    requestConfig,
                );
            } else {
                return updateSubTrade(subTradeToUpdate, requestConfig);
            }
        },
        onSuccess: async (data, variables) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: mutationUpdateSubTrade");
            }

            const formattedData = formatterIndexSubTrade(data.data.data);

            if (!forMatrix) {
                addSuccess({
                    description: fmt("ToastSuccessUpdate", {
                        values: {
                            b: (chunks: string) => <b>{chunks}</b>,
                            subTrade: formattedData.name,
                        },
                    }),
                });

                await queryClient.invalidateQueries({
                    queryKey: [TradeKeys.INDEX, requestConfig],
                });
                await queryClient.invalidateQueries({
                    queryKey: [
                        SubTradeKeys.SELECT_LIST,
                        requestConfig.projectId,
                        requestConfig.subProjectId,
                    ],
                });

                queryClient.setQueryData(
                    [
                        SubTradeKeys.SHOW,
                        requestConfig.projectId,
                        requestConfig.subProjectId,
                        variables.id,
                    ],
                    (oldData: any) => {
                        if (!oldData) return data;
                        // Must have the same props as the 'success' function of the response useShowSubTrade
                        return {
                            ...oldData,
                            ...data,
                        };
                    },
                );
            }
        },
        onError: (err: any): void => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const useShowSubTrade = (
    id?: number,
    enabled = true,
    staleTime: Type_stale_times = NO_STALE_TIME,
) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [
            SubTradeKeys.SHOW,
            requestConfig.projectId,
            requestConfig.subProjectId,
            id,
            requestConfig,
        ],
        queryFn: ({ signal }) =>
            showSubTrade(id as number, requestConfig, signal),
        refetchOnWindowFocus: false,
        select: (data): Type_show_subTrade => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: useShowSubTrade");
            }

            return formatterShowSubTrade(data.data.data);
        },
        onError: (err) => {
            return err;
        },
        enabled: !!id && enabled,
        staleTime: staleTime,
    });
};

export const mutationStatusSubTrade = () => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtError } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (data: Type_prj_put_subTrade) => {
            return statusSubTrade(data, requestConfig);
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: mutationStatusSubTrade");
            }

            await queryClient.invalidateQueries({
                queryKey: [TradeKeys.INDEX, requestConfig],
            });
            await queryClient.invalidateQueries({
                queryKey: [
                    SubTradeKeys.SELECT_LIST,
                    requestConfig.projectId,
                    requestConfig.subProjectId,
                ],
            });
        },
        onError: () => {
            addWarning({
                description: fmtError("CantDisableSubTrade", {}),
            });
        },
    });
};

export const useSelectListSubTrades = (enabled = true) => {
    const { requestConfig, isLoading: isLoadingRequestConfig } = useProject();

    return useQuery({
        queryKey: [
            SubTradeKeys.SELECT_LIST,
            requestConfig.projectId,
            requestConfig.subProjectId,
            requestConfig,
        ],
        queryFn: () => selectListSubTrades(requestConfig),
        refetchOnWindowFocus: false,
        select: (data): Type_selectList_subTrade[] => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: useSelectListSubTrades");
            }

            return formatterSelectListSubTrades(data.data.data);
        },
        enabled:
            !!requestConfig.projectId && !isLoadingRequestConfig && enabled,
        staleTime: MEDIUM_STALE_TIME,
    });
};

export const mutationDeleteSubTrade = () => {
    const { addWarning, addSuccess } = useToast();
    const { formatMessageWithPartialKey: fmtError } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.SubTrades");
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => deleteSubTrade(id, requestConfig),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error("Error format data: useIndexSubProjects");
            }

            await queryClient.invalidateQueries({
                queryKey: [TradeKeys.INDEX, requestConfig],
            });
            await queryClient.invalidateQueries({
                queryKey: [
                    SubTradeKeys.SELECT_LIST,
                    requestConfig.projectId,
                    requestConfig.subProjectId,
                ],
            });

            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtError("CantDeleteSubTrade", {}),
            });
            return err;
        },
    });
};

export const useIndexSubTrades = (params: Type_Params = {}) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [SubTradeKeys.INDEX_FOR_MATRIX, requestConfig, params],
        queryFn: () => indexSubTrades(requestConfig, { ...params }),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: useIndexSubTradesMatrix");
            }

            return formatterIndexSubTradesMatrix(data.data.data);
        },
        enabled: !!requestConfig.projectId && !!requestConfig.subProjectId,
    });
};
