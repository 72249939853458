import { Stack, Tooltip } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { Type_RequestConfig } from "src/api/fetch";
import {
    formatterCreateView,
    formatterIndexView,
} from "src/api/tms-projects/workspacesAndViews/views/formatters";
import { createView } from "src/api/tms-projects/workspacesAndViews/views/services";
import {
    Type_index_view,
    Type_prj_index_view,
} from "src/api/tms-projects/workspacesAndViews/views/types";
import {
    formatterCreateWorkspace,
    formatterShowWorkspace,
} from "src/api/tms-projects/workspacesAndViews/workspaces/formatters";
import {
    createWorkspace,
    showWorkspace,
} from "src/api/tms-projects/workspacesAndViews/workspaces/services";
import { Type_show_workspace } from "src/api/tms-projects/workspacesAndViews/workspaces/types";
import { LoadingBox } from "src/components";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import {
    APP_BAR_HEIGHT,
    TopBarLeft_IconButton,
} from "src/components/Components_Teamoty/TopBar/stylesheet";
import { useProject } from "src/contexts/project";
import { useWorkspaceViewContext } from "src/contexts/workspaces";
import { WorkspaceSelection } from "src/dialogs/WorkspaceSelection";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { ViewsTabs } from "src/layouts/Layout_Workspace/Views/ViewsTabs";
import { Page_Views } from "src/pages/Pages_Teamoty/Project/Views";

import { getDefaultSubprojectConfiguration } from "./configurations/workspacesSubproject";

type Type_Props_ActiveAndCreateWorkspace = {
    setCurrentWorkspace: Dispatch<
        SetStateAction<Type_show_workspace | undefined | null>
    >;
    type: string;
    requestConfig: Type_RequestConfig;
    setProgressValue: Dispatch<SetStateAction<number>>;
};

const activeAndCreateWorkspace = async ({
    setCurrentWorkspace,
    type,
    requestConfig,
    setProgressValue,
}: Type_Props_ActiveAndCreateWorkspace) => {
    try {
        // If we receive a 404 error, this means that the user does not yet have a workspace.
        const workspace = await showWorkspace(type, requestConfig).then(
            (data) => {
                if (!data?.success || !data?.data?.data) {
                    throw new Error("Error createWorkspace");
                }
                return formatterShowWorkspace(data.data.data);
            },
        );
        setCurrentWorkspace(workspace);
    } catch (_e) {
        // Not find workspace => Create workspace and views
        try {
            const workspaceConfig = getDefaultSubprojectConfiguration();
            const newWorkspace = workspaceConfig.get(type);

            if (newWorkspace) {
                // Create workspaces
                const createdWorkspace = await createWorkspace(
                    formatterCreateWorkspace(newWorkspace),
                    requestConfig,
                ).then((data) => {
                    if (!data?.success || !data?.data?.data) {
                        throw new Error("Error createWorkspace");
                    }
                    return formatterShowWorkspace(data.data.data);
                });

                // Create views for workspace
                const workspaceViews = newWorkspace.views;
                const pas = 100 / workspaceViews.length;
                let progress = 0;
                const createdViews: Type_index_view[] = [];
                for (const view of workspaceViews) {
                    setProgressValue((progress += pas));
                    await createView(
                        formatterCreateView(view, createdWorkspace.id),
                        requestConfig,
                    ).then((data) => {
                        if (data?.success && data?.data?.data) {
                            createdViews.push(
                                formatterIndexView(
                                    data.data.data as Type_prj_index_view,
                                ),
                            );
                        }
                    });
                }

                // Set current workspace to the new one
                setCurrentWorkspace({
                    ...createdWorkspace,
                    views: createdViews,
                });
            }
        } catch (error) {
            console.error("Error creating workspaces and views:", error);
        }
    }

    // Close progress bar
    setProgressValue(100);
};

export const Layout_Workspace = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Workspace.Menu",
    );

    const { setCurrentWorkspace, currentWorkspace } = useWorkspaceViewContext();
    const {
        requestConfig,
        workspace: projectWorkspace,
        isLoading,
    } = useProject();

    const topBarLeftContent = document.getElementById("topBarLeftContent");
    const workspacesMenuContent = document.getElementById(
        "workspacesMenuContent",
    );

    //////////////////////////////////
    ///     States
    //////////////////////////////////

    const [open, setOpen] = useState<boolean>(false);
    const [progressValue, setProgressValue] = useState<number>(0);
    const [withProgress, setWithProgress] = useState<boolean>(false);

    //////////////////////////////////
    ///     Effects
    //////////////////////////////////

    // Check if we need to open the dialog to invite user creating new workspace
    useEffect(() => {
        if (!isLoading && projectWorkspace !== null) {
            if (projectWorkspace.length === 0) {
                setCurrentWorkspace(null);
                // open dialog to invite user creating new workspace
                handleOpen();
            }
        }
    }, [isLoading, projectWorkspace]);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleClick = async (type: string) => {
        setWithProgress(true);
        await activeAndCreateWorkspace({
            setCurrentWorkspace,
            type,
            requestConfig,
            setProgressValue,
        });
        setWithProgress(false);

        handleClose();
    };

    // reinitialisation du progress
    if (progressValue && !withProgress) {
        setProgressValue(0);
    }

    return (
        <>
            {workspacesMenuContent &&
                createPortal(
                    <Stack direction="row" height={APP_BAR_HEIGHT}>
                        <Tooltip title={fmt("Tooltip")}>
                            <TopBarLeft_IconButton
                                aria-haspopup="true"
                                color="inherit"
                                onClick={handleOpen}
                                data-testid="WorkspacesMenu-open-btn"
                                aria-controls={open ? "views-menu" : undefined}
                                aria-expanded={open}
                            >
                                <Icon
                                    variant="kit"
                                    icon="light-table-layout-sparkle"
                                    sx={{ width: "24px" }}
                                />
                            </TopBarLeft_IconButton>
                        </Tooltip>
                    </Stack>,
                    workspacesMenuContent,
                )}
            {topBarLeftContent && currentWorkspace?.views?.length
                ? createPortal(<ViewsTabs />, topBarLeftContent)
                : null}

            {currentWorkspace ? <Page_Views /> : <LoadingBox />}

            <WorkspaceSelection
                isLoading={isLoading}
                handleClose={handleClose}
                handleClick={handleClick}
                open={open}
                withConfirmation={currentWorkspace?.type !== undefined}
                progressValue={progressValue}
                withProgress={withProgress}
            />
        </>
    );
};
