import { yupResolver } from "@hookform/resolvers/yup";
import { debounce } from "@mui/material";
import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as Yup from "yup";

import { TaskAreaKeys } from "src/api/tms-scheduling/keys";
import {
    mutationSetProgressTaskArea,
    mutationUpsertTaskArea,
} from "src/api/tms-scheduling/taskArea";
import { formatterShowToPostTaskArea } from "src/api/tms-scheduling/taskArea/formatters";
import {
    Type_post_taskArea,
    Type_show_taskArea,
} from "src/api/tms-scheduling/taskArea/types";
import { DEBOUNCE_TIME, useFormDefaultConfig } from "src/configurations/app";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { GanttHeaderForm } from "src/forms/tasks/HeaderForm/GanttHeaderForm";
import { nameFormSchema } from "src/forms/tasks/NameForm/NameForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Content,
    Form,
    useContextualDrawer,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { getDate } from "src/utils/date";
import { ShortKeys } from "src/utils/shortcuts";

//////////////////////////////////////////////
///     FORM                               ///
//////////////////////////////////////////////

const taskValidationSchema = Yup.object().shape({
    ...nameFormSchema.fields,
    // ...mainFormSchema.fields,
    // ...dateFormSchema.fields,
    // ...waitingDaysFormSchema.fields,
    // ...slideOutFormSchema.fields,
    // ...advancedSchema.fields,
});

//////////////////////////////////////////////
///     TYPES                              ///
//////////////////////////////////////////////

type Type_Props_GanttTaskForm = {
    data: Type_show_taskArea;
};

export const GanttTaskForm = ({ data: taskArea }: Type_Props_GanttTaskForm) => {
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { closePaper } = useContextualDrawer();

    ///////////////////////////////////////
    ///         Queries                 ///
    ///////////////////////////////////////

    const { mutateAsync } = mutationUpsertTaskArea(false);
    const { mutateAsync: mutateAsyncSetProgressValue } =
        mutationSetProgressTaskArea();

    ///////////////////////////////////////
    ///         Form                    ///
    ///////////////////////////////////////

    const formattedTask = formatterShowToPostTaskArea(taskArea);

    const form = useForm<Type_post_taskArea>({
        ...useFormDefaultConfig,
        defaultValues: formattedTask,
        values: formattedTask,
        mode: "onBlur", // submit onBlur
        resolver: yupResolver<any>(taskValidationSchema),
    });

    const updateProgressValue = async (values: Type_post_taskArea) => {
        try {
            await mutateAsyncSetProgressValue({
                origin: "Form",
                areaId: values.areaId ?? null,
                taskId: values.taskId ?? null,
                taskAreaId: values.id ?? null,
                progressValue: Number(values.progressValue),
                progressLastDate: getDate(),
            });
        } catch (error) {
            console.error("Failed to update progress value", error);
            return;
        }
    };

    const onSubmit = async (values: Type_post_taskArea): Promise<void> => {
        console.log("====> onSubmit: should call API", values);
        await form.trigger();
        if (Object.keys(form.formState.dirtyFields).length > 0) {
            if (form.formState.dirtyFields.progressValue) {
                await updateProgressValue(values);

                // Remove dirty status
                form.resetField("progressValue", {
                    defaultValue: values.progressValue,
                });
            }

            const result = Object.fromEntries(
                Object.keys(form.formState.dirtyFields).map((key) => [
                    key,
                    values[key as keyof Type_post_taskArea],
                ]),
            ) as Type_post_taskArea;

            // check if result is empty
            if (Object.keys(result).length === 0) {
                console.debug(
                    "====> onSubmit: no dirty fields to submit",
                    result,
                );
                return;
            }

            // Add ids for request headers
            result.id = values.id;

            console.debug(
                "GanttTaskForm ====> onSubmit: dirty fields to submit",
                result,
            );

            await mutateAsync(result).then((data) => {
                if (data.success) {
                    console.debug("mutateAsync result => reset form");
                    form.reset(values);
                }
            });
        } else {
            console.debug("====> onSubmit: no dirty fields to submit");
        }
    };

    const debounceOnSubmit = useCallback(
        debounce((props) => onSubmit(props), DEBOUNCE_TIME),
        [],
    );

    // On dismount, submit the form
    useEffect(() => {
        return () => {
            console.debug("GanttTaskForm dismount, submit the form");
            onSubmit(form.getValues()).catch((e) => {
                console.error("Error on dismount form", e);
                addWarning({
                    description: fmtErr("GenericError"),
                });
            });
        };
    }, []);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if (event.key === ShortKeys.ENTER) {
            (event.target as HTMLElement).blur();
        }
    };

    ///////////////////////////////////////
    ///         Everything else         ///
    ///////////////////////////////////////

    // React Query refresh function to invalidate the cache
    const onRefresh = useCallback(() => {
        // should have same format as useShowTaskArea, because use same update and setQueryCache
        queryClient.invalidateQueries([
            TaskAreaKeys.SHOW,
            requestConfig,
            taskArea.id,
            null,
            null,
        ]);
    }, [taskArea.id, requestConfig]);

    return (
        <FormProvider {...form}>
            <Form
                onKeyDown={handleKeyDown}
                onBlur={form.handleSubmit(debounceOnSubmit, (props) =>
                    console.error("handleSubmit: invalid response", props),
                )}
                data-testid="GanttTaskForm-Form"
            >
                <GanttHeaderForm
                    onRefresh={onRefresh}
                    onClose={() => closePaper("ganttTask")}
                    data={taskArea}
                    onSubmit={onSubmit}
                    readonly={false}
                />
                <Content data-testid="GanttTaskForm-Content">
                    {/*<MainForm*/}
                    {/*    shouldDisplayConfig={*/}
                    {/*        Enum_ShouldDisplayConfigs.ganttTaskFields*/}
                    {/*    }*/}
                    {/*/>*/}
                    {/*<TaskAreaFormAccordionList*/}
                    {/*    taskArea={taskArea}*/}
                    {/*    shouldDisplayConfig={*/}
                    {/*        Enum_ShouldDisplayConfigs.ganttTaskAccordion*/}
                    {/*    }*/}
                    {/*/>*/}
                </Content>
            </Form>
        </FormProvider>
    );
};
