import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import React, { RefObject, useEffect, useRef } from "react";
import { Group, Rect, Text } from "react-konva";

import { usePlanningTheme } from "src/components/Components_Teamoty/Planning/Planning.const";

import { Type_Props_PlanningMonth } from "./PlanningDate.type";

export const PlanningMonth = ({
    height,
    width,
    date,
    dates,
    x,
    handleContextMenuDates = () => {},
}: Type_Props_PlanningMonth) => {
    const theme = usePlanningTheme();

    const refYear: RefObject<Konva.Text> = useRef<Konva.Text>(null);
    const refMonth: RefObject<Konva.Text> = useRef<Konva.Text>(null);

    useEffect(() => {
        if (refYear.current && refMonth.current) {
            const { width: monthWidth } = refMonth.current.getClientRect();
            refYear.current.x(monthWidth + 3);
        }
    }, []);

    const onContextMenu = (e: KonvaEventObject<PointerEvent>) => {
        const { clientX } = e.evt;

        const currentDate = dates.days.find(
            (d) => d.pos >= clientX - x - width,
        );
        handleContextMenuDates?.(e, currentDate);
    };

    return (
        <Group x={date.pos} onContextMenu={onContextMenu}>
            <Rect width={width * date.count} height={height} fill="white" />
            <Text
                ref={refMonth}
                text={date.monthNarrowText}
                fontSize={12}
                height={height}
                offsetX={-3}
                offsetY={4}
                verticalAlign="bottom"
                fill={theme.colorPlanningDate}
            />
            <Text
                ref={refYear}
                text={date.year.toString()}
                fontSize={14}
                height={height}
                offsetX={-3}
                offsetY={3}
                verticalAlign="bottom"
                fill={theme.colorPlanningDateYear}
            />
        </Group>
    );
};
