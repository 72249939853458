import { Dialog, DialogTitle, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { Dispatch, SetStateAction } from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";

const Styled_DialogTitle = styled(DialogTitle)(({ theme }: any) => ({
    "&.MuiDialogTitle-root": {
        margin: 0,
        padding: theme.spacing(2, 4),
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
    },
}));

const Styled_DialogContent = styled("div")(() => ({
    height: "100%",
    width: "100%",
    overflow: "auto",
    display: "flex",
}));

export type Type_props_DialogFullScreen = {
    openDialog: boolean;
    setToggleDialog: Dispatch<SetStateAction<boolean>>;
    title?: string;
    children?: any;
};

export const DialogFullScreen = ({
    openDialog,
    setToggleDialog,
    title,
    children,
}: Type_props_DialogFullScreen) => {
    const closeDialog = () => setToggleDialog(false);

    return (
        <Dialog
            data-testid={"Dialog-FullScreenDialog"}
            sx={{ p: 8, zIndex: 1200 }}
            fullScreen
            open={openDialog}
            onClose={(_event, reason) => {
                // Empêche la fermeture via backdrop ou touche ESC
                if (reason === "backdropClick" || reason === "escapeKeyDown") {
                    return;
                }
                closeDialog();
            }}
        >
            <Styled_DialogTitle data-testid="Dialog-DialogTitle">
                <Typography>{title}</Typography>
                <IconButton
                    data-testid={
                        "Dialog-DialogTitle-Button-closeFullScreenDialog"
                    }
                    onClick={closeDialog}
                >
                    <Icon variant="light" icon="xmark" />
                </IconButton>
            </Styled_DialogTitle>
            <Styled_DialogContent data-testid="Dialog-DialogContent">
                {children}
            </Styled_DialogContent>
        </Dialog>
    );
};
