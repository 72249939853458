import {
    Alert,
    AlertTitle,
    Box,
    Button,
    CircularProgress,
    CircularProgressProps,
    Stack,
    styled,
    Typography,
} from "@mui/material";
import {
    DataGridPremium,
    GridColDef,
    GridRowModel,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid-premium";
import React, { useState } from "react";

import { useSelectListAreaTypes } from "src/api/tms-commons/areaTypes";
import { selectColumn } from "src/components/Components_Common/matrix/columnDefinitions/selectColumn";

const CircularProgressWithLabel = (
    props: CircularProgressProps & { value: number },
) => {
    return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    position: "absolute",
                    inset: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.primary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
};

const rows: GridRowModel[] = [
    {
        pathName: "Zone1",
        name: "Zone 1",
        color: "red",
        areaType: "Batiment",
        areaTypeId: 1,
        id: 1,
        path: [1],
    },
    {
        pathName: "Zone1/Zone1.1",
        name: "Zone 1.1",
        color: "green",
        areaType: "Appartement",
        areaTypeId: 2,
        id: 2,
        path: [1, 2],
    },
    {
        pathName: "Zone1/Zone1.1/Zone1.1.1",
        name: "Zone 1.1.1",
        color: "blue",
        areaType: "Chambre",
        areaTypeId: 3,
        id: 3,
        path: [1, 2, 3],
    },
    {
        pathName: "Zone1/Zone1.1/Zone1.1.2",
        name: "Zone 1.1.2",
        color: "blue",
        areaType: "Salle de bain",
        areaTypeId: 4,
        id: 4,
        path: [1, 2, 4],
    },
    {
        pathName: "Zone1/Zone1.1/Zone1.1.3",
        name: "Zone 1.1.3",
        color: "blue",
        areaType: "Salon",
        areaTypeId: 5,
        id: 5,
        path: [1, 2, 5],
    },
    {
        pathName: "Zone1/Zone1.2",
        name: "Zone 1.2",
        color: "white",
        areaType: "Appartement",
        areaTypeId: 2,
        id: 6,
        path: [1, 6],
    },
];

// Loading overlay

const StyledGridOverlay = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
}));

export const DataGridDemo = () => {
    const [copiedData, setCopiedData] = useState("");

    const { data: areaTypes = [] } = useSelectListAreaTypes();

    const columnsCustomSelect: GridColDef[] = [
        {
            field: "name",
            headerName: "Area editable",
            flex: 1,
            editable: true,
        },
        {
            field: "color",
            headerName: "Color editable",
            flex: 1,
            editable: true,
        },
        selectColumn({
            field: "areaTypeId",
            headerName: "AreaType renderSelectEditInputCell",
            editable: true,
            display: "flex",
            flex: 1,
            options: areaTypes,
        }),
    ];

    // Loading overlay
    const [loading, setLoading] = useState(false); // Data loading or mutation submit(isSubmitting on Matrix)
    const [progress, setProgress] = useState(0);

    // Mock loading - start
    const handleStartLoading = () => {
        setLoading(true);
        setProgress(0);

        const timer = setInterval(() => {
            setProgress((prevProgress) => prevProgress + 10);
            if (progress > 100) {
                clearInterval(timer);
            }
        }, 500);

        return () => {
            clearInterval(timer);
        };
    };

    const handleStopLoading = () => {
        setLoading(false);
    };
    // Mock loading - stop

    const CustomLoadingOverlay = () => {
        return (
            <StyledGridOverlay>
                {/*<CircularProgress variant="determinate" value={progress} />*/}
                {/*<Typography variant="body2">{progress}%</Typography>*/}
                <CircularProgressWithLabel value={progress} />
            </StyledGridOverlay>
        );
    };
    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <Button onClick={handleStopLoading}>Stop loading</Button>
                <Box sx={{ flexGrow: 1 }} />
                <GridToolbarExport
                    slotProps={{
                        tooltip: { title: "Export data" },
                        button: { variant: "outlined", size: "medium" },
                    }}
                />
                <Button onClick={handleStartLoading}>Save</Button>
            </GridToolbarContainer>
        );
    };

    return (
        <Stack gap={8}>
            <Typography variant="h3">DataGridPremium</Typography>

            <DataGridPremium
                rows={rows}
                columns={columnsCustomSelect}
                // Affichage hierarchy
                treeData
                getTreeDataPath={(row: GridRowModel) =>
                    row?.pathName.split("/")
                }
                // Selection
                cellSelection
                // Global Props
                disableRowSelectionOnClick={true}
                disableMultipleRowSelection={true}
                disableColumnSorting={true} // désactive le tri de colonne
                disableColumnResize={false} // active le redimensionnement de colonne
                disableColumnMenu={true} // Cache le menu dans l'entête des colonnes
                hideFooter={true} // cache le footer qui affiche juste le nombre d'éléments par défaut et la pagination
                ignoreValueFormatterDuringExport={true} // Récupération de la valeur brute du champ et non celle du visuelle
                defaultGroupingExpansionDepth={-1} // permet d'empêcher le tableau de replier la hierarchy lorsque l'on copie une cellule
                // debug
                onClipboardCopy={(copiedString) => setCopiedData(copiedString)} // For debug only, can be removed
            />

            <Alert severity="info" sx={{ width: "100%", mt: 1 }}>
                <AlertTitle>Copied data:</AlertTitle>
                <code
                    style={{
                        display: "block",
                        maxHeight: 200,
                        overflow: "auto",
                        whiteSpace: "pre-line",
                    }}
                >
                    {copiedData}
                </code>
            </Alert>
            <Typography variant="h3">
                DataGridPremium Loading basic demo
            </Typography>

            <DataGridPremium
                rows={rows}
                columns={columnsCustomSelect}
                // Affichage hierarchy
                treeData
                getTreeDataPath={(row: GridRowModel) =>
                    row?.pathName.split("/")
                }
                // loading
                slots={{
                    toolbar: CustomToolbar,
                }}
                slotProps={{
                    loadingOverlay: {
                        // variant: "linear-progress",
                        variant: "circular-progress",
                    },
                }}
                loading={loading}
                // Global Props
                disableRowSelectionOnClick={true}
                disableColumnSorting={true} // désactive le tri de colonne
                disableColumnResize={false} // active le redimensionnement de colonne
                disableColumnMenu={true} // Cache le menu dans l'entête des colonnes
                hideFooter={true} // cache le footer qui affiche juste le nombre d'éléments par défaut et la pagination
                ignoreValueFormatterDuringExport={true} // Récupération de la valeur brute du champ et non celle du visuelle
                defaultGroupingExpansionDepth={-1} // permet d'empêcher le tableau de replier la hierarchy lorsque l'on copie une cellule
            />

            <Typography variant="h3">
                DataGridPremium Loading Custom demo
            </Typography>

            <DataGridPremium
                rows={rows}
                columns={columnsCustomSelect}
                // Affichage hierarchy
                treeData
                getTreeDataPath={(row: GridRowModel) =>
                    row?.pathName.split("/")
                }
                // loading
                slots={{
                    loadingOverlay: CustomLoadingOverlay,
                    toolbar: CustomToolbar,
                }}
                loading={loading}
                // Global Props
                disableRowSelectionOnClick={true}
                disableColumnSorting={true} // désactive le tri de colonne
                disableColumnResize={false} // active le redimensionnement de colonne
                disableColumnMenu={true} // Cache le menu dans l'entête des colonnes
                hideFooter={true} // cache le footer qui affiche juste le nombre d'éléments par défaut et la pagination
                ignoreValueFormatterDuringExport={true} // Récupération de la valeur brute du champ et non celle du visuelle
                defaultGroupingExpansionDepth={-1} // permet d'empêcher le tableau de replier la hierarchy lorsque l'on copie une cellule
            />
        </Stack>
    );
};
