import { Stack } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { useMemo } from "react";
import { useQueryClient } from "react-query";

import { TradeKeys } from "src/api/tms-projects/keys";
import {
    mutationUpdateTrade,
    useIndexTradesMatrix,
} from "src/api/tms-projects/trades";
import { colorPickerColumn } from "src/components/Components_Common/matrix/columnDefinitions/colorPickerColumn";
import { multiLanguesColumns } from "src/components/Components_Common/matrix/columnDefinitions/multiLanguesColumn";
import { Matrix } from "src/components/Components_Common/matrix/Matrix";
import { useProject } from "src/contexts/project";
import { useUser } from "src/contexts/user";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_MatrixTrades = {
    page: string;
    onClose: () => void;
};

export const MatrixTrades = ({ page, onClose }: Type_Props_MatrixTrades) => {
    const { requestConfig, projectLanguages = [] } = useProject();
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");

    ///////////////////////////////////////
    ///           Permission            ///
    ///////////////////////////////////////
    const { checkPermission, user } = useUser();

    const editable = useMemo(() => {
        return page
            ? checkPermission(page, "create") || checkPermission(page, "update")
            : true;
    }, [page, user]);

    ///////////////////////////////////////
    ///          Fetched data           ///
    ///////////////////////////////////////
    const queryClient = useQueryClient();

    const { data: fetchedTrades, isFetching } = useIndexTradesMatrix() || {};

    const { mutateAsync } = mutationUpdateTrade({
        forMatrix: true,
    });

    const refetch = async () => {
        await queryClient.invalidateQueries({
            queryKey: [TradeKeys.INDEX, requestConfig],
        });
    };

    ///////////////////////////////////////
    ///          Columns                ///
    ///////////////////////////////////////
    const columns: GridColDef[] = [
        colorPickerColumn({
            field: "color",
            headerName: fmtTableColumn("Color"),
            editable: editable,
            display: "flex",
        }),
        ...multiLanguesColumns({
            field: "names",
            headerName: fmtTableColumn("Name"),
            languages: projectLanguages,
            editable: editable,
            display: "flex",
            flex: 1,
        }),
        {
            field: "code",
            type: "string",
            headerName: fmtTableColumn("Code"),
            flex: 1,
            editable: editable,
        },
    ];

    ///////////////////////////////////////
    ///              Rows               ///
    ///////////////////////////////////////
    const dataRows = useMemo(() => fetchedTrades, [fetchedTrades]);

    return (
        <Stack p={4} flexGrow={1}>
            <Matrix
                onClose={onClose}
                columns={columns}
                rows={dataRows || []}
                loading={isFetching}
                refetch={refetch}
                mutateUpdate={mutateAsync}
                refreshPlanning={false}
            />
        </Stack>
    );
};
