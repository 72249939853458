import { alpha, styled } from "@mui/material";

export const Styled_GridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    backgroundColor: alpha(theme.palette.common.black, 0.7),
    ...theme.applyStyles("light", {
        backgroundColor: alpha(theme.palette.common.white, 0.7),
    }),
}));
