import { Stack, Typography } from "@mui/material";
import { isEqual } from "lodash";
import * as React from "react";
import { Dispatch, SetStateAction, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { mutationPrint } from "src/api/tms-print/print";
import {
    formatterOrientationToRadioItem,
    formatterPostPrintToPrintSettings,
} from "src/api/tms-print/print/formatters";
import {
    defaultPrintSettings,
    orientation,
    Type_post_print,
} from "src/api/tms-print/print/types";
import { mutationUpdateView } from "src/api/tms-projects/workspacesAndViews/views";
import {
    Enum_typeView,
    Type_show_view,
} from "src/api/tms-projects/workspacesAndViews/views/types";
import { TMC_Button } from "src/components";
import { RadioGroup } from "src/components/Components_Common/forms/reactHookFormComponents/RadioGroup/RadioGroup";
import { Switch } from "src/components/Components_Common/forms/reactHookFormComponents/Switch/Switch";
import { SelectCaptionPosition } from "src/components/Components_Teamoty/forms/select/SelectCaptionPosition";
import { SelectPaperFormat } from "src/components/Components_Teamoty/forms/select/SelectPaperFormat";
import { SelectPrintTimelineType } from "src/components/Components_Teamoty/forms/select/SelectPrintTimelineType";
import { useFormDefaultConfig } from "src/configurations/app";
import { usePlanningContext } from "src/contexts/planning";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Form } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { getDate } from "src/utils/date";

type Type_Props_PrintSettingsForm = {
    view: Type_show_view;
    setGeneratedFile: Dispatch<SetStateAction<string | undefined>>;
    setIsGeneratingFile: Dispatch<SetStateAction<boolean>>;
};

export const PrintSettingsForm = ({
    view,
    setGeneratedFile,
    setIsGeneratingFile,
}: Type_Props_PrintSettingsForm) => {
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Form.Cta");
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Dialog.Print.Form");
    const { viewFilters } = usePlanningContext();

    const { mutateAsync: mutatePrint, isLoading: isLoadingMutatePrint } =
        mutationPrint();
    const { mutate: mutateView } = mutationUpdateView(view.id);

    const form = useForm<Type_post_print>({
        ...useFormDefaultConfig,
        defaultValues: {
            ...defaultPrintSettings,
        },
        values:
            view?.data.type === Enum_typeView.planning ||
            view?.data.type === Enum_typeView.gantt
                ? (view?.data.settings?.print as Type_post_print)
                : undefined,
    });

    const displayCaptionWatch = form.watch("displayCaption");

    const handleSubmit = async (
        values: Type_post_print,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault();

        // Call download pdf file
        await mutatePrint({
            settings: {
                ...values,
                withDaysOff: viewFilters.withDaysOff,
                withWeekend: viewFilters.withWeekend,
                callDate: getDate().toISOString(),
            },
            shouldDownloadDirectly: true,
        });

        // Store current print settings in the view
        const printSettings = formatterPostPrintToPrintSettings(values);

        // Compare with previous settings before saving on the view
        if (
            (view.data.type === Enum_typeView.planning ||
                view.data.type === Enum_typeView.gantt) &&
            !isEqual(printSettings, view.data?.settings?.print)
        ) {
            mutateView({
                data: {
                    ...view.data,
                    settings: {
                        ...(view.data?.settings ? view.data?.settings : {}),
                        print: printSettings,
                    },
                },
            });
        }
    };

    const generateFile = async (e?: React.BaseSyntheticEvent) => {
        e?.preventDefault();

        setIsGeneratingFile(true);
        // Call download pdf file
        const { data } = await mutatePrint({
            settings: {
                ...form.getValues(),
                withDaysOff: viewFilters.withDaysOff,
                withWeekend: viewFilters.withWeekend,
                callDate: getDate().toISOString(),
            },
            shouldDownloadDirectly: false,
        }).finally(() => setIsGeneratingFile(false));

        const file = new Uint8Array(data);

        let binaryString = "";
        for (let i = 0; i < file.length; i++) {
            binaryString += String.fromCharCode(file[i]);
        }

        setGeneratedFile("data:application/pdf;base64," + btoa(binaryString));
    };

    useEffect(() => {
        // On open, generate a pdf file
        generateFile();
    }, []);

    return (
        <FormProvider {...form}>
            <Form
                onSubmit={form.handleSubmit(handleSubmit)}
                id={"print-settings"}
                sx={(theme) => ({
                    gap: theme.spacing(4),
                })}
            >
                <Stack
                    gap={2}
                    direction="column"
                    alignSelf={"stretch"}
                    paddingBottom={2}
                >
                    <Typography variant="h3">{fmt("Format")}</Typography>
                    <SelectPaperFormat
                        name={"paperFormat"}
                        label={fmt("Format")}
                        fullWidth
                    />
                    <RadioGroup
                        name={"orientation"}
                        label={fmt("Layout")}
                        options={formatterOrientationToRadioItem(
                            Object.values(orientation),
                        )}
                    />
                    <SelectPrintTimelineType
                        name={"timelineType"}
                        label={fmt("Timeline")}
                        fullWidth
                    />
                </Stack>
                <Stack
                    gap={2}
                    direction="column"
                    alignSelf={"stretch"}
                    paddingBottom={2}
                    flexGrow={1}
                >
                    <Typography variant="h3">{fmt("Caption")}</Typography>
                    <Switch
                        name={"displayCaption"}
                        label={fmt("ShowCaption")}
                        labelPlacement={"start"}
                        sx={{ justifyContent: "space-between" }}
                    />
                    <SelectCaptionPosition
                        name={"captionPosition"}
                        label={fmt("CaptionPosition")}
                        disabled={!displayCaptionWatch}
                    />
                    <Switch
                        name={"displayCaptionKeydates"}
                        label={fmt("DisplayKeyDates")}
                        labelPlacement={"start"}
                        sx={{ justifyContent: "space-between" }}
                        disabled={!displayCaptionWatch}
                    />
                </Stack>
                <Stack gap={2} direction="row-reverse">
                    <TMC_Button
                        type="submit"
                        disabled={
                            form.formState.isSubmitting ||
                            form.formState.isLoading
                        }
                    >
                        {fmtActions("Download")}
                    </TMC_Button>
                    <TMC_Button
                        variant="outlined"
                        disabled={
                            form.formState.isSubmitting ||
                            form.formState.isLoading ||
                            isLoadingMutatePrint
                        }
                        onClick={generateFile}
                    >
                        {fmtActions("Generate")}
                    </TMC_Button>
                </Stack>
            </Form>
        </FormProvider>
    );
};
