import { GridCellParams, GridColDef } from "@mui/x-data-grid-premium";
import React, { useMemo } from "react";

import { useSelectListSequenceFolders } from "src/api/tms-scheduling/sequenceFolders";
import { formatterSelectListSequenceFolders } from "src/api/tms-scheduling/sequenceFolders/formatters";
import { useIndexSequences } from "src/api/tms-scheduling/sequences";
import { LoadingBox } from "src/components";
import { colorPickerColumn } from "src/components/Components_Common/matrix/columnDefinitions/colorPickerColumn";
import { selectColumn } from "src/components/Components_Common/matrix/columnDefinitions/selectColumn";
import { Matrix } from "src/components/Components_Common/matrix/Matrix";
import { useUser } from "src/contexts/user";
import { useCoreIntl } from "src/hooks/useCoreIntl";

// type Type_Props_MatrixSequence = {};

export const MatrixSequences = () => {
    const page = "sequences";

    //i18n
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");

    // Fetch Data
    const {
        data: dataSequencesFolder = [],
        isFetching: isFetchingSequenceFolder,
    } = useSelectListSequenceFolders();
    const { data: dataSequences = [], isFetching: isFetchingSequences } =
        useIndexSequences();

    const { checkPermission, user } = useUser();
    const editable = useMemo(() => {
        return page
            ? checkPermission(page, "create") || checkPermission(page, "update")
            : true;
    }, [page, user]);

    const isFetching = useMemo(
        () => isFetchingSequences || isFetchingSequenceFolder,
        [isFetchingSequences, isFetchingSequenceFolder],
    );

    const getHiddenCellClassName = (params: GridCellParams<any, any, any>) => {
        if (!params.isEditable) {
            // checkboxes have to be hidden by css
            return "Teamoty-dataGrid-cell-hidden";
        }
        return "";
    };

    const columns: GridColDef[] = useMemo(
        () => [
            selectColumn({
                field: "sequenceFolderId",
                headerName: fmtTableColumn("SequenceFolder"),
                editable: editable,
                options: formatterSelectListSequenceFolders(
                    dataSequencesFolder!,
                ),
                flex: 1,
            }),
            {
                field: "name",
                headerName: fmtTableColumn("Name"),
                flex: 1,
                display: "flex",
                editable: editable,
            },
            {
                field: "code",
                headerName: fmtTableColumn("Code"),
                flex: 1,
                display: "flex",
                editable: editable,
            },
            colorPickerColumn({
                field: "color",
                headerName: fmtTableColumn("Color"),
                editable: editable,
                display: "flex",
                width: 30,
            }),
            {
                field: "earliestDate",
                headerName: fmtTableColumn("StartDate"),
                flex: 1,
                type: "date",
                editable: editable,
                align: "center",
            },
            {
                field: "fixedSize",
                type: "boolean",
                headerName: fmtTableColumn("DurationScaling"),
                editable: editable,
                flex: 1,
                cellClassName: getHiddenCellClassName,
            },
            {
                field: "optimizeByArea",
                type: "boolean",
                headerName: fmtTableColumn("AutoOptimization"),
                editable: editable,
                flex: 1,
                cellClassName: getHiddenCellClassName,
            },
        ],
        [isFetchingSequences, isFetchingSequenceFolder],
    );

    return (
        <>
            {isFetching ? (
                <LoadingBox />
            ) : (
                <Matrix columns={columns} rows={dataSequences} />
            )}
        </>
    );
};
