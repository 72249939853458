import { SceneContext } from "konva/lib/Context";
import React from "react";
import { Group, Rect } from "react-konva";

import {
    colorFlowShapeStroke,
    heightFlowShape,
    paddingFlowText,
    sizeFlowGridX,
    sizeFlowGridY,
    widthFlowShapeStroke,
} from "src/components/Components_Teamoty/Flow/Flow.const";
import { Conv_typeFlowTask } from "src/components/Components_Teamoty/Flow/Flow.enum";
import { getTaskProps } from "src/components/Components_Teamoty/Flow/tools/getTaskProps";
import { getTaskWidth } from "src/components/Components_Teamoty/Flow/tools/getTaskWidth";
import { invertColor } from "src/utils/colors";

import {
    components_shape_flow,
    Type_props_components_shape_flow,
} from "./FlowShape.const";
import { Type_Props_FlowShapeTaskBase } from "./FlowShape.type";
import { FlowShapeTextTask } from "./FlowShapeTextTask";
import { FlowShapeWorkMilestone } from "./FlowShapeWorkMilestone";

export const FlowShapeTaskBase = ({
    task,
    drag,
    onChange = () => {},
    fixedSize,
}: Type_Props_FlowShapeTaskBase) => {
    const color: string = invertColor(task.color);

    const padding: number = paddingFlowText;
    const width: number = getTaskWidth(task, fixedSize);
    const height: number = heightFlowShape;

    const typeShape: Type_props_components_shape_flow = Conv_typeFlowTask[
        task.type
    ] as Type_props_components_shape_flow;
    const shapeFlowFunction = components_shape_flow[typeShape];

    const [props, lines] = getTaskProps(typeShape, width, task);

    return (
        <Group>
            <Rect
                x={task.pt.x - sizeFlowGridX / 6}
                y={task.pt.y - sizeFlowGridY / 6}
                width={width + sizeFlowGridX / 3}
                height={height + sizeFlowGridY / 3}
            />

            {drag.dragging && (
                <FlowShapeWorkMilestone
                    shapeFlowFunction={shapeFlowFunction}
                    x={drag.pt.x}
                    y={drag.pt.y}
                    pt={task.pt}
                    width={width}
                    height={height}
                    opacity={0.5}
                    fill={task.color}
                    listening={false}
                />
            )}

            <FlowShapeWorkMilestone
                name="preview"
                shapeFlowFunction={shapeFlowFunction}
                pt={task.pt}
                width={width}
                height={height}
                fill={task.color}
                stroke={task.color}
                strokeWidth={widthFlowShapeStroke}
                listening={false}
            />

            <Group
                clipFunc={(ctx: SceneContext): void => {
                    shapeFlowFunction(
                        task.pt,
                        width,
                        height,
                        ctx,
                        undefined,
                        1,
                    );
                }}
            >
                <Rect
                    x={task.pt.x + widthFlowShapeStroke / 2}
                    y={task.pt.y + height / 2}
                    width={width - widthFlowShapeStroke}
                    height={height / 2 - widthFlowShapeStroke / 2}
                    fill="white"
                    listening={false}
                />

                {lines}

                <FlowShapeTextTask
                    code={task?.code ?? undefined}
                    name={task?.name}
                    fill={color}
                    background={task.color}
                    maxWidth={width - 2 * padding}
                    height={height / 2 - padding / 2}
                    x={task.pt.x + padding}
                    y={task.pt.y + padding / 2}
                    onChange={(value: string): void => {
                        onChange({
                            name: value,
                        });
                    }}
                    editOffsetY={4}
                />

                {props}
            </Group>

            <FlowShapeWorkMilestone
                shapeFlowFunction={shapeFlowFunction}
                pt={task.pt}
                width={width}
                height={height}
                stroke={colorFlowShapeStroke}
                strokeWidth={widthFlowShapeStroke}
                listening={false}
            />
        </Group>
    );
};
