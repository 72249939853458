import {
    IconButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_HeaderDropDownMenu = {
    openEditDrawingDrawer: () => void;
};

export const HeaderDropDownMenu = ({
    openEditDrawingDrawer,
}: Type_Props_HeaderDropDownMenu) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.Drawings.Drawing.DropDownMenu",
    );

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <IconButton
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                size="small"
                data-testid="Page-Drawing-Header-DropDownMenu"
            >
                <Icon
                    variant="solid"
                    icon="ellipsis-vertical"
                    fontSize="small"
                />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem
                    data-testid="Page-Drawing-Header-DropDownMenu-EditDrawing"
                    key="EditDrawing"
                    onClick={() => {
                        handleClose();
                        openEditDrawingDrawer();
                    }}
                >
                    <ListItemIcon>
                        <Icon variant="light" icon="pen" />
                    </ListItemIcon>
                    <ListItemText>{fmt("EditDrawing")}</ListItemText>
                </MenuItem>
                <MenuItem
                    data-testid="Page-Drawing-Header-DropDownMenu-DuplicateDrawing"
                    key="DuplicateDrawing"
                    onClick={() => {
                        handleClose();
                        alert("Not implemented");
                    }}
                >
                    <ListItemIcon>
                        <Icon variant="light" icon="copy" />
                    </ListItemIcon>
                    <ListItemText>{fmt("DuplicateDrawing")}</ListItemText>
                </MenuItem>
                <MenuItem
                    data-testid="Page-Drawing-Header-DropDownMenu-DeleteDrawing"
                    key="DeleteDrawing"
                    onClick={() => {
                        handleClose();
                        alert("Not implemented");
                    }}
                    sx={(theme) => ({ color: theme.palette.error.main })}
                >
                    <ListItemIcon>
                        <Icon variant="light" icon="trash" color="error" />
                    </ListItemIcon>
                    <Typography color="error">
                        {fmt("DeleteDrawing")}
                    </Typography>
                </MenuItem>
            </Menu>
        </>
    );
};
