import { GridRowId, GridRowModel } from "@mui/x-data-grid-pro";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useDownloadReportTemplate } from "src/api/tms-scheduling/reportTemplate";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import {
    GRID_HEIGHT,
    Table,
    Type_column,
    Type_menuDropDown,
} from "src/components/Components_Common/Table/Table";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { ReportDrawer } from "src/drawers/report/ReportDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Type_modalDeleteReport } from "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_Reports/Page_Reports.container";

export type Type_Props_Page_ReportComponent = {
    reports: any[];
    isFetching: boolean;
    changeStatus: (id: number, report: any) => void;
    deleteReport: (id: number) => void;
    modalDeleteReport: Type_modalDeleteReport;
    setModalDeleteReport: Dispatch<SetStateAction<Type_modalDeleteReport>>;
};

export const Page_ReportsComponent = ({
    reports,
    isFetching,
    changeStatus,
    deleteReport,
    modalDeleteReport,
    setModalDeleteReport,
}: Type_Props_Page_ReportComponent) => {
    const { formatMessageWithPartialKey: fmtTableDataName } =
        useCoreIntl("Table.DataName");
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");

    const page = "reports";

    const [reportId, setReportId] = useState<number | null>(null);
    const [selectedReport, setSelectedReport] = useState<number | null>(null);
    const [openReportDrawer, setOpenReportDrawer] = useState(false);
    const [action, setAction] = useState<Type_action>("create");

    const { refetch } = useDownloadReportTemplate(reportId!);

    const openDrawer = (action: Type_action, reportId: number | null) => {
        setSelectedReport(reportId);
        setAction(action);
        setOpenReportDrawer(true);
    };

    const handleDownloadReportTemplate = (id: number) => {
        setReportId(id);
    };

    useEffect(() => {
        if (reportId) {
            refetch().then(() => setReportId(null)); // download file and reset reportId
        }
    }, [reportId]);

    const handleReportCreate = () => openDrawer("create", null);

    const handleReportEdit = (reportId: number) =>
        openDrawer("update", reportId);

    const handleCloseReportDrawer = () => {
        setOpenReportDrawer(false);
    };

    const dropDownItems: Type_menuDropDown = {
        callbackEdit: ({ id }: GridRowModel) => handleReportEdit(id),
        callbackDownload: (row: GridRowModel) =>
            handleDownloadReportTemplate(row.id),
        callbackStatus: (row: GridRowModel) => changeStatus(row.id, row),
        callbackDelete: (row: GridRowModel) =>
            setModalDeleteReport({
                isOpen: true,
                props: row,
            }),
    };

    const gridColumns: Type_column[] = [
        {
            field: "name",
            headerName: fmtTableColumn("Name"),
        },
        {
            field: "createdAt",
            headerName: fmtTableColumn("DateCreate"),
            colType: "dates",
        },
        {
            field: "user",
            headerName: fmtTableColumn("Owner"),
        },
    ];

    return (
        <>
            <HeaderGrid>
                <AddButton
                    data-testid="Report-AddOne-btn"
                    onClick={() => handleReportCreate()}
                    page={page}
                />
            </HeaderGrid>
            <Table
                rows={reports}
                columns={gridColumns}
                disableColumnSorting
                noDataConfig={{
                    isFetching: isFetching,
                    dataName: fmtTableDataName("Report"),
                    action: () => handleReportCreate(),
                }}
                onClick={(id: GridRowId) => {
                    handleReportEdit(parseInt(id as string));
                }}
                menuDropDown={dropDownItems}
                page={page}
                height={GRID_HEIGHT}
                showSearchBar
            />
            <ModalDelete
                open={modalDeleteReport.isOpen}
                onClose={() => setModalDeleteReport({ isOpen: false })}
                item={fmtTableDataName("Report")}
                validationString={modalDeleteReport.props?.name}
                actions={{
                    onSubmit: {
                        onClick: () => {
                            setModalDeleteReport({ isOpen: false });
                            deleteReport(modalDeleteReport.props?.id);
                        },
                    },
                }}
            />
            <ReportDrawer
                open={openReportDrawer}
                onClose={handleCloseReportDrawer}
                reportIdToUpdate={selectedReport}
                action={action}
                page={page}
            />
        </>
    );
};
