import { DataGridPremiumProps, GridRowModel } from "@mui/x-data-grid-premium";
import React, { ReactElement } from "react";

import {
    Matrix,
    Type_Props_MemoizedDataGridPremium,
} from "src/components/Components_Common/matrix/Matrix";
import { CustomGroupingCell } from "src/components/Components_Common/TreeTable/CustomGroupingCell/CustomGroupingCell";

type Type_Props_MatrixTree = Type_Props_MemoizedDataGridPremium & {
    getTreeDataPath?: (row: GridRowModel) => string;
    groupingCellComponent?: (row: GridRowModel) => ReactElement;
    groupingHeaderName?: string;
    keysToUpdateSolo?: string[];
};

export const MatrixTree = ({
    columns,
    getTreeDataPath = (row: GridRowModel) => row.path,
    groupingCellComponent,
    groupingHeaderName,
    mutateUpdate, // TODO remove, replace by handleSubmit
    refetch,
    keysToUpdateSolo,
    ...props
}: Type_Props_MatrixTree) => {
    const groupingColDef: DataGridPremiumProps["groupingColDef"] = {
        headerName: groupingHeaderName || "",
        ...(groupingCellComponent && {
            renderCell: (params) => (
                <CustomGroupingCell {...params}>
                    {groupingCellComponent(params.row)}
                </CustomGroupingCell>
            ),
        }),
    };

    return (
        <Matrix
            {...props}
            columns={columns}
            treeData={true}
            groupingColDef={groupingColDef}
            getTreeDataPath={getTreeDataPath}
            mutateUpdate={mutateUpdate}
            refetch={refetch}
            keysToUpdateSolo={keysToUpdateSolo}
        />
    );
};
