import { planningDateClass } from "@cimba-digital-construction-solution/utils/dist/class/planningDateClass";
import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import React from "react";
import { Group, Shape } from "react-konva";

import { useMenuItems } from "src/components/Components_Teamoty/Planning/Dialogs/menusConf";
import {
    heightPlanningDate,
    heightPlanningMonth,
    usePlanningTheme,
    widthPlanningDateMinForDay,
    widthPlanningDateMinForWeek,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import { getGridVertical } from "src/components/Components_Teamoty/Planning/tools/getGridVertical";
import { useChannel } from "src/hooks/useChannel";

import { getDates } from "./getDates";
import { getQuarters } from "./getQuarters";
import { getWeeks } from "./getWeeks";
import { Type_Props_PlanningDates } from "./PlanningDate.type";
import { PlanningDateLine } from "./PlanningDateLine";
import { PlanningMonth } from "./PlanningMonth";

export type Type_planningProps = {
    date?: planningDateClass;
    areas?: string[];
    id?: number;
};

export const PlanningDates = ({
    dates,
    x,
    y,
    width,
    widthDate,
}: Type_Props_PlanningDates) => {
    const theme = usePlanningTheme();
    const { menuItemsPlanningDates } = useMenuItems();

    const handleContextMenuDates = (
        { evt }: KonvaEventObject<PointerEvent>,
        date?: planningDateClass,
    ) => {
        const { clientX, clientY } = evt;

        sendEvent("dropDownOnRightClick", {
            open: true,
            mouseX: clientX,
            mouseY: clientY,
            menuItems: menuItemsPlanningDates,
            props: {
                date: date ?? undefined,
            },
        });
    };

    const elmMonths: Array<React.JSX.Element> = [];
    const elmMonthsLine: Array<React.JSX.Element> = [];
    dates.months.map((date: planningDateClass, index: number): void => {
        elmMonths.push(
            <PlanningMonth
                key={"m" + index}
                height={heightPlanningMonth}
                width={widthDate}
                date={date}
                dates={dates}
                x={x}
                handleContextMenuDates={handleContextMenuDates}
            />,
        );
        elmMonthsLine.push(
            <PlanningDateLine
                key={"ml" + index}
                height={heightPlanningMonth}
                width={widthDate}
                date={date}
            />,
        );
    });

    let elmDates: Array<React.JSX.Element>;
    let elmDatesLine: Array<React.JSX.Element>;
    let elmDayKeys: Array<React.JSX.Element> = [];

    if (widthDate > widthPlanningDateMinForDay) {
        [elmDates, elmDatesLine, elmDayKeys] = getDates(
            dates,
            widthDate,
            handleContextMenuDates,
        );
    } else if (widthDate > widthPlanningDateMinForWeek) {
        [elmDates, elmDatesLine] = getWeeks(dates, widthDate);
    } else {
        [elmDates, elmDatesLine] = getQuarters(dates, widthDate);
    }

    const [elmRects] = getGridVertical(heightPlanningDate, dates, widthDate);

    const { sendEvent } = useChannel({});

    return (
        <Group
            x={x}
            y={y}
            clipX={-0.5}
            clipY={-0.5}
            clipWidth={width + 0.5}
            clipHeight={heightPlanningDate + heightPlanningMonth + 1}
        >
            <Group>{elmMonths}</Group>
            <Group y={heightPlanningMonth}>
                {elmRects}
                {elmDayKeys}
                {elmDates}
            </Group>
            <Group offsetY={heightPlanningMonth}>{elmMonthsLine}</Group>
            <Group>{elmMonthsLine}</Group>
            <Group y={heightPlanningMonth}>{elmDatesLine}</Group>
            <Shape
                sceneFunc={(
                    context: Konva.Context,
                    shape: Konva.Shape,
                ): void => {
                    context.beginPath();
                    context.moveTo(0, 0);
                    context.lineTo(0, heightPlanningDate + heightPlanningMonth);
                    context.lineTo(
                        width,
                        heightPlanningDate + heightPlanningMonth,
                    );
                    context.lineTo(width, 0);
                    context.strokeShape(shape);
                }}
                strokeWidth={1}
                stroke={theme.colorPlanningLine}
            />
        </Group>
    );
};
