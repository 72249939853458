import { Skeleton, Stack } from "@mui/material";
import { unix } from "dayjs";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import {
    mutationDeleteKeyDate,
    useShowKeyDate,
} from "src/api/tms-scheduling/keyDates";
import { TMC_Button } from "src/components/Components_Common";
import { Dialog } from "src/components/Components_Common/Dialog/Dialog";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { usePlanningContext } from "src/contexts/planning";
import { KeyDateForm } from "src/forms/keyDate/KeyDateForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Type_DialogParamsRightClick } from "./index";

export const KeyDateDialog = () => {
    const { formatMessageWithPartialKey: fmtAction } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl("Labels");

    const { lang } = useParams();

    const { toggleDialog, dialogs } = usePlanningContext();

    const [isLoading, setIsLoading] = useState(false);
    const [dialogParams] = useState<Type_DialogParamsRightClick>({
        open: dialogs.models.keyDate,
        action: dialogs.props?.id ? "update" : "create",
        data: {
            date: dialogs.props?.date?.timestamp
                ? unix(dialogs.props.date.timestamp)
                : null,
            id: dialogs.props?.id,
        },
    });
    const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);

    const { data, isFetching } = useShowKeyDate(dialogParams?.data?.id);
    const { mutate: mutateDeleteKeyDate } = mutationDeleteKeyDate(
        dialogParams?.data?.id as number,
    );

    const closeDialog = () => {
        toggleDialog("keyDate");
    };

    const title = `${dialogParams?.data?.id ? fmtAction("Update") : fmtAction("New")} ${fmtLabels("KeyDate")}`;

    const defaultValues = useMemo(
        () =>
            data
                ? data
                : {
                      date: dialogParams?.data?.date,
                      areas: dialogs.props?.areas,
                  },
        [data],
    );

    return (
        <>
            <Dialog
                name="keyDate"
                open={dialogParams.open}
                dialogTitle={title}
                isLoading={isLoading}
                onClose={closeDialog}
                actions={
                    <Stack
                        flexDirection={"row"}
                        justifyContent={
                            dialogParams.action === "update"
                                ? "space-between"
                                : "end"
                        }
                        width={"100%"}
                    >
                        {dialogParams.action === "update" && (
                            <TMC_Button
                                onClick={() => setOpenModalDelete(true)}
                                type="button"
                                size="medium"
                                variant="text"
                                color={"secondary"}
                                startIcon={
                                    !isLoading && (
                                        <Icon variant="solid" icon="trash" />
                                    )
                                }
                                data-testid={`dialog-keyDate-delete-btn`}
                            >
                                {fmtAction("Delete")}
                            </TMC_Button>
                        )}
                        <TMC_Button
                            form={"keyDate"}
                            type="submit"
                            size="medium"
                            variant="contained"
                            disabled={isLoading}
                            startIcon={
                                !isLoading && (
                                    <Icon variant="solid" icon="check" />
                                )
                            }
                            data-testid={`dialog-keyDate-save-btn`}
                        >
                            {isLoading ? (
                                <Icon
                                    variant="solid"
                                    icon="spinner-third"
                                    spin
                                />
                            ) : (
                                fmtAction("Save")
                            )}
                        </TMC_Button>
                    </Stack>
                }
            >
                {!isFetching && (
                    <KeyDateForm
                        setIsLoading={setIsLoading}
                        defaultValues={defaultValues}
                        closeDialog={closeDialog}
                    />
                )}
                {isFetching && (
                    <Stack rowGap={6}>
                        <Skeleton variant="rounded" height={139} />
                        <Skeleton variant="rounded" height={62} />
                        <Skeleton variant="rounded" height={62} />
                        <Skeleton variant="rounded" height={62} />
                        <Skeleton variant="rounded" height={62} />
                        <Skeleton variant="rounded" height={60} />
                    </Stack>
                )}
            </Dialog>
            <ModalDelete
                open={openModalDelete}
                onClose={() => setOpenModalDelete(false)}
                item={fmtLabels("KeyDate")}
                actions={{
                    onSubmit: {
                        onClick: () => {
                            mutateDeleteKeyDate();
                            setOpenModalDelete(false);
                            closeDialog();
                        },
                    },
                }}
                validationString={data?.names[lang as string] as string}
            />
        </>
    );
};
