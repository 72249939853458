import {
    DialogFullScreen,
    Type_props_DialogFullScreen,
} from "src/components/Components_Common/Dialog/DialogFullScreen";
import { MatrixTaskAreaResourcesTask } from "src/components/Components_Scheduling/Matrix/MatrixTaskAreaResourcesTask/MatrixTaskAreaResourcesTask";

type Type_Props_MatrixTaskAreaResourcesTaskFullScreen =
    Type_props_DialogFullScreen & {
        taskId: number;
    };

export const MatrixTaskAreaResourcesTaskFullScreen = ({
    openDialog,
    setToggleDialog,
    title,
    taskId,
}: Type_Props_MatrixTaskAreaResourcesTaskFullScreen) => {
    return (
        <DialogFullScreen
            openDialog={openDialog}
            setToggleDialog={setToggleDialog}
            title={title}
        >
            <MatrixTaskAreaResourcesTask
                taskId={taskId}
                onClose={() => setToggleDialog(false)}
            />
        </DialogFullScreen>
    );
};
