export enum AreaWorkLevelKeys {
    SELECT_LIST = "selectListAreaWorkLevels",
}

export enum ConstraintKeys {
    INDEX = "indexConstraints",
}

export enum ConstraintCommentKeys {
    INDEX = "indexConstraintComments",
}

export enum KeyDateKeys {
    INDEX = "indexKeyDates",
    SHOW = "showKeyDate",
}

export enum PeopleKeys {
    SELECT = "selectPeople",
    SHOW = "showPeople",
}

export enum PeopleTaskKeys {
    INDEX = "indexPeopleTask",
}

export enum PeopleTaskAreaKeys {
    INDEX = "indexPeopleTaskArea",
    INDEX_FOR_MATRIX = "indexPeopleTaskAreaForMatrix",
}

export enum ProductTypeTaskKeys {
    INDEX = "indexProductTypeTasks",
    SHOW = "showProductTypeTask",
}

export enum ProgressHistoryKeys {
    INDEX = "indexProgressHistory",
    SHOW = "showProgressHistory",
}

export enum ReportKeys {
    INDEX = "indexReport",
    SHOW = "showReport",
    DOWNLOAD = "downloadReport",
}

export enum ResourceTaskKeys {
    INDEX = "indexResourceTasks",
    SHOW = "showResourceTask",
}

export enum SequenceAreaKeys {
    INDEX = "indexSequenceAreas",
    INDEX_FOR_MATRIX = "indexSequenceAreasForMatrix",
}

export enum SequenceFolderKeys {
    INDEX = "indexSequenceFolders",
    SHOW = "showSequenceFolder",
    SELECT_LIST = "selectListSequenceFolders",
    TREE = "treeSequenceFolders",
}

export enum SequenceNoteKeys {
    INDEX = "indexSequenceNotes",
    SHOW = "showSequenceNote",
    SELECT_LIST = "selectListSequenceNotes",
}

export enum SequenceKeys {
    INDEX = "indexSequences",
    SHOW = "showSequence",
    SHOW_FOR_FLOW = "showSequenceForFlow",
}

export enum TagKeys {
    SELECT = "selectTags",
}

export enum TaskAreaKeys {
    INDEX = "indexTaskArea",
    INDEX_FOR_MATRIX = "indexTaskAreasForMatrix",
    SHOW = "showTaskArea",
}

export enum TaskLinkKeys {
    INDEX = "indexTaskLinks",
    SHOW = "showTaskLink",
}

export enum TaskNoteKeys {
    INDEX = "indexTaskNotes",
}

export enum TaskAreaNoteKeys {
    INDEX = "indexTaskAreaNotes",
}

export enum TaskAreaResourceKeys {
    INDEX_FOR_MATRIX = "indexTaskAreaResourceForMatrix",
}

export enum TaskKeys {
    INDEX = "indexTasks",
    INDEX_FOR_MATRIX = "indexTasksForMatrix",
    SHOW = "showTask",
}

export enum TaskAreaProductTypeKeys {
    INDEX = "indexProductTypeTaskAreas",
    INDEX_FOR_MATRIX = "indexTaskAreaProductTypeForMatrix",
}
