import { Type_index_view } from "src/api/tms-projects/workspacesAndViews/views/types";
import { ViewDashboard } from "src/components/Components_Teamoty/views/ViewDashboard";
import { ViewEmpty } from "src/components/Components_Teamoty/views/ViewEmpty";
import { ViewGantt } from "src/components/Components_Teamoty/views/ViewGantt/ViewGantt";
import { ViewPlanning } from "src/components/Components_Teamoty/views/ViewPlanning/ViewPlanning";
import { useWorkspaceViewContext } from "src/contexts/workspaces";
import { Provider_ContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

export type Type_Props_ViewData = {
    viewData?: Type_index_view;
};

export const Page_Views = () => {
    const { currentView } = useWorkspaceViewContext();
    // list of views component to complete
    const viewsComponents = {
        dashboard: ViewDashboard,
        planning: ViewPlanning,
        gantt: ViewGantt,
        workflow: ViewEmpty,
        areamap: ViewEmpty,
        grid: ViewEmpty,
    };

    const SpecificView = currentView
        ? viewsComponents[currentView?.data?.type]
        : ViewEmpty;

    return (
        <Provider_ContextualDrawer>
            {currentView ? <SpecificView /> : <> NO CURRENT VIEW </>}
        </Provider_ContextualDrawer>
    );
};
