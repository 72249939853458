import { Type_put_task } from "src/api/tms-scheduling/tasks/types";

//
type extendedType_fieldsList = {
    tradeId?: number; // forMatrix : MatrixSequence
    subTradeId?: number; // forMatrix : MatrixSequence
    companyId?: number; // forMatrix : MatrixSequence
};

type Type_fieldsList = keyof Type_put_task | keyof extendedType_fieldsList;

const taskFieldsByType: { [key: number]: Type_fieldsList[] } = {
    // work
    1: [
        "withTradeColor",
        "tradeId",
        "subTrade",
        "subTradeId",
        "company",
        "companyId",
        "duration",
        "areaQuantity",
        "workLevel",
        "areaRhythmLevel",
        "areaRhythmBreak",
        "team",
        "workforce",
        "daily",
        "flow",
        "flowRhythmBreak",
        "flowRhythmLevel",
        "slideOutResourceType",
        "slideOutMovementType",
        "checking",
        "follow",
        "progressValue",
    ],
    // study
    2: [
        "withTradeColor",
        "tradeId",
        "subTrade",
        "subTradeId",
        "company",
        "companyId",
        "duration",
        "areaQuantity",
        "workLevel",
        "daily",
        "checking",
        "follow",
        "progressValue",
    ],
    // waiting
    3: ["duration", "checking", "follow", "progressValue"],
    // milestone
    4: [
        "withTradeColor",
        "tradeId",
        "subTrade",
        "subTradeId",
        "company",
        "companyId",
        "checking",
        "follow",
    ],
    // delivery
    5: [
        "withTradeColor",
        "tradeId",
        "subTrade",
        "subTradeId",
        "company",
        "companyId",
        "checking",
        "follow",
    ],
    // technical
    6: [
        "duration",
        "areaQuantity",
        "workLevel",
        "areaRhythmBreak",
        "areaRhythmLevel",
        "daily",
        "flow",
        "flowRhythmBreak",
        "flowRhythmLevel",
        "slideOutResourceType",
        "slideOutMovementType",
    ],
};

type Type_accordionList =
    | "links"
    | "date"
    | "automaticOptimization"
    | "waitingDays"
    | "ratio"
    | "slideOut"
    | "assignees"
    | "resources"
    | "materials"
    | "advanced"
    | "people"
    | "observations"
    | "progression";

const accordionByTaskType: { [key: number]: Type_accordionList[] } = {
    // work
    1: [
        "links",
        "date",
        "automaticOptimization",
        "waitingDays",
        "ratio",
        "slideOut",
        "assignees",
        "resources",
        "materials",
        "advanced",
        "progression",
        "observations",
    ],
    // study
    2: [
        "links",
        "date",
        "automaticOptimization",
        "waitingDays",
        "assignees",
        "advanced",
        "progression",
        "observations",
    ],
    // waiting
    3: [
        "links",
        "date",
        "automaticOptimization",
        "advanced",
        "progression",
        "people",
    ],
    // milestone
    4: ["links", "date", "automaticOptimization", "advanced", "observations"],
    // delivery
    5: ["links", "date", "automaticOptimization", "advanced", "observations"],
    // technical
    6: [
        "links",
        "date",
        "automaticOptimization",
        "waitingDays",
        "slideOut",
        "observations",
    ],
};

const ganttTaskFieldsByType: { [key: number]: Type_fieldsList[] } = {
    // work
    1: [
        "withTradeColor",
        "subTrade",
        "subTradeId",
        "company",
        "companyId",
        "duration",
        "team",
        "workforce",
        "daily",
        "slideOutResourceType",
        "slideOutMovementType",
        "checking",
        "follow",
        "progressValue",
    ],
    // study
    2: [
        "withTradeColor",
        "subTrade",
        "subTradeId",
        "company",
        "companyId",
        "duration",
        "daily",
        "checking",
        "follow",
        "progressValue",
    ],
    // waiting
    3: ["duration", "checking", "follow", "progressValue"],
    // milestone
    4: [
        "withTradeColor",
        "subTrade",
        "subTradeId",
        "company",
        "companyId",
        "checking",
        "follow",
    ],
    // delivery
    5: [
        "withTradeColor",
        "subTrade",
        "subTradeId",
        "company",
        "companyId",
        "checking",
        "follow",
    ],
    // technical
    6: ["duration", "daily", "slideOutResourceType", "slideOutMovementType"],
};

const ganttTaskAccordionByType: { [key: number]: Type_accordionList[] } = {
    // work
    1: [
        "links",
        "date",
        "waitingDays",
        "ratio",
        "slideOut",
        "assignees",
        "resources",
        "materials",
        "advanced",
        "progression",
        "observations",
    ],
    // study
    2: [
        "links",
        "date",
        "waitingDays",
        "assignees",
        "advanced",
        "progression",
        "observations",
    ],
    // waiting
    3: ["links", "date", "advanced", "progression", "people"],
    // milestone
    4: ["links", "date", "advanced", "observations"],
    // delivery
    5: ["links", "date", "advanced", "observations"],
    // technical
    6: ["links", "date", "waitingDays", "slideOut", "observations"],
};

export enum Enum_ShouldDisplayConfigs {
    ganttTaskAccordion = "ganttTaskAccordion",
    ganttTaskFields = "ganttTaskFields",
    taskAccordion = "taskAccordion",
    taskFields = "taskFields",
}

const configs = {
    taskAccordion: accordionByTaskType,
    ganttTaskFields: ganttTaskFieldsByType,
    ganttTaskAccordion: ganttTaskAccordionByType,
    taskFields: taskFieldsByType,
};

type Type_Props_shouldDisplay = {
    name: Type_fieldsList | Type_accordionList | any; // TODO need to be fixed
    taskType: number | null;
    config?: keyof typeof Enum_ShouldDisplayConfigs;
};

export const shouldDisplay = ({
    name,
    taskType,
    config = "taskFields",
}: Type_Props_shouldDisplay) => {
    if (!taskType) return false;

    const currentConfig = configs[config] || configs.taskFields;

    return currentConfig[taskType]?.includes(name) || false;
};
