import {
    orientation,
    Type_post_print,
    Type_printSettings,
    Type_prt_post_print,
} from "src/api/tms-print/print/types";
import { Type_SelectOptionItem } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const formatterDownLoadPrint = (
    settings: Type_post_print,
): Type_prt_post_print => {
    return {
        size: settings.paperFormat,
        layout: settings.orientation,
        timelineType: settings.timelineType,
        withDaysOff: settings.withDaysOff,
        withWeekend: settings.withWeekend,
        displayCaption: settings.displayCaption,
        captionPosition: settings.captionPosition,
        displayCaptionKeydates: settings.displayCaptionKeydates,
        callDate: settings.callDate,
    };
};

export const formatterOrientationToRadioItem = (
    data: orientation[],
): Type_SelectOptionItem[] => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Form.Field.PrintLayout",
    );
    return data.map((item) => {
        return {
            value: item,
            label: fmt(item),
        };
    });
};

export const getFilenameFromContentDisposition = (
    contentDisposition: string,
) => {
    const filenameRegex =
        /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"]*)['"]?;?/i;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches && matches[1]) {
        return decodeURIComponent(matches[1]);
    }
    return null;
};

export const formatterPostPrintToPrintSettings = (
    data: Type_post_print,
): Type_printSettings => {
    return {
        paperFormat: data.paperFormat,
        orientation: data.orientation,
        timelineType: data.timelineType,
        displayCaption: data.displayCaption,
        captionPosition: data.captionPosition,
        displayCaptionKeydates: data.displayCaptionKeydates,
    };
};
