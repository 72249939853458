import {
    Type_index_sequence,
    Type_post_dup_sequence,
    Type_post_sequence,
    Type_put_sequence,
    Type_sch_index_sequence,
    Type_sch_post_dup_sequence,
    Type_sch_post_sequence,
    Type_sch_put_sequence,
    Type_sch_show_sequence,
    Type_sch_show_sequence_forFlow,
    Type_sch_show_sequence_forFlow_link,
    Type_sch_show_sequence_forFlow_task,
    Type_show_sequence,
    Type_show_sequence_forFlow,
    Type_show_sequence_forFlow_link,
    Type_show_sequence_forFlow_task,
} from "src/api/tms-scheduling/sequences/types";
import { DEFAULT_COLORS } from "src/theme/stylesheet";
import { formatDateForBack, formatDateForFront, toDate } from "src/utils/date";

export const formatterIndexSequences = (
    sequences: Type_sch_index_sequence[],
): Type_index_sequence[] => {
    return sequences.map((sequence: Type_sch_index_sequence) => {
        return formatterIndexSequence(sequence);
    });
};

export const formatterIndexSequence = (
    sequence: Type_sch_index_sequence,
): Type_index_sequence => {
    return {
        id: sequence.id,
        number: sequence.number,
        code: sequence.code,
        name: sequence.name,
        color: sequence.color ?? DEFAULT_COLORS.sequence,
        earliestDate: toDate(sequence.earliestDate),
        sequenceFolder: sequence.sequenceFolder,
        sequenceFolderId: sequence.sequenceFolder.id,
        optimizeByArea: sequence.optimizeByArea,
        enabled: sequence.enabled,
    };
};

export const formatterShowSequence = (
    sequence: Type_sch_show_sequence,
): Type_show_sequence => {
    return {
        id: sequence.id,
        number: sequence.number,
        code: sequence.code ? sequence.code : "",
        names: sequence.names,
        color: sequence.color ?? DEFAULT_COLORS.sequence,
        earliestDate: formatDateForFront(sequence.earliestDate),
        sequenceFolder: sequence.sequenceFolder,
        subProject: sequence.subProject,
        version: sequence.version,
        enabled: sequence.enabled,
        fixedSize: sequence.fixedSize,
        optimizeByArea: sequence.optimizeByArea,
        quantity: sequence.quantity,
        time: sequence.time,
        numberOfTeams: sequence.numberOfTeams,
        workforce: sequence.workforce,
        linkType: sequence.linkType,
        optimizedFlow: sequence.optimizedFlow,
        timeModel: sequence.timeModel,
        areas: sequence.areas,
        notesCount: sequence.notesCount,
    };
};

export const formatterCreateSequence = (
    sequence: Type_post_sequence,
): Type_sch_post_sequence => {
    return {
        names: sequence.names,
        code: sequence.code,
        color: sequence.color,
        earliestDate: formatDateForBack(sequence.earliestDate),
        sequenceFolder_id: sequence.sequenceFolderId,
        optimizeByArea: sequence.optimizeByArea,
        fixedSize: sequence.fixedSize,
        xy: sequence.xy,
    };
};

export const formatterCreateDefaultSequence = (sequence: {
    names: { [p: string]: string };
    sequenceFolder: { id: number };
}): {
    names: { [p: string]: string };
    sequenceFolder_id: number;
} => {
    return {
        names: sequence.names,
        sequenceFolder_id: sequence.sequenceFolder?.id,
    };
};

export const formatterFormSequence = (
    sequence: Type_show_sequence,
): Type_put_sequence => {
    return {
        id: sequence.id,
        names: sequence.names,
        code: sequence.code,
        color: sequence.color ?? DEFAULT_COLORS.sequence,
        earliestDate: sequence.earliestDate,
        sequenceFolderId: sequence.sequenceFolder.id,
        proportionalSize: !sequence.fixedSize, // on inverse la valeur car la design demande d'activer la taille proportionnelle quand le switch est true et non la taille fixe
        optimizeByArea: sequence.optimizeByArea,
    };
};

export const formatterDuplicateSequence = (
    sequence: Type_post_dup_sequence,
): Type_sch_post_dup_sequence => {
    return {
        names: sequence.names,
        destinationVersionId: sequence.version!,
        //use default values:
        withLinksSequences: false,
        withNotes: false,
        withLinksTaskAreas: false,
    };
};

export const formatterUpdateSequence = (
    sequence: Type_put_sequence,
): Type_sch_put_sequence => {
    return {
        id: sequence.id,
        names: sequence.names,
        code: sequence.code === null ? undefined : sequence.code,
        color: sequence.color ?? DEFAULT_COLORS.sequence,
        earliestDate: formatDateForBack(sequence.earliestDate),
        sequenceFolder_id: sequence.sequenceFolderId as number,
        fixedSize: !sequence.proportionalSize, // on inverse une deuxième fois la valeur pour envoyer la bonne au back
        optimizeByArea: sequence.optimizeByArea,
        enabled: sequence.enabled,
        // quantity: sequence.quantity,
        // time: sequence.time,
        // numberOfTeams: sequence.numberOfTeams,
        // workforce: sequence.workforce,
        // linkType: sequence.linkType,
        // optimizedFlow: sequence.optimizedFlow,
        // timeModel_id: sequence.timeModel?.id,
        // areas: sequence.areas,
        areas: [{ areaId: 1, order: 1 }],
    };
};

export const formatterShowSequenceForFlow = (
    data: Type_sch_show_sequence_forFlow,
): Type_show_sequence_forFlow => ({
    id: data.id,
    code: data.code,
    name: data.name,
    names: data.names,
    color: data.color,
    sequenceFolder: data.sequenceFolder,
    fixedSize: data.fixedSize,
    notesCount: data.notesCount,
    enabled: data.enabled,
    tasks: data.tasks.map((task) => formatterShowSequenceForFlowTask(task)),
    links: data.links.map((link) => formatterShowSequenceForFlowLink(link)),
});

export const formatterShowSequenceForFlowTask = (
    data: Type_sch_show_sequence_forFlow_task,
): Type_show_sequence_forFlow_task => ({
    id: data.id,
    type: data.type,
    code: data.code,
    name: data.name,
    color: data.realColor,
    duration: data.duration,
    areaQuantity: data.areaQuantity,
    team: data.team,
    pt: {
        x: data?.xy?.[0] ?? 0,
        y: data?.xy?.[1] ?? 0,
    },
});

export const formatterShowSequenceForFlowLink = (
    data: Type_sch_show_sequence_forFlow_link,
): Type_show_sequence_forFlow_link => ({
    id: data.id,
    type: data.linkType,
    waitingDay: data.waitingDay,
    quantity: data.quantity,
    taskFrom: data.taskFrom_id,
    taskTo: data.taskTo_id,
});
