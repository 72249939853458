import {
    DialogFullScreen,
    Type_props_DialogFullScreen,
} from "src/components/Components_Common/Dialog/DialogFullScreen";
import { MatrixTrades } from "src/components/Components_Scheduling/Matrix/MatrixTrades/MatrixTrades";

type Type_Props_MatrixTradesFullScreen = Type_props_DialogFullScreen & {
    page: string;
};

export const MatrixTradesFullScreen = ({
    page,
    openDialog,
    setToggleDialog,
    title,
}: Type_Props_MatrixTradesFullScreen) => {
    return (
        <DialogFullScreen
            openDialog={openDialog}
            setToggleDialog={setToggleDialog}
            title={title}
        >
            <MatrixTrades page={page} onClose={() => setToggleDialog(false)} />
        </DialogFullScreen>
    );
};
