import React from "react";
import { Group, Line } from "react-konva";

import {
    sizePlanningTaskSummaryTip,
    usePlanningTheme,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import { Type_Props_PlanningShapeTaskSummary } from "src/components/Components_Teamoty/Planning/Shape/PlanningShape.type";

export const PlanningShapeTaskSummary = ({
    line,
    widthDate,
    heightArea,
}: Type_Props_PlanningShapeTaskSummary) => {
    const theme = usePlanningTheme();

    const verticalOffset: number = heightArea / 2;

    const x: number = Math.round(line.area.beginDate?.pos ?? 0);
    const y: number = Math.round(line.pos + verticalOffset);

    let width: number | undefined =
        line.area.beginDate &&
        line.area.endDate &&
        line.area.endDate.pos - line.area.beginDate.pos + widthDate;

    const tipSize: number = Math.round(heightArea / sizePlanningTaskSummaryTip);

    if (width) {
        width = Math.round(width);
    }

    return (
        <Group x={x} y={y}>
            {width && (
                <Line
                    points={[
                        tipSize,
                        0,
                        0,
                        tipSize,
                        0,
                        -1,
                        width,
                        -1,
                        width,
                        tipSize,
                        width - tipSize,
                        0,
                    ]}
                    fill={line.area.color || theme.colorPlanningTaskSummary}
                    closed={true}
                />
            )}
        </Group>
    );
};
