import { Box, InputAdornment, Stack, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";

import { Type_index_sequenceFolderTree_sequence } from "src/api/tms-scheduling/sequenceFolders/types";
import {
    mutationDeleteSequence,
    mutationStatusSequence,
} from "src/api/tms-scheduling/sequences";
import { Type_sch_put_sequence } from "src/api/tms-scheduling/sequences/types";
import { TMC_Button, TMC_TextField } from "src/components";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import {
    Dialog,
    Type_DialogConfig,
    Type_DialogParams,
} from "src/components/Components_Common/Dialog/Dialog";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { Spinner } from "src/components/Components_Common/Spinner/Spinner";
import {
    MatrixSequenceFullScreen,
    Type_sequenceForMatrixFullScreen,
} from "src/components/Components_Scheduling/Matrix/MatrixSequence/MatrixSequenceFullScreen";
import {
    MatrixSequencesFullScreen,
    Type_sequencesForMatrixFullScreen,
} from "src/components/Components_Scheduling/Matrix/MatrixSequences/MatrixSequencesFullScreen";
import { HeaderToolbar } from "src/components/Components_Teamoty/contextualDrawers/HeaderToolbar/HeaderToolbar";
import { usePlanningContext } from "src/contexts/planning";
import { SequencesTreeView } from "src/drawers/sequences/SequencesContextualDrawer/SequencesTreeView";
import { SequenceDuplicationForm } from "src/forms/sequence/SequenceDuplicationForm";
import { SequenceFolderForm } from "src/forms/sequenceFolder/SequenceFolderForm";
import {
    Type_event_dialog,
    Type_event_sequenceTree,
    useChannel,
} from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Content,
    Header,
    Styled_PaperContextualLayout,
    useContextualDrawer,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { COLORS } from "src/theme/stylesheet";

type Type_modalDeleteSequence = {
    isOpen: boolean;
    props?: Type_index_sequenceFolderTree_sequence;
};

type Type_matrixSequenceFullScreen = {
    openDialog: boolean;
    setToggleDialog?: React.Dispatch<React.SetStateAction<boolean>>;
    sequence?: Type_sequenceForMatrixFullScreen | null;
};

type Type_matrixSequencesFullScreen = {
    openDialog: boolean;
    setToggleDialog?: React.Dispatch<React.SetStateAction<boolean>>;
    sequences?: Type_sequencesForMatrixFullScreen | null;
};

export type Type_unChecked = {
    folders: number[];
    sequences: number[];
    tasks: number[];
};

export const SequencesContextualDrawer = () => {
    const { sendEvent } = useChannel({});
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.SequenceFolders",
    );
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");

    const { setDataFilters, dataFilters } = usePlanningContext();
    const { closePaper } = useContextualDrawer();

    const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

    const [dialogParams, setDialogParams] = useState<Type_DialogParams>({
        open: false,
        action: "create",
        data: { name: "" },
    });

    const [modalDeleteSequence, setModalDeleteSequence] =
        useState<Type_modalDeleteSequence>({
            isOpen: false,
        });

    const [fullScreenMatrixSequence, setFullScreenMatrixSequence] =
        useState<Type_matrixSequenceFullScreen>({
            openDialog: false,
            setToggleDialog: () => {},
            sequence: null,
        });

    const [fullScreenMatrixSequences, setFullScreenMatrixSequences] =
        useState<Type_matrixSequencesFullScreen>({
            openDialog: false,
            setToggleDialog: () => {},
        });

    const handleOpenMatrixSequences = () => {
        setFullScreenMatrixSequences({
            ...fullScreenMatrixSequences,
            openDialog: true,
        });
    };

    const { mutateAsync: mutateDeleteSequence } = mutationDeleteSequence(
        modalDeleteSequence?.props?.id as number,
    );

    const { mutate: mutateStatusSequence } = mutationStatusSequence();

    const handleClickOpen = (): void => {
        setDialogParams((prevParams) => ({
            ...prevParams,
            action: "create",
            open: true,
            data: { name: "createOrUpdateSequenceFolder" },
        }));
    };

    const handleCloseDialog = (): void => {
        setDialogParams((prev) => ({ ...prev, open: false, data: {} }));
    };

    useChannel({
        eventHandler: ({ event, data }): void => {
            if (event === "openDialogFolder") {
                setDialogParams((prevParams) => ({
                    ...prevParams,
                    ...(data as Type_event_dialog),
                }));
            }
            if (event === "openModalDeleteSequence") {
                setModalDeleteSequence({
                    isOpen: true,
                    props: (data as Type_event_sequenceTree).item,
                });
            }
            if (event === "changeStatusSequence") {
                const formattedData = data as Type_event_sequenceTree;

                mutateStatusSequence({
                    id: formattedData.item.id,
                    enabled: !formattedData.item.enabled,
                } as Type_sch_put_sequence);
            }
            if (event === "openModalMatrixSequence") {
                const formattedData = data as Type_event_sequenceTree;

                setFullScreenMatrixSequence({
                    openDialog: true,
                    sequence: {
                        id: formattedData.item.id,
                        label: formattedData.item.label,
                    },
                });
            }
        },
    });

    const removeSequence = async (id: number) => {
        await mutateDeleteSequence(id);
    };

    const actions = {
        onSubmit: {
            onClick: () => {
                removeSequence(modalDeleteSequence?.props?.id as number).then(
                    () => setModalDeleteSequence({ isOpen: false }),
                );
            },
        },
    };

    const [unChecked, setUnChecked] = useState<Type_unChecked>({
        folders: dataFilters.notSelectedFolders,
        sequences: dataFilters.notSelectedSequences,
        tasks: dataFilters.notSelectedTasks,
    });

    const showApplyButton = useMemo(() => {
        return (
            unChecked.folders.length !==
                dataFilters.notSelectedFolders.length ||
            unChecked.sequences.length !==
                dataFilters.notSelectedSequences.length ||
            unChecked.tasks.length !== dataFilters.notSelectedTasks.length
        );
    }, [unChecked, dataFilters]);

    const handleFilters = () => {
        setDataFilters((prev) => {
            return {
                ...prev,
                notSelectedFolders: unChecked.folders,
                notSelectedSequences: unChecked.sequences,
                notSelectedTasks: unChecked.tasks,
            };
        });
        sendEvent("updatePlanningFilters");
    };

    const dialogConfig: Type_DialogConfig = {
        createOrUpdateSequenceFolder: {
            props: {
                open: dialogParams.open,
                onClose: handleCloseDialog,
                dialogTitle:
                    dialogParams.action === "create"
                        ? fmt("Form.TitleCreate")
                        : fmt("Form.TitleUpdate"),
                name: "createOrUpdateSequenceFolder",
                isLoading: isFormLoading,
                children: (
                    <SequenceFolderForm
                        dialogParams={dialogParams}
                        setDialogParams={setDialogParams}
                        setIsFormLoading={setIsFormLoading}
                    />
                ),
            },
        },
        duplicateSequence: {
            props: {
                open: dialogParams.open,
                onClose: handleCloseDialog,
                dialogTitle: `${fmt("Sequence.Duplicating")} "${dialogParams?.data?.label}"`,
                name: "duplicateSequence",
                isLoading: isFormLoading,
                children: (
                    <SequenceDuplicationForm
                        setIsFormLoading={setIsFormLoading}
                        dialogParams={dialogParams}
                        setDialogParams={setDialogParams}
                    />
                ),
                actions: (
                    <TMC_Button
                        form={dialogParams?.data?.name}
                        type="submit"
                        size="medium"
                        variant="contained"
                        disabled={isFormLoading}
                        startIcon={
                            isFormLoading ? (
                                <Spinner />
                            ) : (
                                <Icon variant="solid" icon="copy" />
                            )
                        }
                        data-testid={`Dialog-${dialogParams?.data?.name}-btn`}
                    >
                        {fmt("Sequence.More.Duplicate")}
                    </TMC_Button>
                ),
            },
        },
    };

    return (
        <>
            <Styled_PaperContextualLayout>
                <Header
                    data-testid="Sequences-Header"
                    ownerState={{ borderBottom: false }}
                >
                    <HeaderToolbar
                        dataTestIdRef="Sequences-Header"
                        onClose={() => closePaper("sequences")}
                    />
                </Header>
                <Content data-testid="Sequences-Content">
                    <Stack
                        justifyContent="space-between"
                        alignItems="center"
                        direction={"row"}
                        paddingX={4}
                        paddingY={1}
                    >
                        <Typography variant={"h3"}>{fmt("Title")}</Typography>
                        <Stack direction={"row"} spacing={1}>
                            <IconButton
                                onClick={() => handleOpenMatrixSequences()}
                                size="small"
                                title={fmt("OpenMatrix")}
                                data-testid="Sequences-Open-Matrix-btn"
                            >
                                <Icon
                                    variant="solid"
                                    icon="table"
                                    fontSize="medium"
                                />
                            </IconButton>
                            <IconButton
                                onClick={handleClickOpen}
                                size="small"
                                title={fmt("CreateFolder")}
                                data-testid="Sequence-Create-SequenceFolder-btn"
                            >
                                <Icon
                                    variant="solid"
                                    icon="folder-plus"
                                    fontSize="medium"
                                />
                            </IconButton>
                        </Stack>
                    </Stack>
                    <Box paddingX={4} paddingY={2}>
                        <TMC_TextField
                            data-testid="Sequences-SearchBar"
                            name="global-search"
                            placeholder="Search..."
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon
                                            variant="solid"
                                            icon="search"
                                            sx={{ color: COLORS.moon500 }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <SequencesTreeView
                        setUnChecked={setUnChecked}
                        unChecked={unChecked}
                    />
                </Content>
                {showApplyButton && (
                    <Box
                        sx={{
                            textAlign: "right",
                            p: 2,
                        }}
                    >
                        <TMC_Button
                            data-testid="Form-SequenceFilters-Submit-Btn"
                            color="primary"
                            onClick={handleFilters}
                        >
                            {fmtActions("Apply")}
                        </TMC_Button>
                    </Box>
                )}
            </Styled_PaperContextualLayout>
            <Dialog {...dialogConfig[dialogParams?.data?.name]?.props} />
            <ModalDelete
                open={modalDeleteSequence.isOpen}
                onClose={() => setModalDeleteSequence({ isOpen: false })}
                item={fmt("Sequence.DeleteDialog.Sequence")}
                validationString={modalDeleteSequence?.props?.label as string}
                actions={actions}
            />
            <MatrixSequenceFullScreen
                openDialog={fullScreenMatrixSequence.openDialog}
                setToggleDialog={() =>
                    setFullScreenMatrixSequence({
                        openDialog: false,
                        sequence: null,
                    })
                }
                sequence={fullScreenMatrixSequence.sequence}
            />
            <MatrixSequencesFullScreen
                openDialog={fullScreenMatrixSequences.openDialog}
                setToggleDialog={() =>
                    setFullScreenMatrixSequences({
                        openDialog: false,
                    })
                }
            />
        </>
    );
};
