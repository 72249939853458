import { Type_index_taskAreaProductType } from "src/api/tms-scheduling/taskAreaProductTypes/types";

import {
    Type_index_productTypeTasks,
    Type_sch_index_productTypeTasks,
} from "./types";

export const formatterIndexProductTypeTask = (
    products: Type_sch_index_productTypeTasks[],
): Type_index_productTypeTasks[] => {
    const productTypeTasks = products.map((product) => {
        return {
            id: product.id,
            productType: product.productType,
            quantity: product.quantity,
            task_id: product.task_id,
        };
    });

    return sortProductByName(productTypeTasks);
};

export const formatterPostProductTypeTaskToIndex = (
    product: Type_sch_index_productTypeTasks,
): Type_index_productTypeTasks => {
    return {
        id: product.id,
        productType: product.productType,
        quantity: product.quantity,
        task_id: product.task_id,
    };
};

export const sortProductByName = (
    resourceTypesMap:
        | Type_index_productTypeTasks[]
        | Type_index_taskAreaProductType[],
) => {
    return Object.values(resourceTypesMap).sort((a, b) =>
        a.productType.name.localeCompare(b.productType.name),
    );
};
