import { Type_RequestConfig } from "src/api/fetch";
import { PeopleTaskKeys } from "src/api/tms-scheduling/keys";
import {
    mutationCreatePeopleTask,
    mutationDeletePeopleTask,
    useIndexPeopleTask,
} from "src/api/tms-scheduling/peopleTask";
import { PeopleTaskForm } from "src/forms/peopleTasks/PeopleTaskForm";
import { Styled_PaperContextualLayout } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

type Type_Props_PeopleTaskContextualDrawer = {
    onClose: () => void;
    taskId: number;
    focus: boolean;
};

export const PeopleTaskContextualDrawer = ({
    onClose,
    taskId,
    focus = false,
}: Type_Props_PeopleTaskContextualDrawer) => {
    const { isLoading, data: peopleTask } = useIndexPeopleTask({
        taskId: taskId,
    });

    const { mutateAsync: createTaskPerson, isLoading: isCreatingTaskPerson } =
        mutationCreatePeopleTask({ taskId: taskId });

    const { mutateAsync: removePeopleTask } = mutationDeletePeopleTask({
        taskId: taskId,
    });

    const invalidateIndex = [
        PeopleTaskKeys.INDEX,
        { taskId: taskId } as Type_RequestConfig,
    ];

    return (
        <Styled_PaperContextualLayout id="peopleTask">
            <PeopleTaskForm
                onClose={onClose}
                focus={focus}
                peopleTask={peopleTask}
                isLoadingPeopleTask={isLoading}
                createTaskPerson={createTaskPerson}
                isCreatingTaskPerson={isCreatingTaskPerson}
                removeTaskPerson={removePeopleTask}
                invalidateQueryAfterCreate={invalidateIndex}
            />
        </Styled_PaperContextualLayout>
    );
};
