import { PeriodToggleEnum } from "@cimba-digital-construction-solution/utils/dist/filters/planningFilters";
import { Divider, Typography } from "@mui/material";
import * as React from "react";
import { useMemo } from "react";

import {
    DropdownMenu,
    TMC_ToggleButton,
} from "src/components/Components_Common";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Tooltip } from "src/components/Components_Common/_MuiComponentsVariants/Tooltip/Tooltip.component";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { ToolbarSection } from "src/components/Components_Teamoty/views/ViewPlanning/PlanningToolbar";
import { usePlanningContext } from "src/contexts/planning";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

type Type_Props_LeftToolbar = {
    handleAddTaskClick: () => void;
};

export const LeftToolbar = ({ handleAddTaskClick }: Type_Props_LeftToolbar) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.Toolbar",
    );
    const { togglePaper } = useContextualDrawer();
    const { toggleTaskDoneStatus, enableTaskDone, dataFilters } =
        usePlanningContext();

    ///////////////////////////////////
    /// Actions                     ///
    ///////////////////////////////////

    const groupByMenuItems = [
        {
            id: "sequences",
            icon: {
                name: "light-tds-sequence",
                variant: "kit",
                fontSize: "small",
            },
            label: fmt("Sequences"),

            call: () => console.log("Sequences clicked"),
        },
        {
            id: "trades",
            icon: {
                name: "helmet-safety",
                variant: "light",
                fontSize: "small",
            },

            label: fmt("Trades"),
            call: () => console.log("Trades clicked"),
        },
        {
            id: "companies",
            icon: {
                name: "building",
                variant: "light",
                fontSize: "small",
            },
            label: fmt("Companies"),
            call: () => console.log("Companies clicked"),
        },
    ];

    const handleSequencesClick = () => {
        togglePaper("sequences", {}, true);
    };

    const handleFiltersClick = () => {
        togglePaper("planningFilters", {}, true);
    };

    const activeFilterCount = useMemo(() => {
        return [
            Number(dataFilters.period.type !== PeriodToggleEnum.FULL),
            dataFilters.notSelectedTrades.length,
            dataFilters.notSelectedCompanies.length,
            dataFilters.typesTasks.length,
            dataFilters.status.length,
        ].reduce((count, isActive) => count + Number(isActive > 0 ? 1 : 0), 0);
    }, [dataFilters]);

    const hasActiveFilters = !!activeFilterCount;

    return (
        <ToolbarSection>
            <DropdownMenu
                iconButton={{
                    sx: { color: "inherit" },
                }}
                showHeader
                id="group-by"
                label={fmt("GroupBy")}
                icon={
                    <Icon
                        color={"inherit"}
                        icon="rectangle-list"
                        variant="light"
                    />
                }
                menuItems={groupByMenuItems}
            />

            <TMC_ToggleButton
                data-testid={`planning-toolbar-filter-btn`}
                aria-label="filter-btn"
                value="filter"
                selected={hasActiveFilters}
                onClick={handleFiltersClick}
            >
                <Icon
                    sx={{ mr: 1 }}
                    fontSize="small"
                    icon="bars-filter"
                    variant="light"
                />
                <Typography variant="body2">
                    {hasActiveFilters && `(${activeFilterCount})`}{" "}
                    {fmt("Filters")}
                </Typography>
            </TMC_ToggleButton>

            <IconButton
                color={"inherit"}
                aria-label="sequence-btn"
                data-testid={`planning-toolbar-sequence-btn`}
                onClick={handleSequencesClick}
            >
                <Icon sx={{ mr: 1 }} icon="light-tds-sequence" variant="kit" />
                <Typography variant="body2">{fmt("Sequences")}</Typography>
            </IconButton>

            <Divider orientation="vertical" variant="middle" flexItem />

            <IconButton
                color={"inherit"}
                aria-label="add-btn"
                data-testid={`planning-toolbar-add-task-btn`}
                onClick={handleAddTaskClick}
            >
                <Icon icon="add" variant="light" />
            </IconButton>

            <Tooltip
                title={
                    enableTaskDone
                        ? fmt("UndoMarkAsCompleted")
                        : fmt("MarkAsCompleted")
                }
            >
                <TMC_ToggleButton
                    data-testid={`planning-toolbar-mark-as-completed-btn`}
                    value="check"
                    selected={enableTaskDone}
                    onChange={toggleTaskDoneStatus}
                >
                    <Icon icon="circle-check" variant="light" />
                </TMC_ToggleButton>
            </Tooltip>
        </ToolbarSection>
    );
};
