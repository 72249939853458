import React from "react";
import { Rect, Text } from "react-konva";

import {
    fontSizeFactorPlanningTask,
    fontSizeMaxPlanningTask,
    fontSizeMinPlanningTask,
    marginWithPlanningTaskCircle,
    paddingPlanningTask,
    strokeOffsetPlanningTask,
    strokeWidthPlanningTask,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import {
    color_shape_planning,
    components_shape_planning,
    Type_props_component_shape_planning,
} from "src/components/Components_Teamoty/Planning/Shape/PlanningShape.const";
import { Conv_typePlanningTask } from "src/components/Components_Teamoty/Planning/Shape/PlanningShape.enum";
import { Type_Props_PlanningShapeTaskBase } from "src/components/Components_Teamoty/Planning/Shape/PlanningShape.type";
import { PlanningShapeGroup } from "src/components/Components_Teamoty/Planning/Shape/PlanningShapeGroup";
import { PlanningShapeTask } from "src/components/Components_Teamoty/Planning/Shape/PlanningShapeTask";
import { getHeight } from "src/components/Components_Teamoty/Planning/tools/getHeight";
import { getWidth } from "src/components/Components_Teamoty/Planning/tools/getWidth";
import { separatorCodeTask } from "src/configurations/app";

export const PlanningShapeTaskLight = ({
    task,
    line,

    widthDate,
    heightArea,

    selected,
    setSelected,

    getBeginDate,
    getEndDate,
}: Type_Props_PlanningShapeTaskBase) => {
    // Détermine le type de forme pour la tâche donnée
    const typeShape: Type_props_component_shape_planning =
        Conv_typePlanningTask[task.type] as Type_props_component_shape_planning;

    // Récupération de forme et de couleur associées au type de forme
    const shapePlanningFunction = components_shape_planning[typeShape];
    const colorShapePlanningFunction = color_shape_planning[typeShape];

    // Calcule des dimensions et des positions de la tâche
    const height: number = getHeight({ task, heightArea });
    const width: number = getWidth({ task, widthDate });

    // Générer le texte de la tâche
    const taskText: string = task.code
        ? `${task.code}${separatorCodeTask}${task.name}`
        : task.name;

    // Calculer la taille de police appropriée
    const fontSize: number = Math.min(
        heightArea / fontSizeFactorPlanningTask,
        fontSizeMaxPlanningTask,
    );

    // Calcule de la couleur à appliquer
    const colors = colorShapePlanningFunction(task.color, task.isDone);

    return (
        <PlanningShapeGroup
            width={width}
            height={height}
            task={task}
            line={line}
            widthDate={widthDate}
            heightArea={heightArea}
            selected={selected}
            setSelected={setSelected}
            getBeginDate={getBeginDate}
            getEndDate={getEndDate}
        >
            <Rect
                x={strokeOffsetPlanningTask}
                y={strokeOffsetPlanningTask}
                width={width - strokeOffsetPlanningTask * 2}
                height={height - strokeOffsetPlanningTask * 2}
                fill={colors.fill}
                stroke={colors.fill}
                strokeWidth={strokeWidthPlanningTask}
            />

            <PlanningShapeTask
                shapePlanningFunction={shapePlanningFunction}
                width={width}
                height={height}
                fill={colors.stroke}
                stroke={colors.stroke}
                strokeWidth={strokeWidthPlanningTask}
            />

            {fontSize >= fontSizeMinPlanningTask && (
                <Text
                    x={selected ? marginWithPlanningTaskCircle : 2}
                    y={1}
                    width={
                        width -
                        (selected ? marginWithPlanningTaskCircle * 2 : 4)
                    }
                    height={height - 3}
                    padding={paddingPlanningTask}
                    fill={colors.textColor}
                    text={taskText}
                    fontSize={fontSize}
                />
            )}
        </PlanningShapeGroup>
    );
};
