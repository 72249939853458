import {
    GridCellParams,
    GridColDef,
    GridRenderCellParams,
    GridValidRowModel,
    GridValueOptionsParams,
} from "@mui/x-data-grid-premium";
import { GridRowModel } from "@mui/x-data-grid-pro";
import React, { useMemo } from "react";

import { useSelectListTimeModels } from "src/api/tms-commons/timeModels";
import { useIndexProjectCompanies } from "src/api/tms-projects/projectCompanies";
import {
    Type_index_projectCompany,
    Type_index_projectCompanyWithMeta,
} from "src/api/tms-projects/projectCompanies/types";
import { Type_selectList_subTrade } from "src/api/tms-projects/subTrades/types";
import { useIndexTrades } from "src/api/tms-projects/trades";
import { Type_index_trade } from "src/api/tms-projects/trades/types";
import { useSelectListAreaWorkLevels } from "src/api/tms-scheduling/areas";
import {
    mutationUpdateTaskForMatrix,
    useIndexTasksForMatrix,
} from "src/api/tms-scheduling/tasks";
import { LoadingBox } from "src/components";
import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";
import { Type_SelectOptionItem } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { colorPickerColumn } from "src/components/Components_Common/matrix/columnDefinitions/colorPickerColumn";
import { multiLanguesColumns } from "src/components/Components_Common/matrix/columnDefinitions/multiLanguesColumn";
import { selectColumn } from "src/components/Components_Common/matrix/columnDefinitions/selectColumn";
import {
    getDifferentAttributes,
    Matrix,
} from "src/components/Components_Common/matrix/Matrix";
import { SELECT_NON_OPTION, SELECT_NONE_VALUE } from "src/configurations/app";
import { useProject } from "src/contexts/project";
import { useUser } from "src/contexts/user";
import { shouldDisplay } from "src/forms/tasks/utils";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_selectListSubTrades = {
    id: number;
    name: string;
    optionGroupLabel?: React.ReactNode;
};

const formatSelectListSubTrades = (
    data: Type_index_trade[],
): Type_selectListSubTrades[] => {
    if (!data) return [];
    let formattedOptions: Type_selectListSubTrades[] = [];

    data.forEach((trade) => {
        trade.subTrades.forEach((subTrade, index) => {
            formattedOptions = [
                ...formattedOptions,
                {
                    name: subTrade.name,
                    id: subTrade.id,
                    optionGroupLabel:
                        index === 0 ? (
                            <TMC_Chip
                                key={trade.id}
                                label={`${trade.code.toUpperCase()} - ${trade.name}`}
                                backgroundColor={trade.color}
                                withBorder={true}
                            />
                        ) : undefined, // Ajouter le titre du groupe uniquement pour le premier sous-trade
                },
            ];
        });
    });
    return formattedOptions;
};

export const formatSelectListCompanies = (
    companies: Type_index_projectCompanyWithMeta | undefined, // TODO : changer typage index forMatrix demandé au back
    subTradeId?: number,
): Type_SelectOptionItem[] => {
    if (!companies) return [];
    if (!subTradeId) return [];

    const formattedOptions = companies.companies
        .filter(
            (item: Type_index_projectCompany) =>
                item.subTrades
                    .map((item: Type_selectList_subTrade) => item.id)
                    .includes(subTradeId), // TODO : simplifier selon le nouveau typage index forMatrix demandé au back
        )
        .map((item: Type_index_projectCompany) => ({
            label: item.name,
            value: item.id,
        }));
    formattedOptions.unshift(SELECT_NON_OPTION);

    return formattedOptions;
};

export const getTradeColor = (tradeId: number, trades: Type_index_trade[]) => {
    return trades
        .filter((trade: Type_index_trade) => trade.id === tradeId)
        .map((trade: Type_index_trade) => trade.color);
};

type Type_Props_MatrixSequence = {
    sequenceId?: number;
    onClose: () => void;
};

export const MatrixSequence = ({
    sequenceId,
    onClose,
}: Type_Props_MatrixSequence) => {
    // Permissions
    const page = "tasks";
    const { checkPermission, user } = useUser();
    const hasPermission = useMemo(() => {
        return page
            ? checkPermission(page, "create") || checkPermission(page, "update")
            : true;
    }, [page, user]);

    // Languages
    const { projectLanguages = [] } = useProject();
    const checkLanguages =
        projectLanguages?.map(({ locale }) => `names-${locale}`) ?? [];

    const editableFields = [...checkLanguages, "code", "timeModelId"];

    //i18n
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Lists");

    // Fetch Data
    const {
        data: dataTasks = [],
        isFetching: isFetchingTasks,
        refetch: refetchForMatrix,
    } = useIndexTasksForMatrix({
        sequence_id: sequenceId,
    });
    const { data: trades = [], isFetching: isFetchingTrades } =
        useIndexTrades() || {};
    const { data: companies, isFetching: isFetchingCompanies } =
        useIndexProjectCompanies({
            params: {
                page: 1,
                per_page: 1000,
            },
        }) || {}; // TODO: adding params forMatrix instead of params
    const { data: timeModels = [], isFetching: isFetchingTimeModels } =
        useSelectListTimeModels() || {};
    const { data: workLevels = [], isFetching: isFetchingWorkLevels } =
        useSelectListAreaWorkLevels(sequenceId, true);

    // mutations
    const { mutateAsync: mutateUpdate } =
        mutationUpdateTaskForMatrix(false) || {};

    // refetch
    const refetchValues = async () => {
        return await refetchForMatrix();
    };

    const isFetching = useMemo(
        () =>
            isFetchingTasks ||
            isFetchingTrades ||
            isFetchingTimeModels ||
            isFetchingWorkLevels ||
            isFetchingCompanies,
        [
            isFetchingTasks,
            isFetchingTrades,
            isFetchingTimeModels,
            isFetchingWorkLevels,
            isFetchingCompanies,
        ],
    );

    const getHiddenCellClassName = (params: GridCellParams<any, any, any>) => {
        if (!params.isEditable) {
            // checkboxes have to be hidden by css
            return "Teamoty-dataGrid-cell-hidden";
        }
        return "";
    };

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: "type",
                headerName: fmtTableColumn("Type"),
                flex: 1,
                renderCell: (params: GridRenderCellParams<any, any, any>) => {
                    return fmt(`TaskType.${params.value}`);
                },
            },
            {
                field: "code",
                headerName: fmtTableColumn("Code"),
                flex: 1,
                display: "flex",
                editable: hasPermission,
            },
            ...multiLanguesColumns({
                field: "names",
                headerName: fmtTableColumn("Name"),
                languages: projectLanguages,
                editable: hasPermission,
            }),
            colorPickerColumn({
                field: "color",
                headerName: fmtTableColumn("Color"),
                editable: hasPermission,
                display: "flex",
                width: 30,
                getColor: (params: GridRowModel) => {
                    if (!params.row.withTradeColor) return params.value;
                    return getTradeColor(params.row.tradeId, trades);
                },
            }),
            {
                field: "withTradeColor",
                type: "boolean",
                headerName: fmtTableColumn("WithTradeColor"),
                flex: 1,
                editable: hasPermission,
                cellClassName: getHiddenCellClassName,
            },
            selectColumn({
                field: "subTradeId",
                headerName: fmtTableColumn("SubTrade"),
                editable: hasPermission,
                options: formatSelectListSubTrades(trades!),
                hasNoneValue: true,
                flex: 1,
                cellClassName: getHiddenCellClassName,
            }),
            {
                field: "companyId",
                headerName: fmtTableColumn("Company"),
                type: "singleSelect",
                editable: hasPermission,
                valueOptions: (params: GridValueOptionsParams<any>) => {
                    const subTradeId = params?.row?.subTradeId;
                    if (subTradeId === null || subTradeId === -1) return;

                    return formatSelectListCompanies(companies, subTradeId);
                },
                flex: 1,
                cellClassName: getHiddenCellClassName,
            },
            {
                field: "duration",
                headerName: fmtTableColumn("Duration"),
                editable: hasPermission,
                flex: 1,
                cellClassName: getHiddenCellClassName,
            },
            {
                field: "workforce",
                headerName: fmtTableColumn("Workforce"),
                editable: hasPermission,
                flex: 1,
                cellClassName: getHiddenCellClassName,
            },
            {
                field: "areaQuantity",
                headerName: fmtTableColumn("Quantity"),
                editable: hasPermission,
                flex: 1,
                cellClassName: getHiddenCellClassName,
            },
            selectColumn({
                field: "workLevel",
                headerName: fmtTableColumn("WorkLevel"),
                options: workLevels,
                editable: hasPermission,
                flex: 1,
                display: "flex",
                cellClassName: getHiddenCellClassName,
            }),
            {
                field: "team",
                headerName: fmtTableColumn("Team"),
                editable: hasPermission,
                flex: 1,
                cellClassName: getHiddenCellClassName,
            },
            {
                field: "follow",
                type: "boolean",
                headerName: fmtTableColumn("Follow"),
                editable: hasPermission,
                flex: 1,
                cellClassName: getHiddenCellClassName,
            },
            {
                field: "flow",
                type: "boolean",
                headerName: fmtTableColumn("Flow"),
                editable: hasPermission,
                flex: 1,
                cellClassName: getHiddenCellClassName,
            },
            {
                field: "checking",
                type: "boolean",
                headerName: fmtTableColumn("Checking"),
                editable: hasPermission,
                flex: 1,
                cellClassName: getHiddenCellClassName,
            },
            selectColumn({
                field: "timeModelId",
                headerName: fmtTableColumn("TimeModel"),
                options: timeModels,
                editable: hasPermission,
                flex: 1,
                display: "flex",
            }),
        ],
        [isFetching],
    );

    const editableConditions: Record<
        string,
        (params: GridCellParams<any, any, any>) => boolean
    > = {
        companyId: (params) =>
            params.row.subTradeId &&
            params.row.subTradeId !== SELECT_NONE_VALUE &&
            formatSelectListCompanies(companies, params.row.subTradeId).length >
                1,
    };

    // Check if a field is editable
    const isCellEditable = (params: GridCellParams<any, any, any>) => {
        if (!hasPermission) return false;

        // These fields are always displayed
        if (editableFields.includes(params.field)) return true;

        if (params.field === "color") {
            return !params.row.withTradeColor;
        }

        // These fields are displayed only if the task type is in the list
        if (
            !shouldDisplay({
                name: params.field,
                taskType: params.row.type,
            })
        ) {
            return false;
        }

        // check on conditional fields
        const condition = editableConditions[params.field];

        return condition ? condition(params) : hasPermission;
    };

    //
    // onBlur behavior
    //
    const processRowUpdateAdditionalBehaviors = (
        newRow: GridValidRowModel,
        oldRow: GridValidRowModel,
    ): GridValidRowModel => {
        const diff: GridValidRowModel = getDifferentAttributes(newRow, oldRow);

        if (diff.subTradeId) {
            diff.companyId = SELECT_NONE_VALUE;
        }

        return diff;
    };

    return (
        <>
            {isFetching ? (
                <LoadingBox />
            ) : (
                <Matrix
                    columns={columns}
                    rows={dataTasks}
                    onClose={onClose}
                    mutateUpdate={mutateUpdate}
                    refetch={refetchValues}
                    isCellEditable={isCellEditable}
                    processRowUpdateAdditionalBehaviors={
                        processRowUpdateAdditionalBehaviors
                    }
                />
            )}
        </>
    );
};
