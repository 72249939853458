import * as React from "react";
import { useState } from "react";

import { TMC_Button } from "src/components/Components_Common";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { ToolbarSection } from "src/components/Components_Teamoty/views/ViewPlanning/PlanningToolbar";
import { PrintSettingsDialog } from "src/dialogs/print/PrintSettingsDialog";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

export const RightToolbar = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.Toolbar",
    );

    const { togglePaper } = useContextualDrawer();

    const [openPrintSettings, setOpenPrintSettings] = useState<boolean>(false);

    return (
        <ToolbarSection>
            <IconButton
                color={"inherit"}
                aria-label="settings-btn"
                data-testid={`planning-toolbar-settings-btn`}
                onClick={() => {
                    togglePaper("planningSettings", {}, true);
                }}
            >
                <Icon variant="light" icon="sliders" />
            </IconButton>

            <TMC_Button
                variant="outlined"
                aria-label="export-btn"
                data-testid={`planning-toolbar-export-btn`}
                startIcon={
                    <Icon variant="light" icon="arrow-down-to-bracket" />
                }
                onClick={() => setOpenPrintSettings(true)}
            >
                {fmt("ExportPDF")}
            </TMC_Button>

            {openPrintSettings && (
                <PrintSettingsDialog
                    openDialog={openPrintSettings}
                    setToggleDialog={setOpenPrintSettings}
                />
            )}
        </ToolbarSection>
    );
};
