import { Skeleton } from "@mui/material";
import * as React from "react";
import { memo, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { useSelectListAreaWorkLevels } from "src/api/tms-scheduling/areas";
import {
    formatSelectListToSelectOptionItem,
    Select,
    Type_Props_Select,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";

type Type_Props_SelectBreakLevel = Omit<Type_Props_Select, "options"> & {
    sequenceId?: number;
    withCurrent?: boolean;
    autoSelectFirstItem?: boolean;
};
export const SelectBreakLevel = memo(function SelectBreakLevel({
    name,
    sequenceId,
    withCurrent,
    autoSelectFirstItem = false,
    ...props
}: Type_Props_SelectBreakLevel) {
    const { isLoading, data } = useSelectListAreaWorkLevels(
        sequenceId,
        withCurrent,
    );

    const { setValue } = useFormContext(); // retrieve those props

    useEffect(() => {
        if (autoSelectFirstItem && !isLoading && data && data.length > 0) {
            setValue(name, data[0].id);
        }
    }, [isLoading, data, autoSelectFirstItem]);

    return (
        <>
            {isLoading || !data ? (
                <Skeleton variant="rounded" height={60} />
            ) : (
                <Select
                    name={name}
                    options={formatSelectListToSelectOptionItem(data)}
                    {...props}
                />
            )}
        </>
    );
});
