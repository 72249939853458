import { Type_api_selectList, Type_selectList } from "src/api/types";

export const formatterSelectList = (
    selectList: Type_api_selectList[],
): Type_selectList[] => {
    return selectList.map((selectListItem: Type_api_selectList) => ({
        id: selectListItem.id,
        name: selectListItem.name,
    }));
};

export const LANGUAGE_NAME_DIVIDER: string = "-";

export const formatterMultiLanguageForMatrixToBack = (
    parentKeyName: string,
    value: any,
): { [key: string]: string } => {
    const newValue: { [key: string]: string } = {};

    Object.keys(value).forEach((key: string) => {
        if (key.includes(parentKeyName + LANGUAGE_NAME_DIVIDER))
            newValue[key.split(LANGUAGE_NAME_DIVIDER)[1]] = value[key];
    });

    return newValue;
};

export const formatterMultiLanguageForMatrixToFront = (
    parentKeyName: string,
    value: any,
): { [key: string]: string } => {
    const flattenNames: { [key: string]: string } = {};

    if (value) {
        Object.keys(value).forEach((lang) => {
            flattenNames[`${parentKeyName}${LANGUAGE_NAME_DIVIDER}${lang}`] =
                value[lang];
        });
    }

    return flattenNames;
};
