import { useEffect, useState } from "react";
import {
    generatePath,
    Outlet,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";

import { LoadingBox } from "src/components";
import { useProject } from "src/contexts/project";
import { WorkspaceViewProvider } from "src/contexts/workspaces";
import { URL_TEAMOTY_PROJECT } from "src/router";

export const Layout_Project_Workspace = () => {
    const urlParams = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { isLoading, subProject, workspace } = useProject();

    const [loadingProject, setLoadingProject] = useState(true);

    useEffect(() => {
        if (isLoading) {
            console.debug("Loading project...");
            setLoadingProject(true);
        } else if (subProject !== null) {
            console.debug("Project loaded", subProject, workspace); // TODO to remove
            const baseUrl = `${URL_TEAMOTY_PROJECT}/${subProject.id}/myteamoty/workspace/`;
            let newUrl;

            if (workspace && workspace[0]?.views[0]?.id) {
                // Subproject already have a workspace, so we can redirect to the first view
                newUrl = generatePath(`${baseUrl}${workspace[0].views[0].id}`, {
                    ...urlParams,
                });
            } else {
                // No workspace, so redirect to Layout_Workspace to allow the user to choose the one they want to create
                newUrl = generatePath(baseUrl, { ...urlParams });
            }

            if (pathname !== newUrl) {
                console.debug("Layout_Project: REDIRECT TO VIEW", newUrl);
                navigate(newUrl, { replace: true }); // Replace the current history entry to avoid redundant navigation
            }

            setLoadingProject(false);
        }
    }, [isLoading, subProject, workspace]);

    return (
        <>
            {loadingProject ? (
                <LoadingBox />
            ) : (
                <WorkspaceViewProvider>
                    <Outlet />
                </WorkspaceViewProvider>
            )}
        </>
    );
};
