import {
    DialogFullScreen,
    Type_props_DialogFullScreen,
} from "src/components/Components_Common/Dialog/DialogFullScreen";
import { MatrixMaterials } from "src/components/Components_Scheduling/Matrix/MatrixMaterials/MatrixMaterials";

type Type_Props_MatrixMaterialsFullScreen = Type_props_DialogFullScreen & {
    taskId: number;
};

export const MatrixMaterialsFullScreen = ({
    openDialog,
    setToggleDialog,
    title,
    taskId,
}: Type_Props_MatrixMaterialsFullScreen) => {
    return (
        <DialogFullScreen
            openDialog={openDialog}
            setToggleDialog={setToggleDialog}
            title={title}
        >
            <MatrixMaterials
                onClose={() => setToggleDialog(false)}
                taskId={taskId}
            />
        </DialogFullScreen>
    );
};
