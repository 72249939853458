import { DrawerProps } from "@mui/material";
import * as React from "react";
import { useState } from "react";

import { Type_show_drawing } from "src/api/tms-projects/drawing/types";
import {
    DrawerGeneric,
    Type_action,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { Type_Resource } from "src/components/Components_Common/forms/InputFile";
import { DrawingForm } from "src/forms/drawings/DrawingForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_DrawingDrawer = DrawerProps & {
    open: boolean;
    onClose: () => void;
    drawingImage: Type_Resource;
    drawingIdToUpdate?: number | null;
    drawing?: Type_show_drawing;
    enabledCrop?: boolean;
    action: Type_action;
    page: string;
};

export const DrawingDrawer = ({
    open,
    onClose,
    drawingImage,
    drawing,
    enabledCrop = true,
    action,
    page,
}: Type_Props_DrawingDrawer) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Drawing");
    const [isLoading, setIsLoading] = useState(false);

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            dataTestIdTitle={`Drawing-Drawer-${action}-Title`}
            title={
                action === "update" ? fmt("TitleUpdate") : fmt("TitleCreate")
            }
            isFormLoading={isLoading}
            formId={"drawings"}
            page={page}
        >
            <DrawingForm
                onClose={onClose}
                drawing={drawing}
                drawingImage={drawingImage}
                enabledCrop={enabledCrop}
                setIsLoading={setIsLoading}
                action={action}
                isFetching={isLoading}
            />
        </DrawerGeneric>
    );
};
