import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { TaskAreaResourcesPath, Url } from "src/api/paths";
import { Type_sch_post_taskAreaResource } from "src/api/tms-scheduling/taskAreaResources/types";

export const indexTaskAreaResourcesForMatrix = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.SCHEDULING}${TaskAreaResourcesPath.TASK_AREA_RESOURCES_FOR_MATRIX}`,
        requestConfig,
    );
};

export const upsertTaskAreaResourceForMatrix = (
    data: Type_sch_post_taskAreaResource,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.SCHEDULING}${TaskAreaResourcesPath.TASK_AREA_RESOURCES}`,
        requestConfig,
    );
};
