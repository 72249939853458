import {
    Type_index_sequenceArea,
    Type_index_sequenceArea_forMatrix,
    Type_sch_index_sequenceArea,
    Type_sch_index_sequenceArea_forMatrix,
} from "./types";

export const formatterIndexSequenceAreas = (
    sequenceAreas: Type_sch_index_sequenceArea[],
): Type_index_sequenceArea[] => {
    return sequenceAreas.map((sequenceArea: Type_sch_index_sequenceArea) =>
        formatterIndexSequenceArea(sequenceArea),
    );
};

export const formatterIndexSequenceArea = (
    sequenceArea: Type_sch_index_sequenceArea,
): Type_index_sequenceArea => {
    return {
        id: sequenceArea.id,
        area_id: sequenceArea.area_id,
        sequence_id: sequenceArea.sequence_id,
        order: sequenceArea.order,
    };
};

export const formatterIndexSequenceAreasForMatrix = (
    sequenceAreas: Type_sch_index_sequenceArea_forMatrix[],
): Type_index_sequenceArea_forMatrix[] => {
    return sequenceAreas.map(
        (sequenceArea: Type_sch_index_sequenceArea_forMatrix) =>
            formatterIndexSequenceAreaForMatrix(sequenceArea),
    );
};

export const formatterIndexSequenceAreaForMatrix = (
    sequenceArea: Type_sch_index_sequenceArea_forMatrix,
): Type_index_sequenceArea_forMatrix => {
    return {
        deep: sequenceArea.area_deep,
        areaId: sequenceArea.area_id,
        areaName: sequenceArea.area_name,
        areaParentId: sequenceArea.area_parent_id,
        areaSortOrder: sequenceArea.area_sortOrder,
        areaTypeName: sequenceArea.area_type_name,
        id: sequenceArea.id,
        order: sequenceArea.order,
    };
};
