/*
This is the helper class for all matrix based on taskAreas

You have to implement getTaskAreaRows in your controller
getTaskAreaRows has 3 params :
    - data contains :
        - the original data from the task
        - the areas (of the task)
        - any taskAreas that are overloaded
    - maxDepth : is required to fill the area columns
    - dataColumns : corresponds to the columns in your controller without areas

You have to add to "Extended Types" your own types.
You can take exemple on MatrixTaskAreasTask.tsx
*/

import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-premium";

import {
    ExtendedType_taskArea_forMatrix_data_task,
    ExtendedType_taskArea_forMatrix_dataTaskArea_task,
} from "src/api/tms-scheduling/taskArea/types";
import { getDifferentAttributes } from "src/components/Components_Common/matrix/Matrix";
import {
    Type_index_dataColumns,
    Type_index_taskAreaMatrix_task,
    Type_index_taskAreaMatrix_taskArea,
} from "src/components/Components_Scheduling/Matrix/helpers/types";

///////////////////////////////////////
///          Base types             ///
///////////////////////////////////////
export type Type_taskArea_forMatrix_data = {
    id: number;
    [key: string]: Date | string | number | boolean | undefined | null;
};

export type Type_taskArea_forMatrix_dataTaskArea = {
    id: number; // is area id
    taskAreaId?: number;
    [key: string]: Date | string | number | boolean | undefined | null;
};

export type Type_taskArea_forMatrix_path = {
    areaName: string;
    areaTypeName: string;
};

export type Type_taskArea_forMatrix_area = {
    deep: number;
    path: Type_taskArea_forMatrix_path[];
    id: number;
    order?: number;
};

export type Type_taskArea_forMatrix_row = {
    [key: `area_name_${number}`]: string;
    [key: `area_type_name_${number}`]: string;
    [key: string]: Date | string | number | boolean | undefined | null; // can be any columns of data
    id: number; // Id of the area
    areaId: number;
    taskId: number;
    taskAreaId?: number;
    order?: number;
};

///////////////////////////////////////
///        Extended types           ///
///////////////////////////////////////
export type ExtendedType_taskArea_forMatrix_data =
    | Type_taskArea_forMatrix_data
    | ExtendedType_taskArea_forMatrix_data_task
    | Type_index_taskAreaMatrix_task;

export type ExtendedType_taskArea_forMatrix_dataTaskArea =
    | Type_taskArea_forMatrix_dataTaskArea
    | ExtendedType_taskArea_forMatrix_dataTaskArea_task
    | Type_index_taskAreaMatrix_taskArea;

export type Type_taskArea_forMatrix<
    T extends ExtendedType_taskArea_forMatrix_data,
    U extends ExtendedType_taskArea_forMatrix_dataTaskArea,
> = {
    areas: Type_taskArea_forMatrix_area[];
    task: T;
    taskAreas: U[];
    dataColumns?: Type_index_dataColumns[];
};

///////////////////////////////////////
///        getTaskAreaRows          ///
///////////////////////////////////////
type Type_getTaskAreaRows<
    T extends ExtendedType_taskArea_forMatrix_data,
    U extends ExtendedType_taskArea_forMatrix_dataTaskArea,
> = {
    data: Type_taskArea_forMatrix<T, U> | undefined;
    dataColumns: GridColDef[] | undefined;
    maxDepth: number | null;
};

export const getTaskAreaRows = <
    T extends ExtendedType_taskArea_forMatrix_data,
    U extends ExtendedType_taskArea_forMatrix_dataTaskArea,
>({
    data,
    dataColumns, // columns without areas
    maxDepth,
}: Type_getTaskAreaRows<T, U>): Type_taskArea_forMatrix_row[] => {
    if (!data) return [];
    if (!data.areas) return [];
    if (!dataColumns) return [];
    if (!maxDepth) return [];

    const rows: Type_taskArea_forMatrix_row[] = [];

    data.areas.forEach(function (area: Type_taskArea_forMatrix_area) {
        const taskArea = data.taskAreas.find(
            (taskArea) => taskArea.areaId == area.id,
        );
        rows.push(
            addTaskAreaRow({
                area: area,
                dataColumns: dataColumns,
                maxDepth: maxDepth,
                task: data.task,
                taskArea: taskArea,
            }),
        );
    });

    return rows;
};

///////////////////////////////////////
///        addTaskAreaRow           ///
///////////////////////////////////////
type Type_addTaskAreaRow<
    T extends ExtendedType_taskArea_forMatrix_data,
    U extends ExtendedType_taskArea_forMatrix_dataTaskArea,
> = {
    area: Type_taskArea_forMatrix_area;
    dataColumns: GridColDef[];
    maxDepth: number;
    task: T;
    taskArea: U | undefined;
};

const addTaskAreaRow = <
    T extends ExtendedType_taskArea_forMatrix_data,
    U extends ExtendedType_taskArea_forMatrix_dataTaskArea,
>({
    area,
    dataColumns,
    maxDepth,
    task,
    taskArea,
}: Type_addTaskAreaRow<T, U>): Type_taskArea_forMatrix_row => {
    const row: Type_taskArea_forMatrix_row = {
        id: area.id,
        areaId: area.id,
        taskId: task.id,
        taskAreaId: taskArea ? (taskArea.id as number) : undefined,
        ...(area.order !== undefined ? { order: area.order } : {}),
    };

    // The first columns corresponding to areas : Fill each level's area_name and area_type up to maxDepth
    for (let i = 0; i < maxDepth; i++) {
        row[`area_name_${i}`] = area.path[i] ? area.path[i].areaName : "";
        row[`area_type_name_${i}`] = area.path[i]
            ? area.path[i].areaTypeName
            : "";
    }

    // fill each column of the row with data
    dataColumns.forEach(function (column: GridColDef) {
        if (
            taskArea &&
            column.field in taskArea &&
            typeof taskArea[column.field] !== "undefined"
        ) {
            // data from taskArea
            row[column.field] = taskArea[column.field as keyof U];
        } else if (column.field in task) {
            // data from task configuration
            row[column.field] = task[column.field as keyof T];
        } else row[column.field] = "";
    });

    return row;
};

//
// Specific management for updating a taskAreaMatrix
//
export const processRowUpdateTaskAreaMatrix = (
    newRow: GridValidRowModel,
    oldRow: GridValidRowModel,
): GridValidRowModel => {
    const diff: GridValidRowModel = getDifferentAttributes(newRow, oldRow);
    if (diff) {
        diff.taskAreaId = oldRow.taskAreaId;
    }
    return diff;
};
