import { useMutation, useQuery } from "react-query";

import { SequenceAreaKeys } from "src/api/tms-scheduling/keys";
import {
    createOrUpdateMultipleSequenceAreas,
    indexSequenceAreas,
} from "src/api/tms-scheduling/sequenceAreas/services";
import {
    Type_sch_index_sequenceArea,
    Type_sch_index_sequenceArea_forMatrix,
    Type_sch_post_sequenceArea,
} from "src/api/tms-scheduling/sequenceAreas/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import {
    formatterIndexSequenceAreas,
    formatterIndexSequenceAreasForMatrix,
} from "./formatter";

export const useIndexSequenceAreas = (
    sequenceId: number,
    enabled?: boolean,
) => {
    const { requestConfig } = useProject();
    return useQuery({
        enabled: enabled,
        queryKey: [SequenceAreaKeys.INDEX, requestConfig, sequenceId],
        queryFn: () => indexSequenceAreas({ sequenceId, ...requestConfig }),
        refetchOnWindowFocus: false,
        select: (data) => {
            const SequenceAreas: Type_sch_index_sequenceArea[] =
                data?.data?.data;
            return formatterIndexSequenceAreas(SequenceAreas);
        },
    });
};

export const useIndexSequenceAreasForMatrix = (
    sequenceId: number,
    enabled: boolean = false,
) => {
    const { requestConfig } = useProject();
    return useQuery({
        enabled: enabled,
        queryKey: [
            SequenceAreaKeys.INDEX_FOR_MATRIX,
            requestConfig,
            sequenceId,
        ],
        queryFn: () =>
            indexSequenceAreas(
                { sequenceId, ...requestConfig },
                {
                    forMatrix: true,
                },
            ),
        refetchOnWindowFocus: false,
        select: (data) => {
            const sequenceAreas = data?.data?.data;
            return formatterIndexSequenceAreasForMatrix(
                sequenceAreas as Type_sch_index_sequenceArea_forMatrix[],
            );
        },
    });
};

export const mutationCreateOrUpdateMultipleSequenceAreas = (
    sequenceId: number,
    showToast: boolean = false,
) => {
    const { requestConfig } = useProject();

    const { addSuccess } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerSequenceAreas",
    );

    return useMutation({
        mutationFn: (sequenceAreas: Type_sch_post_sequenceArea[]) =>
            createOrUpdateMultipleSequenceAreas(sequenceAreas, {
                sequenceId,
                ...requestConfig,
            }),
        onSuccess: (data: any) => {
            if (data.success) {
                if (showToast) {
                    addSuccess({
                        description: fmt("Toast.ToastSuccessUpdate"),
                    });
                }
            }
        },
        onError: (err: any) => {
            return err;
        },
    });
};
