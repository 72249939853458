import {
    Alert,
    AlertTitle,
    FormControlLabel,
    Stack,
    Switch,
    Typography,
} from "@mui/material";
import {
    DataGridPremium,
    GridCellModes,
    GridCellModesModel,
    GridCellParams,
    GridColDef,
    GridRowModel,
} from "@mui/x-data-grid-premium";
import React, { useCallback, useState } from "react";

import { selectColumn } from "src/components/Components_Common/matrix/columnDefinitions/selectColumn";

const areaTypes = [
    { id: "none", name: "None" },
    { id: 1, name: "Batiment" },
    { id: 2, name: "Appartement" },
    { id: 3, name: "Chambre" },
    { id: 4, name: "Salle de bain" },
    { id: 5, name: "Salon" },
];

const rows: GridRowModel[] = [
    {
        pathName: "Zone1",
        name: "Zone 1",
        color: "red",
        areaType: "Batiment",
        areaTypeId: 1,
        id: 1,
        path: [1],
    },
    {
        pathName: "Zone1/Zone1.1",
        name: "Zone 1.1",
        color: "green",
        areaType: "Appartement",
        areaTypeId: 2,
        id: 2,
        path: [1, 2],
    },
    {
        pathName: "Zone1/Zone1.1/Zone1.1.1",
        name: "Zone 1.1.1",
        color: "blue",
        areaType: "Chambre",
        areaTypeId: 3,
        id: 3,
        path: [1, 2, 3],
    },
    {
        pathName: "Zone1/Zone1.1/Zone1.1.2",
        name: "Zone 1.1.2",
        color: "blue",
        areaType: "Salle de bain",
        areaTypeId: 4,
        id: 4,
        path: [1, 2, 4],
    },
    {
        pathName: "Zone1/Zone1.1/Zone1.1.3",
        name: "Zone 1.1.3",
        color: "blue",
        areaType: "Salon",
        areaTypeId: 5,
        id: 5,
        path: [1, 2, 5],
    },
    {
        pathName: "Zone1/Zone1.2",
        name: "Zone 1.2",
        color: "white",
        areaType: "Appartement",
        areaTypeId: 2,
        id: 6,
        path: [1, 6],
    },
];

export const DataGridSingleClickDemo = () => {
    const [copiedData, setCopiedData] = useState("");
    const [isSingleClickEditing, setIsSingleClickEditing] = useState(true);

    const columnsCustomSelect: GridColDef[] = [
        {
            field: "name",
            headerName: "Area editable",
            flex: 1,
            editable: true,
        },
        {
            field: "color",
            headerName: "Color editable",
            flex: 1,
            editable: true,
        },
        selectColumn({
            field: "areaTypeId",
            headerName: "AreaType renderSelectEditInputCell",
            editable: true,
            display: "flex",
            flex: 1,
            options: areaTypes,
        }),
    ];

    /*
     * - Gestion de l'édition au simple clic
     */
    const [cellModesModel, setCellModesModel] = useState({});

    // Lien : https://mui.com/x/react-data-grid/recipes-editing/#single-click-editing
    const handleCellClick = useCallback(
        (params: GridCellParams, event: React.MouseEvent) => {
            if (!params.isEditable) {
                return;
            }
            // Vérifiez= si la touche Ctrl est maintenue.
            // On sort si maintenu pour conserver l'édtion de masse
            if (event.ctrlKey) {
                return;
            }

            // Ignore portal
            if (
                (event.target as any).nodeType === 1 &&
                !event.currentTarget.contains(event.target as Element)
            ) {
                console.log("ignore portal");
                return;
            }

            type CellModesModel = {
                [key: string]: {
                    [field: string]: { mode: GridCellModes };
                };
            };

            setCellModesModel((prevModel: CellModesModel) => {
                return {
                    ...Object.keys(prevModel).reduce(
                        (acc, id) => ({
                            ...acc,
                            [id]: Object.keys(prevModel[id]).reduce(
                                (acc2, field) => ({
                                    ...acc2,
                                    [field]: { mode: GridCellModes.View },
                                }),
                                {},
                            ),
                        }),
                        {},
                    ),
                    [params.id]: {
                        ...Object.keys(prevModel[params.id] || {}).reduce(
                            (acc, field) => ({
                                ...acc,
                                [field]: { mode: GridCellModes.View },
                            }),
                            {},
                        ),
                        [params.field]: { mode: GridCellModes.Edit },
                    },
                };
            });
        },
        [],
    );

    const handleCellModesModelChange = useCallback(
        (newModel: GridCellModesModel) => {
            setCellModesModel(newModel);
        },
        [],
    );

    return (
        <Stack gap={8}>
            <Typography variant="h3">
                DataGridPremium Single Click Editing
            </Typography>

            <FormControlLabel
                control={
                    <Switch
                        checked={isSingleClickEditing}
                        onChange={(e) =>
                            setIsSingleClickEditing(e.target.checked)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                    />
                }
                label="Single Click Editing Mode"
            />

            <DataGridPremium
                rows={rows}
                columns={columnsCustomSelect}
                // Affichage hierarchy
                treeData
                getTreeDataPath={(row: GridRowModel) =>
                    row?.pathName.split("/")
                }
                // Selection
                cellSelection
                // single click editing
                {...(isSingleClickEditing
                    ? {
                          cellModesModel: cellModesModel,
                          onCellModesModelChange: handleCellModesModelChange,
                          onCellClick: handleCellClick,
                      }
                    : {})}
                // Global Props
                disableRowSelectionOnClick={true}
                disableColumnSorting={true} // désactive le tri de colonne
                disableColumnResize={false} // active le redimensionnement de colonne
                disableColumnMenu={true} // Cache le menu dans l'entête des colonnes
                hideFooter={true} // cache le footer qui affiche juste le nombre d'éléments par défaut et la pagination
                ignoreValueFormatterDuringExport={true} // Récupération de la valeur brute du champ et non celle du visuelle
                defaultGroupingExpansionDepth={-1} // permet d'empêcher le tableau de replier la hierarchy lorsque l'on copie une cellule
                // debug
                onClipboardCopy={(copiedString) => setCopiedData(copiedString)} // For debug only, can be removed
            />

            <Alert severity="info" sx={{ width: "100%", mt: 1 }}>
                <AlertTitle>Copied data:</AlertTitle>
                <code
                    style={{
                        display: "block",
                        maxHeight: 200,
                        overflow: "auto",
                        whiteSpace: "pre-line",
                    }}
                >
                    {copiedData}
                </code>
            </Alert>
        </Stack>
    );
};
