import dayjs, { Dayjs, extend, locale, Ls } from "dayjs";
import duration, { Duration } from "dayjs/plugin/duration";
import isSameOrBeforePlugin from "dayjs/plugin/isSameOrBefore";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

extend(localeData);
extend(localizedFormat);
extend(duration);
extend(utc);
extend(timezone);

import {
    BACKEND_DATE_FORMAT,
    BACKEND_DATE_TIME_FORMAT,
    BACKEND_TIME_FORMAT,
} from "src/configurations/LocalizationWrapper";
import { getLocalStorageItem } from "src/utils/localStorageServices";

export const chooseLocale = (localeStr: string | null): string => {
    localeStr = localeStr ? localeStr.toLowerCase().replace("_", "-") : "en";
    if (Ls[localeStr]) {
        return localeStr;
    } else if (Ls[localeStr.substring(0, 2)]) {
        return localeStr.substring(0, 2);
    }
    return "en-gb";
};

/**
 * Get the current date format in the current locale
 */
export const getCurrentFormat = (customLocale?: string): string => {
    const language = customLocale ?? getLocalStorageItem("language");
    locale(chooseLocale(language));
    return dayjs.localeData().longDateFormat("L");
};

/**
 * Get the current date and time format in the current locale
 */
export const getCurrentDateTimeFormat = (customLocale?: string): string => {
    const language = customLocale ?? getLocalStorageItem("language");
    locale(chooseLocale(language));
    // On aurait préféré faire directement longDateFormat("L LT"), mais ça fait planter le composant
    return `${dayjs.localeData().longDateFormat("L")} ${dayjs.localeData().longDateFormat("LT")}`;
};

/**
 * Display a formatted date in the current locale
 * @param date The date to format
 * @param customLocale The custom locale to use
 * @returns The formatted date in string
 */
export const displayFormattedDate = (
    date: dayjs.ConfigType,
    customLocale?: string | null,
): string => {
    const language = customLocale ?? getLocalStorageItem("language");
    return dayjs(date).locale(chooseLocale(language)).format("L");
};

/**
 * Display a formatted date and time in the current locale
 * @param date The date to format
 * @param customLocale The custom locale to use
 * @returns The formatted date and time in string
 */
export const displayFormattedDateTime = (
    date: dayjs.ConfigType,
    customLocale?: string | null,
): string => {
    const language = customLocale ?? getLocalStorageItem("language");
    return dayjs(date)
        .locale(chooseLocale(language))
        .tz(dayjs.tz.guess())
        .format("L LT");
};

/**
 * Check if a date is valid, useful for Yup validation schema
 * @param date
 */
export const isValidDate = (date: dayjs.ConfigType): boolean => {
    return dayjs(date).isValid();
};

/**
 * Get the string of relative time from now.
 * Example: 2 days ago
 * @param date
 */
export const getFromNow = (date: string | null): string => {
    if (!date) return "";

    const dateUTC = dayjs.utc(date);
    const language = getLocalStorageItem("language");
    locale(chooseLocale(language));
    extend(relativeTime);
    return dayjs(dateUTC).fromNow();
};

/**
 * Get a formatted date from a Frontend date object to a string in the Backend format
 * Useful to send to the API
 * @param date
 * @param format
 */
export const formatDateForBack = (
    date: dayjs.ConfigType,
    format: string = BACKEND_DATE_FORMAT,
): string | undefined => {
    if (!date) return undefined;
    return dayjs(date).format(format);
};

/**
 * Get a formatted date and time from a Frontend date object to a string in the Backend format
 * Useful to send to the API
 * @param date
 * @param format
 */
export const formatDateTimeForBack = (
    date: dayjs.ConfigType,
    format: string = BACKEND_DATE_TIME_FORMAT,
): string | undefined => {
    if (!date) return undefined;
    return dayjs(date).format(format);
};

export const formatTimeForBack = (
    time: dayjs.ConfigType,
): string | undefined => {
    if (!time) return undefined;
    return dayjs(time).format(BACKEND_TIME_FORMAT);
};

/**
 * Convert a backend date string to a DayJs object.
 * @param date - Date string in backend format, or null/undefined.
 * @param format - Desired format for the date string.
 * @returns A DayJs object or null.
 */
export const formatDateForFront = (
    date: string | null | undefined,
    format: string = BACKEND_DATE_FORMAT,
): Dayjs | null => {
    if (!date) return null;
    return dayjs(date, format);
};

/** same with datetime for front */
export const formatDateTimeForFront = (
    date: string | null | undefined,
    format: string = BACKEND_DATE_TIME_FORMAT,
): Dayjs | null => {
    if (!date) return null;
    return dayjs(date, format);
};

export const getDate = (date?: dayjs.ConfigType, format?: string): Dayjs => {
    if (date) {
        return dayjs(date, format);
    }
    return dayjs();
};

/**
 * Check if a date is the same or before another date
 * @param date1
 * @param date2
 */
export const isSameOrBefore = (
    date1: dayjs.ConfigType,
    date2: dayjs.ConfigType,
): boolean => {
    extend(isSameOrBeforePlugin);
    return dayjs(date1).isSameOrBefore(date2);
};

// Duration

export const getDuration = (durationValue: string): Duration => {
    return dayjs.duration(durationValue);
};

/**
 * Convert an ISO duration to months
 * @param date
 * @returns The number of months
 */
export const isoToMonths = (date: string): number => {
    return dayjs.duration(date).asMonths();
};

/**
 * Convert a number of months to an ISO duration
 * @param monthsNbr
 * @returns The ISO duration
 */
export const monthsToIso = (monthsNbr: number): string => {
    return dayjs.duration(monthsNbr, "months").toISOString();
};

/**
 * Returns un string in object Date
 * @param date
 */
export const toDate = (date?: dayjs.ConfigType): Date | null => {
    if (!date) return null;

    return dayjs(date).toDate();
};
