import { useState } from "react";

import {
    mutationDeleteReportTemplate,
    mutationStatusReportTemplate,
    useIndexReportTemplate,
} from "src/api/tms-scheduling/reportTemplate";
import { Page_ReportsComponent } from "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_Reports/Page_Reports.component";

export type Type_modalDeleteReport = {
    isOpen: boolean;
    props?: any;
};

export const Page_ReportsContainer = () => {
    const { isFetching, data = [] } = useIndexReportTemplate() || {};

    // STATUS
    const { mutate: mutationReportStatus } =
        mutationStatusReportTemplate() || {};

    const { mutateAsync: mutateDeleteReport } =
        mutationDeleteReportTemplate() || {};

    const changeStatus = async (id: number, report: any) => {
        mutationReportStatus({
            id: id,
            enabled: !report.enabled,
        });
    };

    // DELETE
    const [modalDeleteReport, setModalDeleteReport] =
        useState<Type_modalDeleteReport>({
            isOpen: false,
            props: { name: "" },
        });

    const removeReport = async (id: number) => {
        await mutateDeleteReport(id);
    };

    return (
        <Page_ReportsComponent
            isFetching={isFetching}
            reports={data ?? []}
            changeStatus={changeStatus}
            deleteReport={removeReport}
            modalDeleteReport={modalDeleteReport}
            setModalDeleteReport={setModalDeleteReport}
        />
    );
};
