import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    remove,
    RemoveReturnType,
    Type_AppRequestConfig,
    Type_ProjectRequestConfig,
    Type_RequestConfig,
} from "src/api/fetch";
import { ConstrainPath, Url } from "src/api/paths";

import { Type_sch_post_constraint, Type_sch_put_constraint } from "./types";

export const indexConstraints = (
    requestConfig: Type_ProjectRequestConfig,
    appRequestConfig: Type_AppRequestConfig,
    signal?: AbortSignal,
): GetReturnType => {
    return get(
        `${Url.SCHEDULING}${ConstrainPath.CONSTRAINTS}`,
        { ...requestConfig, ...appRequestConfig },
        null,
        signal,
    );
};

export const createConstraint = (
    data: Type_sch_post_constraint,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.SCHEDULING}${ConstrainPath.CONSTRAINTS}`,
        requestConfig,
    );
};

export const updateConstraint = (
    data: Type_sch_put_constraint,
    id: number,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return put(
        data,
        `${Url.SCHEDULING}${ConstrainPath.CONSTRAINTS}/${id}`,
        requestConfig,
    );
};

export const deleteConstraint = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        `${Url.SCHEDULING}${ConstrainPath.CONSTRAINTS}/${id}`,
        requestConfig,
    );
};
