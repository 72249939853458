import { GridColDef } from "@mui/x-data-grid-premium";

type Type_customItem = {
    id: number;
    field: string;
    headerName: string;
};

type Type_Props_matrixCustomColumns = {
    data: Type_customItem[];
    editable: boolean;
};

export const matrixCustomColumns = ({
    data,
    editable,
}: Type_Props_matrixCustomColumns): GridColDef[] => {
    if (!data) return [];
    const columnArray: GridColDef[] = [];
    data.forEach((item: Type_customItem) => {
        columnArray.push({
            field: item.field,
            type: "number",
            headerName: item.headerName,
            flex: 1,
            resizable: true,
            sortable: true,
            editable: editable,
            filterable: true,
        });
    });
    return columnArray;
};
