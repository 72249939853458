import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { getParamsJoin, Type_Params } from "src/api/filters";
import { TradePath, Url } from "src/api/paths";
import {
    Type_prj_post_trade,
    Type_prj_put_trade,
} from "src/api/tms-projects/trades/types";

export const indexTrades = (
    requestConfig: Type_RequestConfig,
    params: Type_Params = {},
): GetReturnType => {
    const urlParams: string = getParamsJoin(params);
    return get(`${Url.PROJECTS}${TradePath.TRADES}${urlParams}`, requestConfig);
};

export const statusTrade = (
    data: Type_prj_put_trade,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.PROJECTS}${TradePath.TRADES}/${data.id}`,
        requestConfig,
    );
};

export const createTrade = (
    data: Type_prj_post_trade,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(data, `${Url.PROJECTS}${TradePath.TRADES}`, requestConfig);
};

export const updateTrade = (
    data: Type_prj_put_trade,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return put(
        data,
        `${Url.PROJECTS}${TradePath.TRADES}/${data.id}`,
        requestConfig,
    );
};

export const showTrade = (
    id: number,
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.PROJECTS}${TradePath.TRADES}/${id}`, requestConfig);
};

export const deleteTrade = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(`${Url.PROJECTS}${TradePath.TRADES}/${id}`, requestConfig);
};

export const selectListTrades = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.PROJECTS}${TradePath.TRADES}/select-list`, requestConfig);
};

export const exportTrades = (
    requestConfig: Type_RequestConfig,
    signal?: AbortSignal,
): GetReturnType => {
    return get(
        `${Url.PROJECTS}${TradePath.TRADES_EXPORT}`,
        requestConfig,
        null,
        signal,
        {
            responseType: "arraybuffer",
        },
    );
};

export const importTrades = (data: any, requestConfig: Type_RequestConfig) => {
    return post(data, `${Url.PROJECTS}${TradePath.TRADES_IMPORT}`, {
        ...requestConfig,
        "Content-Type": "multipart/form-data",
    });
};
