import { Stack } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { useMemo } from "react";
import { useQueryClient } from "react-query";

import { TradeKeys } from "src/api/tms-projects/keys";
import {
    mutationUpdateSubTrade,
    useIndexSubTrades,
} from "src/api/tms-projects/subTrades";
import { colorPickerColumn } from "src/components/Components_Common/matrix/columnDefinitions/colorPickerColumn";
import { multiLanguesColumns } from "src/components/Components_Common/matrix/columnDefinitions/multiLanguesColumn";
import { Matrix } from "src/components/Components_Common/matrix/Matrix";
import { useProject } from "src/contexts/project";
import { useUser } from "src/contexts/user";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_MatrixSubTrades = {
    page: string;
    onClose: () => void;
};

export const MatrixSubTrades = ({
    page,
    onClose,
}: Type_Props_MatrixSubTrades) => {
    const { requestConfig, projectLanguages = [] } = useProject();
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");

    ///////////////////////////////////////
    ///           Permission            ///
    ///////////////////////////////////////
    const { checkPermission, user } = useUser();

    const editable = useMemo(() => {
        return page
            ? checkPermission(page, "create") || checkPermission(page, "update")
            : true;
    }, [page, user]);

    ///////////////////////////////////////
    ///          Fetched data           ///
    ///////////////////////////////////////
    const queryClient = useQueryClient();

    const { data: fetchedSubTrades, isFetching } =
        useIndexSubTrades({
            forMatrix: true,
        }) || {};

    const { mutateAsync: mutateUpdate } = mutationUpdateSubTrade({
        forMatrix: true,
    });

    const refetch = async () => {
        await queryClient.invalidateQueries({
            queryKey: [TradeKeys.INDEX, requestConfig],
        });
    };

    ///////////////////////////////////////
    ///          Columns                ///
    ///////////////////////////////////////
    const columns: GridColDef[] = [
        colorPickerColumn({
            field: "color",
            headerName: fmtTableColumn("Color"),
            editable: false,
            display: "flex",
        }),
        {
            field: "parentName",
            type: "string",
            headerName: fmtTableColumn("Parent"),
            flex: 1,
            editable: false,
        },
        ...multiLanguesColumns({
            field: "names",
            headerName: fmtTableColumn("Name"),
            languages: projectLanguages,
            editable: editable,
            display: "flex",
            flex: 1,
        }),
        {
            field: "code",
            type: "string",
            headerName: fmtTableColumn("Code"),
            flex: 1,
            display: "flex",
            editable: editable,
        },
        {
            field: "team",
            type: "number",
            headerName: fmtTableColumn("Team"),
            flex: 1,
            display: "flex",
            editable: editable,
            headerAlign: "left",
        },
    ];

    ///////////////////////////////////////
    ///              Rows               ///
    ///////////////////////////////////////
    const dataRows = useMemo(() => fetchedSubTrades, [fetchedSubTrades]);

    return (
        <Stack p={4} flexGrow={1}>
            <Matrix
                onClose={onClose}
                columns={columns}
                rows={dataRows || []}
                loading={isFetching}
                refetch={refetch}
                refreshPlanning={false}
                mutateUpdate={mutateUpdate}
            />
        </Stack>
    );
};
