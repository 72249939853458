import { formatterIndexTaskAreaForMatrixArea } from "src/api/tms-scheduling/taskArea/formatters";
import {
    Type_post_taskAreaResource_forFormatter,
    Type_sch_index_taskAreaResource_forMatrix_taskAreasResource,
    Type_sch_index_taskAreaResource_forMatrix_taskResource,
    Type_sch_index_taskAreaResources_forMatrix,
    Type_sch_post_taskAreaResource,
} from "src/api/tms-scheduling/taskAreaResources/types";
import {
    formatterIndexMatrix_task,
    formatterMatrix_dataColumns,
    formatterMatrix_taskAreas,
} from "src/components/Components_Scheduling/Matrix/helpers/formatters";
import {
    Type_index_dataColumns,
    Type_index_taskAreaMatrix_data,
} from "src/components/Components_Scheduling/Matrix/helpers/types";

export const formatterIndexTaskAreaResourcesForMatrix = (
    data: Type_sch_index_taskAreaResources_forMatrix,
): Type_index_taskAreaMatrix_data => {
    return {
        areas: data.areas.map(formatterIndexTaskAreaForMatrixArea),
        task: formatterIndexMatrix_task<Type_sch_index_taskAreaResource_forMatrix_taskResource>(
            data.taskResource,
            "resource",
            "resource_id",
        ),
        taskAreas:
            formatterMatrix_taskAreas<Type_sch_index_taskAreaResource_forMatrix_taskAreasResource>(
                data.taskAreasResource,
                "resource",
                "resource_id",
            ),
        dataColumns: data.taskResource
            ? data.taskResource.map(
                  (
                      item: Type_sch_index_taskAreaResource_forMatrix_taskResource,
                  ): Type_index_dataColumns =>
                      formatterMatrix_dataColumns<Type_sch_index_taskAreaResource_forMatrix_taskResource>(
                          item,
                          "resource",
                          "resource_id",
                      ),
              )
            : [],
    };
};

export const formatterCreateTaskAreaResource = (
    data: Type_post_taskAreaResource_forFormatter,
): Type_sch_post_taskAreaResource => {
    const formattedObject: Type_sch_post_taskAreaResource = {
        quantity: 0,
    };

    for (const [key, value] of Object.entries(data)) {
        const splitKey: string[] = key.split("_"); // Extracts 'key' and 'id' from 'key_id'
        formattedObject["resource_id"] = Number(splitKey[1]);
        formattedObject["quantity"] = value;
    }

    return formattedObject;
};
