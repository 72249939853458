import {
    Type_index_role,
    Type_usr_index_role,
} from "src/api/tms-users/rolesAndPermissions/types";

// API
export type Type_com_index_licenseTypeMode = {
    id: number;
    name: "personal" | "project";
    sortOrder: number;
    hasMultipleRoles: boolean;
    enabled: boolean;
};

export type Type_com_index_licenseType = {
    id: number;
    name: string;
    duration: string;
    licenseTypeMode: Type_com_index_licenseTypeMode;
    roles: Type_usr_index_role[];
    enabled: boolean;
};

export type Type_com_selectList_licenseType = {
    id: number;
    name: string;
    duration: number;
};

export type Type_com_show_licenseType = {
    id: number;
    names: {
        [key: string]: string;
    };
    duration: string;
    licenseTypeMode: Type_com_index_licenseTypeMode;
    roles: Type_usr_index_role[];
    enabled: boolean;
};

export type Type_com_post_licenseType = {
    license_type_mode_id?: number;
    duration: number;
    names: {
        [key: string]: string;
    };
    roles: number[];
};

export type Type_com_put_licenseType = {
    license_type_mode_id?: number;
    duration?: number;
    names?: {
        [key: string]: string;
    };
    roles?: number[];
    enabled?: boolean;
};

//FRONT
export type Type_index_licenseType = {
    id: number;
    name: string;
    duration: number;
    licenseTypeName: string;
    roleTypeNames: string;
    enabled: boolean;
};

export type Type_show_licenseType = {
    mode: number;
    duration: number;
    names: {
        [key: string]: string;
    };
    roles: Type_index_role[] | number[];
    role?: Type_index_role;
    id: number;
    hasMultipleRoles: boolean;
    enabled: boolean;
};

export type Type_selectList_licenseType = {
    id: number;
    name: string;
    duration: number;
};

export type Type_put_licenseType = {
    id: number;
    enabled: boolean;
};

export type Type_index_licenseTypeMode = {
    id: number;
    name: "personal" | "project";
    sortOrder: number;
    hasMultipleRoles: boolean;
    enabled: boolean;
};

export enum LICENCE_TYPE_ID {
    "PERSONAL" = 1,
    "PROJECT" = 2,
}
