import { useMutation, useQuery } from "react-query";

import { Type_RequestConfig } from "src/api/fetch";
import { TaskAreaResourceKeys } from "src/api/tms-scheduling/keys";
import {
    formatterCreateTaskAreaResource,
    formatterIndexTaskAreaResourcesForMatrix,
} from "src/api/tms-scheduling/taskAreaResources/formatters";
import {
    indexTaskAreaResourcesForMatrix,
    upsertTaskAreaResourceForMatrix,
} from "src/api/tms-scheduling/taskAreaResources/services";
import { Type_post_taskAreaResource } from "src/api/tms-scheduling/taskAreaResources/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexTaskAreaResourcesForMatrix = (
    taskAreaConfig: Type_RequestConfig,
) => {
    const { requestConfig } = useProject();
    const config = { ...requestConfig, ...taskAreaConfig };

    return useQuery({
        queryKey: [TaskAreaResourceKeys.INDEX_FOR_MATRIX, config],
        queryFn: () => indexTaskAreaResourcesForMatrix(config),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success) {
                throw new Error(
                    "Wrong format data: useIndexTaskAreaResourcesForMatrix",
                );
            }
            return formatterIndexTaskAreaResourcesForMatrix(data.data.data);
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
        enabled:
            !!config.projectId &&
            !!config.subProjectId &&
            !!config.versionId &&
            !!config.taskId,
    });
};

export const mutationUpsertTaskAreaResourceForMatrix = (taskId: number) => {
    const { requestConfig } = useProject();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (resource: Type_post_taskAreaResource) => {
            return upsertTaskAreaResourceForMatrix(
                formatterCreateTaskAreaResource(resource.data),
                {
                    ...requestConfig,
                    areaId: resource.id,
                    taskId: taskId,
                },
            );
        },
        onSuccess: (data): void => {
            if (!data?.success) {
                throw new Error(
                    "Wrong format data: mutationUpsertTaskAreaResourceForMatrix",
                );
            }
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};
