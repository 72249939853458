import { Dispatch, SetStateAction, useState } from "react";
import { useQueryClient } from "react-query";

import { TaskKeys } from "src/api/tms-scheduling/keys";
import {
    mutationCreateTaskLink,
    mutationDeleteTaskLink,
} from "src/api/tms-scheduling/taskLinks";
import {
    formatterTaskLinkToFlow,
    formatterTypePropsLinkToPostTaskLink,
} from "src/api/tms-scheduling/taskLinks/formatters";
import { Type_index_taskLink } from "src/api/tms-scheduling/taskLinks/types";
import {
    mutationCreateTask,
    mutationDeleteTask,
    mutationUpdateTask,
} from "src/api/tms-scheduling/tasks";
import { formatterTaskToFlow } from "src/api/tms-scheduling/tasks/formatters";
import { Type_sch_index_task } from "src/api/tms-scheduling/tasks/types";
import { Flow } from "src/components/Components_Teamoty/Flow/Flow";
import {
    Type_flow,
    Type_flowLink,
    Type_flowTask,
    Type_Props_Link,
} from "src/components/Components_Teamoty/Flow/Flow.type";
import { useProject } from "src/contexts/project";
import {
    Type_event_link,
    Type_event_task,
    useChannel,
} from "src/hooks/useChannel";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { COLORS_TASK } from "src/theme/stylesheet";
import { getLocalStorageItem } from "src/utils/localStorageServices";

type Type_Props_PertDiagram = {
    sequenceId: number;
    tasks: Type_flow;
    setTasks: Dispatch<SetStateAction<Type_flow>>;
    fixedSize: boolean | undefined;
};

export const SequencePertDiagram = ({
    sequenceId,
    tasks,
    setTasks,
    fixedSize = false,
}: Type_Props_PertDiagram) => {
    const [selectedTaskId, setSelectedTaskId] = useState<null | number>(null);

    const { openPaper, closePaper, isPaperOpen } = useContextualDrawer();

    const language = getLocalStorageItem("language");
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    const { sendEvent } = useChannel({
        eventHandler: ({ event, data }) => {
            if (event === "updateTask") {
                const taskData = data as Type_event_task;
                setTasks((prev) => {
                    prev.tasks.map((task: Type_flowTask, index: number) => {
                        if (task.id === taskData?.id) {
                            prev.tasks[index] = {
                                ...task,
                                ...formatterTaskToFlow(
                                    taskData as Type_sch_index_task,
                                ),
                            };
                        }
                    });
                    return { ...prev };
                });
            }
            if (event === "updateLink") {
                const linkData = data as Type_event_link;
                setTasks((prev) => {
                    prev.links.map((link: Type_flowLink, index: number) => {
                        if (link.id === linkData!.id) {
                            prev.links[index] = {
                                ...link,
                                ...formatterTaskLinkToFlow(
                                    linkData as Type_index_taskLink,
                                ),
                            };
                        }
                    });
                    return { ...prev };
                });
            }
            // TODO: Add the possibility for a task to not have a destination linkTo/linkFrom in PERT.
            // else if (event === "addLink") {
            //     setTaskLinks((prevLinks) => {
            //         return {
            //             ...prevLinks,
            //             ...formatterTaskLinkToFlow(data as Type_event_link),
            //         };
            //     });
            // }
        },
    });

    const { mutateAsync: createTask } = mutationCreateTask({
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [
                    TaskKeys.INDEX,
                    requestConfig,
                    {
                        sequence_id: sequenceId,
                    },
                ],
                exact: true,
            });
        },
    });

    const { mutateAsync: createTaskLink } = mutationCreateTaskLink();
    const { mutateAsync: deleteTaskLink } = mutationDeleteTaskLink();
    const { mutateAsync: deleteTask } = mutationDeleteTask();
    const { mutateAsync: updateTask } = mutationUpdateTask();

    const onTaskAdd = async (pt: [number, number]) => {
        // Select palette 600
        let colorsTask = COLORS_TASK;
        colorsTask = colorsTask.filter((value, index) => index % 8 === 0);

        // Check color used
        tasks.tasks.forEach((task) => {
            const index = colorsTask.findIndex((value) => value == task.color);

            if (index !== -1) {
                colorsTask.push(task.color);
                colorsTask.splice(index, 1);
            }
        });

        // Create task
        const { data } = await createTask({
            names: { [language]: "new task" },
            color: colorsTask.shift(),
            xy: pt,
            sequence_id: sequenceId,
            taskCategory_id: null,
        });
        return formatterTaskToFlow(data.data as Type_sch_index_task);
    };

    const onLinkAdd = async (props: Type_Props_Link) => {
        const taskLink = formatterTypePropsLinkToPostTaskLink(props);

        const { data } = await createTaskLink(taskLink);
        return formatterTaskLinkToFlow(data.data as Type_index_taskLink);
    };

    const onTaskDelete = async ({ id }: { id: number }) => {
        await deleteTask(id);
        if (selectedTaskId === id) {
            closePaper("task");
        }
    };

    const onLinkDelete = async ({ id }: { id: number }) => {
        await deleteTaskLink(id);
    };

    const onOpenTaskDrawer = ({ id }: { id: number }) => {
        setSelectedTaskId(id);
        if (isPaperOpen("task")) {
            sendEvent("updateIdTask", id);
        } else openPaper("task", { id }, true);
    };

    const onUpdateTask = async (task: any) => {
        if (task.name) {
            task.names = { [language]: task.name };
            delete task.name;
        }

        await updateTask(task).then(() => {
            if (selectedTaskId) {
                sendEvent(`updateTask_${task.id}`, task);
            }
        });
    };

    return (
        <Flow
            tasks={tasks}
            setTasks={setTasks}
            onAddTask={onTaskAdd}
            onAddLink={onLinkAdd}
            onDeleteLink={onLinkDelete}
            onDeleteTask={onTaskDelete}
            onUpdateTask={onUpdateTask}
            openTaskDrawer={onOpenTaskDrawer}
            fixedSize={fixedSize}
        />
    );
};
