import {
    DialogFullScreen,
    Type_props_DialogFullScreen,
} from "src/components/Components_Common/Dialog/DialogFullScreen";
import { MatrixAssignees } from "src/components/Components_Scheduling/Matrix/MatrixAssignees/MatrixAssignees";

type Type_Props_MatrixAssigneesFullScreen = Type_props_DialogFullScreen & {
    taskId: number;
};

export const MatrixAssigneesFullScreen = ({
    openDialog,
    setToggleDialog,
    title,
    taskId,
}: Type_Props_MatrixAssigneesFullScreen) => {
    return (
        <DialogFullScreen
            openDialog={openDialog}
            setToggleDialog={setToggleDialog}
            title={title}
        >
            <MatrixAssignees
                onClose={() => setToggleDialog(false)}
                taskId={taskId}
            />
        </DialogFullScreen>
    );
};
