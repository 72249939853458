import {
    Box,
    CircularProgressProps,
    CircularProgress as Mui_CircularProgress,
    Typography,
} from "@mui/material";

import { Styled_GridOverlay } from "./CircularProgress.style";

export const CircularProgress = (
    props: CircularProgressProps & { value: number },
) => {
    const { value, ...restProps } = props;
    return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
            <Mui_CircularProgress
                variant="determinate"
                value={Math.min(value, 100)} // Limite à 100%
                {...restProps}
            />
            <Box
                sx={{
                    position: "absolute",
                    inset: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.primary"
                >{`${Math.round(Math.min(value, 100))}%`}</Typography>
            </Box>
        </Box>
    );
};

type Type_Props_OverlayCircularProgress = {
    value: number;
};
export const OverlayCircularProgress = ({
    value,
}: Type_Props_OverlayCircularProgress) => {
    return (
        <Styled_GridOverlay>
            <CircularProgress value={value} />
        </Styled_GridOverlay>
    );
};
