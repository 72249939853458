import { Dayjs } from "dayjs";

import { Type_usr_select_company } from "src/api/tms-commons/companies/types";

//API
export type Type_usr_index_user = {
    id: number;
    email: string;
    firstName?: string;
    lastName?: string;
    phone: string;
    company: Type_usr_select_company;
    role: {
        id: number;
        name: string;
        roleType: {
            id: number;
            name: string;
            sortOrder: number;
        };
        enabled: boolean;
    };
    last_activity_date: string | null;
    status: number;
    origin: number;
    metadata: Record<string, string>;
    enabled: boolean;
};

export type Type_usr_index_userWithMeta = {
    data: Type_usr_index_user[];
    links: { first: string; last: string; next: string; prev: string };
    meta: {
        current_page: number;
        from: number;
        last_page: number;
        links: { url: string; label: string; active: boolean }[];
        per_page: number;
        to: number;
        total: number;
    };
};

export type Type_usr_post_user = {
    email: string;
    password?: string;
    status: number;
    origin: number;
    firstName?: string;
    lastName?: string;
    phone?: string | null;
    company_id?: number | null;
    role_id: number | null;
    metadata?: Record<string, string> | null;
    firstName_to_correct?: string | null;
    lastName_to_correct?: string | null;
    phone_to_correct?: string | null;
    metadata_to_correct?: Record<string, string> | null;
};

export type Type_usr_put_user = {
    email?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    status?: number;
    company_id?: number;
    metadata?: Record<string, string> | null;
    role_id?: number;
    enabled?: boolean;
    firstName_to_correct?: boolean;
    lastName_to_correct?: boolean;
    phone_to_correct?: boolean;
    metadata_to_correct?: Record<string, string> | null;
};

export type Type_usr_checkToken_user = {
    isValid: boolean;
    email: string;
    role_id: number;
    company_id: number | null;
};

//FRONT
export type Type_index_user = {
    id: number;
    email: string;
    name?: string;
    firstName?: string;
    lastName?: string;
    permissions?: string[];
    companyId?: number | null;
    phone: string;
    company: string;
    role: string;
    lastActivityDate: Dayjs | null;
    status: number;
    origin: number;
    metadata: Record<string, string>;
    enabled: boolean;
};

export type Type_index_userWithMeta = {
    users: Type_index_user[];
    total?: number;
};

export type Type_post_userInvitation = {
    email: string;
    status: number;
    origin: number;
    roleId: number;
};

export type Type_put_user = {
    id: number;
    email?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    companyId?: number;
    status?: number;
    metadata?: Record<string, string> | null;
    roleId?: number;
    enabled?: boolean;
    firstName_to_correct?: boolean;
    lastName_to_correct?: boolean;
    phone_to_correct?: boolean;
    metadata_to_correct?: Record<string, string> | null;
};

export type Type_metadataUser = {
    label: string;
    value: string | string[];
    isDate?: boolean;
};

export type Type_checkToken_user = {
    isValid: boolean;
    email: string;
    roleId: number;
    companyId: number | null;
};

export type Type_usr_selectList_user = {
    firstName: string;
    lastName: string;
    email: string;
};

//CONSTANTS
export enum USER_ORIGIN {
    SUBSCRIPTION = 1,
    INVITATION = 2,
}
export enum USER_STATUS {
    INVITED = 1,
    TO_VALIDATE = 2,
    VALIDATED = 3,
}
