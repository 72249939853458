import React, { useState } from "react";
import { ReactDnDPoc } from "src/components/Components_Common/DragAndDrop/ReactDnD.poc";
import { Box, FormControlLabel, Link, Stack, Switch } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";

import { Demo_Buttons } from "src/pages/Pages_Common/Page_Demo/components/buttons/Demo_Buttons";
import { KonvaDrawing } from "src/pages/Pages_Common/Page_Demo/components/canvas/KonvaDrawing";
import { Typography } from "@mui/material";
import { DaysCheckbox } from "src/components/Components_Common/Days/DaysCheckbox";
import { ResizableDrawer } from "src/layouts/LayoutDashboard/ResizableDrawer/ResizableDrawer";
import { Test_ContextualDrawer } from "src/pages/Pages_Common/Page_Demo/layouts/Test_ContextualDrawer";
import { FormikHandleChangeForm } from "src/pages/Pages_Common/Page_Demo/components/forms/FormikHandleChangeForm";
import { ReactHookFormSubmitOnBlur } from "src/pages/Pages_Common/Page_Demo/components/forms/ReactHookFormSubmitOnBlur";
import { ReactHookFormAutocompleteFreeSolo } from "src/pages/Pages_Common/Page_Demo/components/forms/ReactHookFormAutocompleteFreeSolo";
import { DaysBadge } from "src/components/Components_Common/Days/DaysBadge";
import { IconSelectDemo } from "src/pages/Pages_Common/Page_Demo/components/inputs/IconSelectDemo";
import { DialogFullScreenDemo } from "src/pages/Pages_Common/Page_Demo/components/DialogFullScreenDemo";
import { ColorPickerFormikDemo } from "src/pages/Pages_Common/Page_Demo/components/inputs/ColorPickerFormikDemo";
import { FormattedDateDemo } from "src/pages/Pages_Common/Page_Demo/components/FormattedDateDemo";
import { InputDrawingFileDemo } from "src/pages/Pages_Common/Page_Demo/components/inputs/inputDrawingFileDemo";
import { ThemeSwitcher } from "src/components";
import { DesignLayoutDemo } from "src/pages/Pages_Common/Page_Demo/layouts/DesignLayoutDemo";
import { SubProjectSelect } from "src/components/Components_Teamoty/SubProjectSelect/SubProjectSelect";
import { AutocompleteDemo } from "src/pages/Pages_Common/Page_Demo/components/inputs/AutocompleteDemo";
import { SectionTitle } from "src/components/Components_Common/SectionTitle";
import { TreeTable } from "src/components/Components_Common/TreeTable/TreeTable";
import { DialogDemo } from "./components/DialogDemo";
import { LayoutResizableDemo } from "src/pages/Pages_Common/Page_Demo/components/LayoutResizableDemo/LayoutResizableDemo";
import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";
import { HighCharts } from "./HighCharts/HighCharts";
import { CompleteButton } from "src/components/Components_Common/forms/reactHookFormComponents/CompleteButtonRhf/CompleteButtonRhf";
import { MatrixDemo } from "src/pages/Pages_Common/Page_Demo/components/matrix/MatrixDemo";
import { MatrixTreeDemo } from "src/pages/Pages_Common/Page_Demo/components/matrix/MatrixTreeDemo";
import { DataGridDemo } from "src/pages/Pages_Common/Page_Demo/components/DataGridDemo";
import {
    InputFile,
    Type_Resource,
} from "src/components/Components_Common/forms/InputFile";
import { DataGridSingleClickDemo } from "src/pages/Pages_Common/Page_Demo/components/DataGridSingleClickDemo";

export const Page_Demo = () => {
    const [show, setShow] = useState<{ [key: string]: boolean }>({});
    const [selectedSubProjectId, setSelectedSubProjectId] = useState<
        number | null
    >(1);
    const handleChange = (name: string, value: boolean) => {
        setShow((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    const columns: GridColDef[] = [
        {
            field: "team",
            headerName: "Team",
            flex: 1,
        },
    ];

    const data = [
        {
            id: 1,
            name: "Electricity",
            code: "ELEC",
            color: "#A3FF55",
            enabled: true,
            subProject: {
                id: 1,
                name: "Eiffel Tower",
                enabled: true,
            },
            subTrades: [
                {
                    id: 1,
                    code: "ELEC",
                    team: 2,
                    name: "Electrici",
                    trade_id: 1,
                    enabled: false,
                },
                {
                    id: 3,
                    code: "ELEC",
                    team: 2,
                    name: "Electric",
                    trade_id: 1,
                    enabled: true,
                    subTrades: [
                        {
                            id: 1,
                            code: "ELEC",
                            team: 2,
                            name: "Electrici",
                            trade_id: 1,
                            enabled: true,
                        },
                        {
                            id: 2,
                            code: "ELEC",
                            team: 2,
                            name: "Electric",
                            trade_id: 1,
                            enabled: true,
                        },
                    ],
                },
            ],
        },
        {
            id: 2,
            name: "Paint",
            code: "PAINT",
            color: "#A3FF55",
            enabled: true,
            subTrades: [
                {
                    id: 1,
                    code: "BLU",
                    team: 2,
                    name: "Blue paint",
                    trade_id: 1,
                    enabled: true,
                },
                {
                    id: 2,
                    code: "PNT",
                    team: 2,
                    name: "Paint",
                    trade_id: 1,
                    enabled: true,
                },
            ],
        },
        {
            id: 3,
            name: "Paint",
            code: "PAINT",
            color: "#A3FF55",
            enabled: true,
        },
    ]; // TODO: à supprimer, pour test PR AP-2961

    const [importDocx, setImportDocx] = useState<Type_Resource>({
        file: null,
        image: null,
        errors: false,
    });

    const demoList = [
        { label: "design", component: <DesignLayoutDemo /> },
        { label: "buttons", component: <Demo_Buttons /> },
        { label: "colorPicker", component: <ColorPickerFormikDemo /> },
        {
            label: "daysForm",
            component: (
                <Stack spacing={3}>
                    <DaysCheckbox
                        dataTestIdPrefix={"DaysForm"}
                        name={"daysForm"}
                    />
                    <DaysBadge />
                    <DaysBadge disableEdition />
                </Stack>
            ),
        },
        { label: "inputDrawingFile", component: <InputDrawingFileDemo /> },
        {
            label: "autocompleteWithCreate",
            component: <ReactHookFormAutocompleteFreeSolo />,
        },
        { label: "iconSelect", component: <IconSelectDemo /> },
        {
            label: "formikHandleChangeForm",
            component: <FormikHandleChangeForm />,
        },
        {
            label: "reactHookFormSubmitOnBlur",
            component: <ReactHookFormSubmitOnBlur />,
        },
        { label: "contextualDrawer", component: <Test_ContextualDrawer /> },
        {
            label: "resizableDrawer",
            component: (
                <Stack>
                    <Stack
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={{ width: "100%", height: "100%" }}
                    >
                        Page 1
                    </Stack>
                    <ResizableDrawer
                        name="data-test"
                        direction={"bottom"}
                        toolbar={<Typography>toolbar</Typography>}
                    >
                        <Stack
                            justifyContent={"center"}
                            alignItems={"center"}
                            sx={{ width: "100%", height: "100%" }}
                        >
                            Content
                        </Stack>
                    </ResizableDrawer>
                </Stack>
            ),
        },
        { label: "fullScreenDialog", component: <DialogFullScreenDemo /> },
        { label: "Dialog", component: <DialogDemo /> },
        {
            label: "layoutPertAreas",
            component: (
                <Stack
                    flexDirection={"row"}
                    width={"100%"}
                    height={500}
                    sx={{ border: "2px solid grey", top: "auto", bottom: 0 }}
                >
                    <Box sx={{ flexGrow: 1, p: 3 }}>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Rhoncus dolor purus non enim
                            praesent elementum facilisis leo vel. Risus at
                            ultrices mi tempus imperdiet. Semper risus in
                            hendrerit gravida rutrum quisque non tellus.
                            Convallis convallis tellus id interdum velit laoreet
                            id donec ultrices. Odio morbi quis commodo odio
                            aenean sed adipiscing. Amet nisl suscipit adipiscing
                            bibendum est ultricies integer quis. Cursus euismod
                            quis viverra nibh cras. Metus vulputate eu
                            scelerisque felis imperdiet proin fermentum leo.
                            Mauris commodo quis imperdiet massa tincidunt. Cras
                            tincidunt lobortis feugiat vivamus at augue.
                        </Typography>
                    </Box>
                </Stack>
            ),
        },
        { label: "dnd", component: <ReactDnDPoc /> },
        {
            label: "drawing",
            component: (
                <div className={`space-y-12`}>
                    <KonvaDrawing />
                </div>
            ),
        },
        { label: "formattedDate", component: <FormattedDateDemo /> },
        {
            label: "selectSubProject",
            component: (
                <div className={`space-y-12`}>
                    <SubProjectSelect
                        isFetching={false}
                        subProjects={[]}
                        setSelectedSubProjectId={setSelectedSubProjectId}
                        selectedSubProjectId={selectedSubProjectId as number}
                    />
                </div>
            ),
        },

        {
            label: "autocomplete",
            component: <AutocompleteDemo />,
        },
        {
            label: "SectionTitle",
            component: (
                <>
                    <SectionTitle
                        title={"Tests Demo"}
                        contents={["Content 1", "Content 2"]}
                    />
                    <SectionTitle
                        title={"Tests Demo"}
                        contents={["Content 1"]}
                    />
                </>
            ),
        },
        {
            label: "TreeTable",
            component: (
                <>
                    <TreeTable
                        treeData={true}
                        columns={columns}
                        groupHeaderName={"Trades"}
                        noDataConfig={{
                            isFetching: false,
                            dataName: "trades",
                            action: () => console.log("action"),
                        }}
                        page={"trades"}
                        data={data}
                        menuDropDown={{
                            callbackEdit: () => console.log("edit"),
                            callbackDuplicate: () => console.log("duplicate"),
                            callbackStatus: () => console.log("status"),
                            callbackDelete: () => console.log("delete"),
                        }}
                        hierarchy={"subTrades"}
                        groupingCellComponent={(row) => (
                            <TMC_Chip
                                label={`${row.props.code.toUpperCase()}`}
                                data-testid={`GroupingCellTrades-${row.name.replaceAll(
                                    " ",
                                    "_",
                                )}`}
                                backgroundColor={row.props.color}
                            />
                        )}
                    />
                </>
            ),
        },
        {
            label: "Matrix Simple",
            component: (
                <>
                    <MatrixDemo />
                </>
            ),
        },
        {
            label: "Matrix Tree ",
            component: (
                <>
                    <MatrixTreeDemo />
                </>
            ),
        },
        {
            label: "MUI DataGrid: Basic ",
            component: <DataGridDemo />,
        },
        {
            label: "MUI DataGrid: Single Click (DEPRECATED)",
            component: <DataGridSingleClickDemo />,
        },
        { label: "Layout resizable", component: <LayoutResizableDemo /> },
        {
            label: "Complete Button",
            component: (
                <div className={`space-y-12`}>
                    <CompleteButton
                        hideDropdownMenu={false}
                        completed={false}
                        size="small"
                        backgroundColor={"#FFF"}
                        handleCompleteStatus={() => {}}
                    />
                    <CompleteButton
                        hideDropdownMenu={false}
                        completed={true}
                        size="small"
                        backgroundColor={"#FFF"}
                        handleCompleteStatus={() => {}}
                    />
                    <CompleteButton
                        hideDropdownMenu={false}
                        completed={false}
                        size="medium"
                        backgroundColor={"#FFF"}
                        handleCompleteStatus={() => {}}
                    />
                    <CompleteButton
                        hideDropdownMenu={false}
                        completed={true}
                        size="medium"
                        backgroundColor={"#FFF"}
                        handleCompleteStatus={() => {}}
                    />
                    <CompleteButton
                        hideDropdownMenu={false}
                        completed={false}
                        size="large"
                        backgroundColor={"#FFF"}
                        handleCompleteStatus={() => {}}
                    />
                    <CompleteButton
                        hideDropdownMenu={false}
                        completed={true}
                        size="large"
                        backgroundColor={"#FFF"}
                        handleCompleteStatus={() => {}}
                    />
                    <Typography>Milestone or delivery</Typography>
                    <CompleteButton
                        hideDropdownMenu={true}
                        completed={false}
                        size="large"
                        backgroundColor={"#FFF"}
                        handleCompleteStatus={() => {}}
                    />
                </div>
            ),
        },
        { label: "High charts", component: <HighCharts /> },
        {
            label: "import docx",
            component: (
                <InputFile
                    acceptFormats={["docx"]}
                    state={importDocx}
                    setState={setImportDocx}
                    data-testid="Form-Drawing-Input-Drawing-File"
                />
            ),
        },
    ];

    return (
        <Box
            gap={4}
            sx={(theme) => ({
                backgroundColor: theme.palette.background.default,
                display: "flex",
                flexDirection: "column",
            })}
        >
            <ThemeSwitcher />

            <Link href="/en_GB/projects/slug1/1/demo">Page Demo Project</Link>

            <Box
                sx={{
                    p: 4,
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                }}
            >
                {demoList.map((item, index) => (
                    <FormControlLabel
                        key={index}
                        control={
                            <Switch
                                name={item.label}
                                checked={show[item.label] ?? false}
                                onChange={(e: any) =>
                                    handleChange(
                                        item.label!,
                                        e?.target?.checked,
                                    )
                                }
                            />
                        }
                        label={
                            <Typography variant="body1Bold" sx={{ px: 2 }}>
                                {item.label}
                            </Typography>
                        }
                    />
                ))}
            </Box>

            <Box
                sx={{
                    p: 4,
                    overflow: "auto",
                }}
            >
                {demoList.map(
                    (item, index) =>
                        show && show[item.label!] && <>{item.component}</>,
                )}
            </Box>
        </Box>
    );
};
