import { Stack, useTheme } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { RectReadOnly } from "react-use-measure";

import { useShowSequenceForFlow } from "src/api/tms-scheduling/sequences";
import { Type_show_sequence_forFlow } from "src/api/tms-scheduling/sequences/types";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { Type_flow } from "src/components/Components_Teamoty/Flow/Flow.type";
import { SequencePertDiagram } from "src/components/Components_Teamoty/SequencePert/SequencePertDiagram";
import { SequencePertToolbar } from "src/components/Components_Teamoty/SequencePert/SequencePertToolbar";

type Type_Props_SequencePertDrawer = {
    sequenceId: number;
    setShowPertDiagram: Dispatch<SetStateAction<boolean>>;
    bounds: RectReadOnly;
};

export const SequencePertDrawer = ({
    sequenceId,
    setShowPertDiagram,

    bounds,
}: Type_Props_SequencePertDrawer) => {
    const theme = useTheme();

    const { data, isFetching } = useShowSequenceForFlow(sequenceId, {
        forFlow: true,
    });

    const [sequenceForFlow, setSequenceForFlow] = useState<
        Type_show_sequence_forFlow | undefined
    >();
    const [tasksLinks, setTasksLinks] = useState<Type_flow>({
        tasks: [],
        links: [],
    });

    useEffect(() => {
        if (!isFetching) {
            if (data) {
                setSequenceForFlow(data);
                setTasksLinks({
                    tasks: data?.tasks || [],
                    links: data?.links || [],
                });
            }
        }
    }, [isFetching, sequenceId]);

    return (
        <>
            <Stack
                sx={{
                    width: bounds.width,
                    borderTop: "3px solid " + theme.palette.moonAlpha[200],
                }}
                data-testid="SequencePertDrawer-StackY"
            >
                <SequencePertToolbar
                    handleClose={() => setShowPertDiagram(false)}
                    sequenceId={sequenceId}
                    sequenceForFlow={sequenceForFlow}
                    setSequenceForFlow={setSequenceForFlow}
                    isFetching={isFetching}
                />
                <Stack
                    flexDirection={"row"}
                    sx={{
                        width: "100%",
                        height: bounds.height - 70,
                    }}
                    data-testid="SequencePertDrawer-StackX"
                >
                    {isFetching || sequenceForFlow === undefined ? (
                        <FullSpinner />
                    ) : (
                        <SequencePertDiagram
                            sequenceId={sequenceId}
                            fixedSize={sequenceForFlow?.fixedSize}
                            tasks={tasksLinks}
                            setTasks={setTasksLinks}
                        />
                    )}
                </Stack>
            </Stack>
        </>
    );
};
