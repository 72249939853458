import { planningDateClass } from "@cimba-digital-construction-solution/utils/dist/class/planningDateClass";
import { KonvaEventObject } from "konva/lib/Node";

export type Type_Props_PlanningMonthsDays = {
    days: planningDateClass[];
    months: planningDateClass[];
};

export const initialPlanningMonthsDays: Type_Props_PlanningMonthsDays = {
    days: [],
    months: [],
};

export type Type_Props_PlanningDates = {
    dates: Type_Props_PlanningMonthsDays;
    x: number;
    y: number;
    width: number;
    widthDate: number;
};

export type Type_Props_PlanningDate = {
    width: number;
    height: number;
    date: planningDateClass;
    handleContextMenuDates?: (
        event: KonvaEventObject<PointerEvent>,
        date?: planningDateClass,
    ) => void;
};

export type Type_Props_PlanningMonth = Type_Props_PlanningDate & {
    dates: Type_Props_PlanningMonthsDays;
    x: number;
};
