import { Stack, Typography } from "@mui/material";
import React, { ComponentProps, useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { useSelectListResources } from "src/api/tms-projects/resources";
import { formatterSelectListResources } from "src/api/tms-projects/resources/formatters";
import { Autocomplete } from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";

type Type_Props_AutocompleteResourceGeneric = Omit<
    ComponentProps<typeof Autocomplete>,
    "options" | "isFetching"
> & {
    desc?: string;
    resourceType?: {
        id: number;
        name: string;
        enabled: boolean;
    };
};

export const AutocompleteResourcesGeneric = ({
    name,
    label,
    desc,
    resourceType,
    ...props
}: Type_Props_AutocompleteResourceGeneric) => {
    const { isFetching, data: resources = [] } = useSelectListResources() || {};

    const { setValue, control } = useFormContext();

    const [resource] = useWatch({
        control,
        name: ["resource"],
    });

    const filteredResources = useMemo(() => {
        if (!resourceType || !resources.length) return [];
        return resources.filter(
            ({ resourceType: { id } }) => id === resourceType.id,
        );
    }, [resources, resourceType]);

    useEffect(() => {
        const item = resources.find(({ id }) => id === resource?.id);
        if (resourceType?.id !== item?.resourceType?.id)
            setValue(name, undefined);
    }, [resource]);

    return (
        <Stack width={"100%"} flexDirection={"column"}>
            <Autocomplete
                name={name}
                label={label}
                isFetching={isFetching}
                options={formatterSelectListResources(filteredResources)}
                {...props}
            />
            {desc && (
                <Typography
                    variant={"body2"}
                    color={"sectionTitle.contrastText"}
                >
                    {desc}
                </Typography>
            )}
        </Stack>
    );
};
