import {
    DialogFullScreen,
    Type_props_DialogFullScreen,
} from "src/components/Components_Common/Dialog/DialogFullScreen";
import { MatrixAreas } from "src/components/Components_Scheduling/Matrix/MatrixAreas/MatrixAreas";

type Type_Props_MatrixAreasFullScreen = Type_props_DialogFullScreen & {
    page: string;
};

export const MatrixAreasFullScreen = ({
    openDialog,
    setToggleDialog,
    title,
    page,
}: Type_Props_MatrixAreasFullScreen) => {
    return (
        <DialogFullScreen
            openDialog={openDialog}
            setToggleDialog={setToggleDialog}
            title={title}
        >
            <MatrixAreas onClose={() => setToggleDialog(false)} page={page} />
        </DialogFullScreen>
    );
};
