import {
    ButtonGroup,
    ClickAwayListener,
    Grow,
    IconButton,
    MenuList,
    Paper,
    Popper,
} from "@mui/material";
import { IconButtonProps } from "@mui/material/IconButton/IconButton";
import MenuItem from "@mui/material/MenuItem";
import React, {
    Dispatch,
    Fragment,
    SetStateAction,
    useRef,
    useState,
} from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_option = { id: number; name: string; value: string };

type Type_Props_CtaSplitButtonHandleMatrix = {
    setModalMatrix: Dispatch<SetStateAction<false | string>>;
    tooltipTitle: string;
    options: Type_option[];
    size?: IconButtonProps["size"];
};

export const CtaSplitButtonHandleMatrix = ({
    setModalMatrix,
    tooltipTitle,
    options,
    size = "small",
}: Type_Props_CtaSplitButtonHandleMatrix) => {
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    const handleClick = () => {
        setModalMatrix(options[0].value);
    };

    const handleMenuItemClick = (option: Type_option) => {
        setModalMatrix(option.value);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    return (
        <Fragment>
            <ButtonGroup
                variant="text"
                ref={anchorRef}
                aria-label="Button group with a nested menu"
            >
                <IconButton
                    data-testid={`Matrix-Cta-Btn-${options[0].value}`}
                    size={size}
                    title={tooltipTitle}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleClick();
                    }}
                    color="inherit"
                >
                    <Icon variant="light" icon="table" fontSize={size} />
                </IconButton>
                <IconButton
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="menu"
                    data-testid={`Matrix-Cta-Dropdown-${options[0].value}`}
                    size={"small"}
                    title={fmtActions("MoreOptions")}
                    onClick={handleToggle}
                    color="inherit"
                >
                    <Icon
                        variant="solid"
                        icon="caret-down"
                        fontSize={"small"}
                    />
                </IconButton>
            </ButtonGroup>
            <Popper
                sx={{ zIndex: 1 }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement={"bottom-end"}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom-end"
                                    ? "right top"
                                    : "right bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {options.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            data-testid={`Matrix-Cta-Dropdown-Options-${option.id}`}
                                            onClick={() =>
                                                handleMenuItemClick(option)
                                            }
                                        >
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Fragment>
    );
};
