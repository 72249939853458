import {
    DialogFullScreen,
    Type_props_DialogFullScreen,
} from "src/components/Components_Common/Dialog/DialogFullScreen";
import { MatrixSubTrades } from "src/components/Components_Scheduling/Matrix/MatrixSubTrades/MatrixSubTrades";

type Type_Props_MatrixSubTradesFullScreen = Type_props_DialogFullScreen & {
    page: string;
};
export const MatrixSubTradesFullScreen = ({
    page,
    openDialog,
    setToggleDialog,
    title,
}: Type_Props_MatrixSubTradesFullScreen) => {
    return (
        <DialogFullScreen
            openDialog={openDialog}
            setToggleDialog={setToggleDialog}
            title={title}
        >
            <MatrixSubTrades
                page={page}
                onClose={() => setToggleDialog(false)}
            />
        </DialogFullScreen>
    );
};
