import { GridColDef, GridRowModel } from "@mui/x-data-grid-premium";
import React from "react";
import { QueryObserverResult } from "react-query";

import {
    Matrix,
    Type_Props_MemoizedDataGridPremium,
} from "src/components/Components_Common/matrix/Matrix";

const rows: GridRowModel[] = [
    {
        path: "Zone1",
        name: "Zone 1",
        color: "red",
        areaType: "Batiment",
        id: 1,
    },
    {
        path: "Zone1/Zone1.1",
        name: "Zone 1.1",
        color: "green",
        areaType: "Appartement",
        id: 2,
    },
    {
        path: "Zone1/Zone1.1/Zone1.1.1",
        name: "Zone 1.1.1",
        color: "blue",
        areaType: "Chambre",
        id: 3,
    },
    {
        path: "Zone1/Zone1.1/Zone1.1.2",
        name: "Zone 1.1.2",
        color: "blue",
        areaType: "Salle de bain",
        id: 4,
    },
    {
        path: "Zone1/Zone1.1/Zone1.1.3",
        name: "Zone 1.1.3",
        color: "blue",
        areaType: "Salon",
        id: 5,
    },
    {
        path: "Zone1/Zone1.2",
        name: "Zone 1.2",
        color: "white",
        areaType: "Appartement",
        id: 6,
    },
];

const columns: GridColDef[] = [
    {
        field: "name",
        headerName: "Area éditable",
        flex: 1,
        editable: true,
    },
    {
        field: "color",
        headerName: "Color non éditable",
        flex: 1,
    },
    {
        field: "areaType",
        headerName: "Area type éditable",
        flex: 1,
        editable: true,
    },
];

export const MatrixDemo = () => {
    const mockMutateUpdate: Type_Props_MemoizedDataGridPremium["mutateUpdate"] =
        async () => {
            return Promise.resolve({
                data: {},
            });
        };

    const mockRefetch: any = async () => {
        return Promise.resolve({
            data: [
                { id: 1, name: "Row 1" },
                { id: 2, name: "Row 2" },
            ],
            error: null,
            status: "success",
            isLoading: false,
            isError: false,
            isSuccess: true,
            isFetching: false,
            failureCount: 0,
            refetch: () => Promise.resolve({} as QueryObserverResult<any, any>),
        });
    };

    return (
        <Matrix
            columns={columns}
            rows={rows}
            mutateUpdate={mockMutateUpdate}
            refetch={mockRefetch}
        />
    );
};
