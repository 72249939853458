import { merge } from "lodash";
import {
    QueryClient,
    useMutation,
    useQuery,
    useQueryClient,
} from "react-query";

import { Type_RequestConfig } from "src/api/fetch";
import { ProgressHistoryKeys, TaskAreaKeys } from "src/api/tms-scheduling/keys";
import {
    formatterCreateTaskArea,
    formatterCreateTaskAreaForMatrix,
    formatterIndexTaskAreas,
    formatterIndexTaskAreasForMatrix,
    formatterProgressTaskArea,
    formatterShowTaskArea,
} from "src/api/tms-scheduling/taskArea/formatters";
import {
    createTaskArea,
    indexTaskAreas,
    setProgressTaskArea,
    showTaskArea,
} from "src/api/tms-scheduling/taskArea/services";
import {
    Type_post_taskArea,
    Type_progress_taskArea,
    Type_sch_index_taskArea,
    Type_sch_show_taskArea,
} from "src/api/tms-scheduling/taskArea/types";
import { Type_taskArea_forMatrix_dataTaskArea } from "src/components/Components_Scheduling/Matrix/helpers/MatrixTaskAreasHelper";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { Type_event_taskAreaProgress, useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexTaskAreas = (areaId: number, taskId: number) => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [TaskAreaKeys.INDEX, requestConfig, areaId, taskId],
        queryFn: ({ signal }) =>
            indexTaskAreas(
                {
                    ...requestConfig,
                    areaId: areaId,
                    taskId: taskId,
                },
                signal,
            ),
        enabled: !!requestConfig.projectId && !!requestConfig.subProjectId,
        refetchOnWindowFocus: false,
        select: (data) => {
            return formatterIndexTaskAreas(
                data?.data?.data as Type_sch_index_taskArea[],
            );
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
    });
};

export const useIndexTaskAreasForMatrix = (taskId: number) => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [TaskAreaKeys.INDEX_FOR_MATRIX, requestConfig, taskId],
        queryFn: () =>
            indexTaskAreas(
                {
                    ...requestConfig,
                    taskId: taskId,
                },
                undefined,
                { forMatrix: true },
            ),
        enabled:
            !!requestConfig.projectId &&
            !!requestConfig.subProjectId &&
            !!taskId,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: useIndexTaskAreasForMatrix",
                );
            }

            return formatterIndexTaskAreasForMatrix(data?.data?.data);
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
    });
};

export const useShowTaskArea = (
    taskId: number,
    areaId: number,
    taskAreaId: number | null,
    staleTime = 0,
) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [
            TaskAreaKeys.SHOW,
            requestConfig,
            taskAreaId,
            taskId,
            areaId,
        ],
        queryFn: ({ signal }) =>
            showTaskArea(
                { ...requestConfig, taskId: taskId, areaId: areaId },
                taskAreaId,
                signal,
            ),
        enabled: !!requestConfig.projectId && !!requestConfig.subProjectId,
        refetchOnWindowFocus: false,
        select: (data) => {
            return formatterShowTaskArea(
                data?.data?.data as Type_sch_show_taskArea,
            );
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
        staleTime,
    });
};

export const useShowTaskGantt = (taskAreaId: number, staleTime = 0) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [TaskAreaKeys.SHOW, requestConfig, taskAreaId, null, null], // should have same format as useShowTaskArea, because use same update and setQueryCache
        queryFn: ({ signal }) =>
            showTaskArea({ ...requestConfig }, taskAreaId, signal),
        enabled: !!requestConfig.projectId && !!requestConfig.subProjectId,
        refetchOnWindowFocus: false,
        select: (data) => {
            return formatterShowTaskArea(
                data?.data?.data as Type_sch_show_taskArea,
            );
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
        staleTime,
    });
};

/**
 * Create OR Update a taskArea
 */
export const mutationUpsertTaskArea = (withData = true) => {
    const { requestConfig } = useProject();
    const { sendEvent } = useChannel({});
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (taskArea: Type_post_taskArea) =>
            createTaskArea(formatterCreateTaskArea(taskArea), {
                ...requestConfig,
                withData: withData,
                taskId: taskArea.taskId,
                areaId: taskArea.areaId,
                taskAreaId: taskArea.id,
            }),
        onSuccess: (data, variables): void => {
            if (!data?.success) {
                throw new Error("Wrong format data: mutationUpsertTaskArea");
            }
            /**
             * Lorsque l'on charge le formulaire la toute premiere fois, on n'a pas de taskAreaId.
             * la clé de cache est donc constituée de taskId, AreaId et taskAreaId: null
             *
             * Lors de la réponse de l'api, on récupère le taskAreaId et on met à jour le formulaire
             * les prochains appels se font donc avec la clé de cache: taskId, AreaId, taskAreaId
             *
             * On doit donc mettre à jour les deux clés de cache pour éviter d'avoir des données obsolètes
             */
            const cacheKey = [
                TaskAreaKeys.SHOW,
                requestConfig,
                variables?.id ?? data?.data?.data?.id,
                variables?.taskId,
                variables?.areaId,
            ];
            const cacheKey2 = [
                TaskAreaKeys.SHOW,
                requestConfig,
                null,
                variables?.taskId,
                variables?.areaId,
            ];

            queryClient.setQueryData(cacheKey, (oldData) => {
                const mergeData = mergeCacheData(oldData, data, variables);
                console.debug(
                    `CACHE: update key ${cacheKey} on mutationUpsertTaskArea`,
                    oldData,
                    mergeData,
                );
                return mergeData;
            });
            queryClient.setQueryData(cacheKey2, (oldData) => {
                const mergeData = mergeCacheData(oldData, data, variables);
                console.debug(
                    `CACHE: update key ${cacheKey2} on mutationUpsertTaskArea`,
                    oldData,
                    mergeData,
                );
                return mergeData;
            });

            sendEvent("postTaskArea");
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};

/**
 * Create OR Update a taskArea
 */
export const mutationUpsertTaskAreaForMatrix = (taskId: number) => {
    const { requestConfig } = useProject();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (data: {
            id: number;
            data: Type_taskArea_forMatrix_dataTaskArea;
        }) => {
            return createTaskArea(formatterCreateTaskAreaForMatrix(data.data), {
                ...requestConfig,
                withData: false,
                areaId: data.id,
                taskId: taskId,
                taskAreaId: data.data.taskAreaId,
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};

/**
 * Update progress value of a taskArea
 */
export const mutationSetProgressTaskArea = () => {
    const { requestConfig } = useProject();
    const { sendEvent } = useChannel({});
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (progressTaskArea: Type_progress_taskArea) =>
            setProgressTaskArea(formatterProgressTaskArea(progressTaskArea), {
                ...requestConfig,
                withData: false,
                ...(progressTaskArea.taskId !== null && {
                    taskId: progressTaskArea.taskId,
                }),
                ...(progressTaskArea.areaId !== null && {
                    areaId: progressTaskArea.areaId,
                }),
                ...(progressTaskArea.taskAreaId !== null && {
                    taskAreaId: progressTaskArea.taskAreaId,
                }),
            }),
        onSuccess: (data, variables): void => {
            if (!data?.success) {
                throw new Error(
                    "Wrong format data: mutationSetProgressTaskArea",
                );
            }

            const taskAreaId = data?.data?.data?.id;

            /**
             * Lorsque l'on charge le formulaire la toute premiere fois, on n'a pas de taskAreaId.
             * la clé de cache est donc constituée de taskId, AreaId et taskAreaId: null
             *
             * Lors de la réponse de l'api, on récupère le taskAreaId et on met à jour le formulaire
             * les prochains appels se font donc avec la clé de cache: taskId, AreaId, taskAreaId
             *
             * On doit donc mettre à jour les deux clés de cache pour éviter d'avoir des données obsolètes
             */
            const cacheKey = [
                TaskAreaKeys.SHOW,
                requestConfig,
                variables?.taskAreaId ?? data?.data?.data?.id,
                variables?.taskId,
                variables?.areaId,
            ];
            const cacheKey2 = [
                TaskAreaKeys.SHOW,
                requestConfig,
                null,
                variables?.taskId,
                variables?.areaId,
            ];

            queryClient.setQueryData(cacheKey, (oldData) => {
                const mergeData = mergeCacheData(oldData, data, variables);
                console.debug(
                    `CACHE: update key ${cacheKey} on mutationSetProgressTaskArea`,
                    oldData,
                    mergeData,
                );
                return mergeData;
            });
            queryClient.setQueryData(cacheKey2, (oldData) => {
                const mergeData = mergeCacheData(oldData, data, variables);
                console.debug(
                    `CACHE: update key ${cacheKey2} on mutationSetProgressTaskArea`,
                    oldData,
                    mergeData,
                );
                return mergeData;
            });

            queryClient.invalidateQueries([
                ProgressHistoryKeys.INDEX,
                requestConfig,
                taskAreaId,
            ]);
            sendEvent(
                "progressTaskArea" + variables.origin,
                variables as Type_event_taskAreaProgress,
            );
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};

//////////////////////////////////////////////
///     Utils                              ///
//////////////////////////////////////////////

/**
 * Update taskAreaShow cache
 * @deprecated should be replace by mergeCacheData()
 */
export const updateTaskAreaIdOnShowCache = (
    taskAreaId: number,
    taskId: number,
    areaId: number,
    requestConfig: Type_RequestConfig,
    queryClient: QueryClient,
) => {
    queryClient.setQueryData(
        [TaskAreaKeys.SHOW, requestConfig, null, taskId, areaId],
        (oldData: any) => {
            if (!oldData)
                return {
                    data: {
                        data: {
                            id: taskAreaId,
                        },
                    },
                };
            // Must have the same props as the 'success' function of the response useShowTask
            return {
                ...oldData,
                data: {
                    ...oldData.data,
                    data: {
                        ...oldData.data.data,
                        id: taskAreaId,
                    },
                },
            };
        },
    );
};

const mergeCacheData = (oldData?: any, data?: any, variables?: any) => {
    // Deep merge the data objects
    const oldTaskArea = oldData?.data?.data || {};
    const newTaskArea = data?.data?.data || {};
    const mergedDataObject = merge(oldTaskArea, newTaskArea, variables);

    // Determine the ID to use
    const id = variables?.id ?? newTaskArea?.id;

    return {
        ...oldData,
        data: {
            ...oldData?.data,
            ...data?.data,
            data: {
                ...mergedDataObject,
                id,
            },
        },
    };
};
////////////////////////////////////////////
