import { Box } from "@mui/material";

import {
    DialogFullScreen,
    Type_props_DialogFullScreen,
} from "src/components/Components_Common/Dialog/DialogFullScreen";
import { MatrixSequences } from "src/components/Components_Scheduling/Matrix/MatrixSequences/MatrixSequences";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_sequencesForMatrixFullScreen = {
    id: number;
    label: string;
};

type Type_Props_MatrixSequencesFullScreen = Omit<
    Type_props_DialogFullScreen,
    "title"
>;

export const MatrixSequencesFullScreen = ({
    openDialog,
    setToggleDialog,
}: Type_Props_MatrixSequencesFullScreen) => {
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");

    return (
        <DialogFullScreen
            openDialog={openDialog}
            setToggleDialog={setToggleDialog}
            title={fmtTableColumn("SequencesTitle")}
        >
            <Box sx={{ flexGrow: 1, mx: 4 }}>
                <MatrixSequences />
            </Box>
        </DialogFullScreen>
    );
};
