import React, {
    createContext,
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Type_index_view } from "src/api/tms-projects/workspacesAndViews/views/types";
import { Type_show_workspace } from "src/api/tms-projects/workspacesAndViews/workspaces/types";
import { useProject } from "src/contexts/project";

export type Type_workspaceViewContext = {
    currentWorkspace: Type_show_workspace | undefined | null;
    setCurrentWorkspace: Dispatch<
        SetStateAction<Type_show_workspace | undefined | null>
    >;

    currentView: Type_index_view | undefined | null;
    setCurrentView: Dispatch<
        SetStateAction<Type_index_view | undefined | null>
    >;

    handleChangeTab: (viewId: number) => void;
    updateView: (view: Type_index_view) => void;
};

const WorkspaceViewContext = createContext<
    Type_workspaceViewContext | undefined
>(undefined);

export const useWorkspaceViewContext = () => {
    const context = useContext(WorkspaceViewContext);
    if (!context) {
        throw new Error(
            "useWorkspaceViewContext must be used within a WorkspaceViewProvider",
        );
    }
    return context;
};

type Type_Props_WorkspaceViewProvider = {
    children: JSX.Element | JSX.Element[];
};

export const WorkspaceViewProvider = ({
    children,
}: Type_Props_WorkspaceViewProvider) => {
    // Router
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { view: viewIdParams } = useParams();
    const { isLoading, workspace } = useProject();

    ////////////////////////////////////////
    ///         State                    ///
    ////////////////////////////////////////

    const [currentWorkspace, setCurrentWorkspace] =
        useState<Type_show_workspace | null>();
    const [currentView, setCurrentView] = useState<Type_index_view | null>();

    ////////////////////////////////////////
    ///         Handle changes           ///
    ////////////////////////////////////////

    const handleChangeTab = (viewId: number) => {
        if (currentWorkspace) {
            const newView = currentWorkspace.views.find(
                (view) => view.id === viewId,
            );
            if (newView && newView.id !== currentView?.id) {
                setCurrentView(newView);
            }
        }
    };

    const updateView = (updatedView: Type_index_view) => {
        if (!currentWorkspace) return;

        const updatedViews = currentWorkspace.views.map((view) =>
            view.id === updatedView.id ? updatedView : view,
        );

        setCurrentWorkspace((workspace) => {
            if (workspace) {
                return { ...workspace, views: updatedViews };
            }
            return workspace;
        });

        if (currentView?.id === updatedView.id) {
            setCurrentView(updatedView);
        }
    };

    ////////////////////////////////////////
    ///         Effects                  ///
    ////////////////////////////////////////

    // Watch project context changement
    useEffect(() => {
        if (!isLoading && workspace) {
            setCurrentWorkspace(workspace[0] as Type_show_workspace);
        }
    }, [isLoading, workspace]);

    // Watch view changement
    useEffect(() => {
        if (currentView && pathname.includes("myteamoty")) {
            const workspaceUrl = "/workspace";
            const tabLocation = pathname.split(workspaceUrl)[0];
            const newUrl = `${tabLocation}${workspaceUrl}/${currentView.id}`;

            if (newUrl !== pathname) {
                navigate(newUrl, { replace: true }); // Replace the current history entry to avoid redundant navigation
            }
        }
    }, [currentView, pathname, navigate]);

    // Watch workspace changement
    useEffect(() => {
        if (currentWorkspace) {
            const viewFromUrlParam = currentWorkspace.views.find(
                (viewItem) => viewItem.id == parseInt(viewIdParams || "0"),
            );
            setCurrentView(viewFromUrlParam ?? currentWorkspace.views[0]);
        }
    }, [currentWorkspace]);

    return (
        <WorkspaceViewContext.Provider
            value={{
                currentWorkspace,
                setCurrentWorkspace,

                currentView,
                setCurrentView,

                handleChangeTab,
                updateView,
            }}
        >
            {children}
        </WorkspaceViewContext.Provider>
    );
};
