import { ListSubheader, MenuItem, TextField } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import {
    GridRenderEditCellParams,
    useGridApiContext,
} from "@mui/x-data-grid-premium";
import { ChangeEvent } from "react";
import * as React from "react";

import { Type_SelectOptionItem } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";

export type Type_Props_SelectEditCell = GridRenderEditCellParams & {
    options: Type_SelectOptionItem[];
    inputProps?: TextFieldProps;
};

// Composant select générique
// @see https://mui.com/x/react-data-grid/editing/#with-auto-stop
export const SelectEditCell = (props: Type_Props_SelectEditCell) => {
    const { id, value, field, options, inputProps } = props;
    const apiRef = useGridApiContext();

    const handleChange = async (
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
        await apiRef.current.setEditCellValue({
            id,
            field,
            value: event?.target?.value,
        });
        apiRef.current.stopCellEditMode({ id, field });
    };

    return (
        <TextField
            id="standard-select"
            select
            defaultValue="none"
            variant="standard"
            value={value}
            onChange={handleChange}
            autoFocus
            SelectProps={{
                defaultOpen: true,
            }}
            {...inputProps}
        >
            {options.map((option, index) => {
                const elements: React.ReactNode[] = [];
                if (option.optionGroupLabel) {
                    elements.push(
                        <ListSubheader key={`subheader-${index}`} disableSticky>
                            {option.optionGroupLabel}
                        </ListSubheader>,
                    );
                }
                elements.push(
                    <MenuItem
                        key={option.value}
                        value={option.value}
                        data-testid={`Select-MenuItem-${field}-${option.value}`}
                    >
                        {option.label}
                    </MenuItem>,
                );
                return elements;
            })}
        </TextField>
    );
};
