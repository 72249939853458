import {
    Type_index_peopleTaskArea,
    Type_post_peopleTaskArea,
    Type_sch_index_peopleTaskArea,
    Type_sch_index_peopleTaskArea_forMatrix,
    Type_sch_post_peopleTaskArea,
} from "src/api/tms-scheduling/peopleTaskArea/types";
import {
    Type_sch_index_taskArea_forMatrix_area,
    Type_sch_taskArea_forMatrix_path,
} from "src/api/types";
import { formatterMatrix_taskAreas } from "src/components/Components_Scheduling/Matrix/helpers/formatters";
import { Type_taskArea_forMatrix_area } from "src/components/Components_Scheduling/Matrix/helpers/MatrixTaskAreasHelper";

export const formatterIndexPeopleTaskAreas = (
    peopleTaskAreas: Type_sch_index_peopleTaskArea[],
): Type_index_peopleTaskArea[] => {
    return peopleTaskAreas.map(
        (peopleTaskArea: Type_sch_index_peopleTaskArea) =>
            formatterIndexPeopleTaskArea(peopleTaskArea),
    );
};

export const formatterIndexPeopleTaskArea = (
    peopleTaskArea: Type_sch_index_peopleTaskArea,
): Type_index_peopleTaskArea => {
    return {
        peopleTaskId: peopleTaskArea.id,
        taskAreaId: peopleTaskArea.task_area_id,
        id: peopleTaskArea.people.id,
        firstName: peopleTaskArea.people.firstName,
        lastName: peopleTaskArea.people.lastName,
        email: peopleTaskArea.people.email,
        userId: peopleTaskArea.people.user_id,
    };
};

export const formatterFormPeopleTaskArea = (
    data: Type_post_peopleTaskArea,
): Type_sch_post_peopleTaskArea => {
    return {
        people_id: data.peopleId,
    };
};

const formatterIndexPeopleTaskAreaForMatrixArea = (
    area: Type_sch_index_taskArea_forMatrix_area,
): Type_taskArea_forMatrix_area => {
    return {
        id: area.area_id,
        deep: area.area_deep,
        order: area.order,
        path: area.path.map((level: Type_sch_taskArea_forMatrix_path) => {
            return {
                areaName: level.area_name,
                areaTypeName: level.areaType_name,
                deep: level.deep,
            };
        }),
    };
};

export const formatterIndexPeopleTaskAreasForMatrix = (
    data: Type_sch_index_peopleTaskArea_forMatrix,
): any => {
    // TODO: typage 4670
    return {
        areas: data.areas?.map(formatterIndexPeopleTaskAreaForMatrixArea) || [],
        task: [],
        taskAreas: formatterMatrix_taskAreas<any>(
            data.taskAreasPeople,
            "people",
            "people_id",
        ),
    };
};
