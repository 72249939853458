import { defaultPrintSettings } from "src/api/tms-print/print/types";
import { Enum_typeView } from "src/api/tms-projects/workspacesAndViews/views/types";
import { Type_default_workspace } from "src/api/tms-projects/workspacesAndViews/workspaces/types";

export enum workspaceType {
    TEAMOTY = "teamoty",
    METHOD = "method",
    SITE_MANAGER = "siteManager",
}

// get default configuration
export const getDefaultSubprojectConfiguration = () => {
    const defaultSubprojectWorkspaces: Map<string, Type_default_workspace> =
        new Map();

    defaultSubprojectWorkspaces.set(workspaceType.TEAMOTY, <
        Type_default_workspace
    >{
        name: "Subproject.Teamoty.Title",
        description: "Subproject.Teamoty.Description",
        type: "teamoty",
        current: true,
        views: [
            {
                name: "View.Planning",
                order: 1,
                data: {
                    version: 1,
                    type: Enum_typeView.planning,
                    settings: {
                        print: defaultPrintSettings,
                    },
                },
            },
            {
                name: "View.Gantt",
                order: 2,
                data: {
                    version: 1,
                    type: Enum_typeView.gantt,
                    settings: {
                        print: defaultPrintSettings,
                    },
                },
            },
            {
                name: "View.Workflow",
                order: 3,
                data: { version: 1, type: Enum_typeView.workflow },
            },
            {
                name: "View.Dashboard",
                order: 4,
                data: {
                    version: 1,
                    type: Enum_typeView.dashboard,
                    settings: {
                        widgets: ["graphs"],
                    },
                },
            },
        ],
    });

    defaultSubprojectWorkspaces.set(workspaceType.METHOD, <
        Type_default_workspace
    >{
        name: "Subproject.Method.Title",
        description: "Subproject.Method.Description",
        type: "method",
        current: false,
        views: [
            {
                name: "View.Planning",
                order: 1,
                data: {
                    version: 1,
                    type: Enum_typeView.planning,
                    settings: {
                        print: defaultPrintSettings,
                    },
                },
            },
            {
                name: "View.Grid",
                order: 2,
                data: { version: 1, type: Enum_typeView.grid },
            },
        ],
    });

    defaultSubprojectWorkspaces.set(workspaceType.SITE_MANAGER, <
        Type_default_workspace
    >{
        name: "Subproject.SiteManager.Title",
        description: "Subproject.SiteManager.Description",
        type: "siteManager",
        current: false,
        views: [
            {
                name: "View.Dashboard",
                order: 1,
                data: {
                    version: 1,
                    type: Enum_typeView.dashboard,
                    settings: {
                        widgets: ["graphs"],
                    },
                },
            },
            {
                name: "View.Planning",
                order: 2,
                data: {
                    version: 1,
                    type: Enum_typeView.planning,
                    settings: {
                        print: defaultPrintSettings,
                    },
                },
            },
            {
                name: "View.Gantt",
                order: 3,
                data: {
                    version: 1,
                    type: Enum_typeView.gantt,
                    settings: {
                        print: defaultPrintSettings,
                    },
                },
            },
            {
                name: "View.Workflow",
                order: 4,
                data: { version: 1, type: Enum_typeView.workflow },
            },
        ],
    });

    return defaultSubprojectWorkspaces;
};
