import { Box } from "@mui/material";

import {
    DialogFullScreen,
    Type_props_DialogFullScreen,
} from "src/components/Components_Common/Dialog/DialogFullScreen";
import { MatrixTaskAreasTask } from "src/components/Components_Scheduling/Matrix/MatrixTaskAreasTask/MatrixTaskAreasTask";

type Type_Props_MatrixTaskAreasTaskFullScreen = Type_props_DialogFullScreen & {
    taskId?: number;
};

export const MatrixTaskAreasTaskFullScreen = ({
    openDialog,
    setToggleDialog,
    title,
    taskId,
}: Type_Props_MatrixTaskAreasTaskFullScreen) => {
    return (
        <DialogFullScreen
            openDialog={openDialog}
            setToggleDialog={setToggleDialog}
            title={title}
        >
            <Box sx={{ flexGrow: 1, mx: 4 }}>
                {taskId && (
                    <MatrixTaskAreasTask
                        onClose={() => setToggleDialog(false)}
                        taskId={taskId}
                    />
                )}
            </Box>
        </DialogFullScreen>
    );
};
