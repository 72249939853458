import { yupResolver } from "@hookform/resolvers/yup";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { canvastoDataURL, EImageType } from "image-conversion";
import * as React from "react";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Cropper, CropperRef, ImageRestriction } from "react-advanced-cropper";
import { useQueryClient } from "react-query";
import * as Yup from "yup";

import { DrawingPath } from "src/api/paths";
import {
    mutationCreateDrawing,
    mutationUpdateDrawing,
} from "src/api/tms-projects/drawing";
import {
    Type_prj_post_drawing,
    Type_prj_put_drawing,
    Type_show_drawing,
} from "src/api/tms-projects/drawing/types";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import {
    emptyResource,
    InputFile,
    Type_Resource,
} from "src/components/Components_Common/forms/InputFile";
import { InputSliderTextSizeRhf } from "src/components/Components_Common/forms/reactHookFormComponents/InputSliderTextSizeRhf/InputSliderTextSizeRhf";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { ToggleButtonGroup } from "src/components/Components_Common/forms/reactHookFormComponents/ToggleButtonGroup/ToggleButtonGroup";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useToast } from "src/contexts/toasts";
import { TmcFormProvider, useTmcForm } from "src/hooks/tests/useTmcForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import "react-advanced-cropper/dist/style.css";

export type Type_Props_DrawingForm = {
    onClose: () => void;
    drawingImage: Type_Resource;
    drawingIdToUpdate?: number | null;
    drawing?: Type_show_drawing;
    enabledCrop?: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    action: Type_action;
    isFetching: boolean;
};

export type Type_DrawingImageCrop = Type_Resource & {
    isDrawingImageUpdated?: boolean;
    isCropEnabled?: boolean;
};

const colorSettings = ["grayscale", "colorful"];

const Schema_Drawing = Yup.object().shape({
    name: Yup.string().required(FORM_ERR_FMT.REQUIRED),
    colorSetting: Yup.string()
        .trim()
        .required(FORM_ERR_FMT.REQUIRED)
        .oneOf(colorSettings, FORM_ERR_FMT.REQUIRED),
});

export const DrawingForm = ({
    onClose,
    drawingImage,
    drawing,
    enabledCrop,
    setIsLoading,
    action,
    isFetching,
}: Type_Props_DrawingForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Drawing");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const [drawingImageCrop, setDrawingImageCrop] =
        useState<Type_DrawingImageCrop>({
            ...drawingImage,
            isCropEnabled: enabledCrop,
        });

    const { add } = useToast();
    const queryClient = useQueryClient();

    const cropperRef = useRef<CropperRef>(null);

    // Create mutation
    const { mutateAsync: mutateCreate } = mutationCreateDrawing() || {};

    // Update mutation
    const { mutateAsync: mutateUpdate } = mutationUpdateDrawing(
        drawing?.id as number,
    );

    const form = useTmcForm({
        defaultValues: {
            name: "",
            colorSetting: drawing?.grayscale ? "grayscale" : "colorful",
            textSize: 4,
        },
        values: drawing,
        resolver: yupResolver<any>(Schema_Drawing),
        onClose,
        setIsLoading,
        disableDirtyCheck: true,
    });

    const handleOnError = (): void => {
        add({
            type: "warning",
            description: fmtErr("GenericError"),
        });
    };

    const handleSubmit = async (
        values: (Type_show_drawing | Type_prj_post_drawing) & {
            colorSetting: string;
        },
    ) => {
        if (action === "create") {
            // call api create
            const data: Type_prj_post_drawing = {
                name: values.name,
                grayscale: values.colorSetting === "grayscale", // TODO replace by boolean
                textSize: values.textSize,
                image: await getCroppedImage(),
            };
            mutateCreate(data, {
                onSuccess: (): void => {
                    add({
                        type: "success",
                        description: fmt("CallApi.Create.ToastSuccess"),
                    });
                    onClose();
                },
                onError: handleOnError,
            });
        } else if (action === "update") {
            // call api update
            const updateData: Type_prj_put_drawing = {
                name: values.name,
                grayscale: values.colorSetting === "grayscale", // TODO replace by boolean
                textSize: values.textSize,
            };
            if (drawingImageCrop.isDrawingImageUpdated) {
                if (!drawingImageCrop.isCropEnabled) {
                    updateData.image = drawingImageCrop.image?.src;
                } else {
                    // Get image from crop if crop enabled
                    updateData.image = await getCroppedImage();
                }
            }

            mutateUpdate(updateData, {
                onSuccess: async () => {
                    add({
                        type: "success",
                        description: fmt("CallApi.Update.ToastSuccess"),
                    });
                    // Force refetch drawimg image
                    await queryClient.invalidateQueries({
                        queryKey: [DrawingPath.DRAWINGS, "image", drawing?.id],
                    });
                    // reset state
                    setDrawingImageCrop((prev) => ({
                        ...prev,
                        file: null,
                        isCropEnabled: false,
                        isDrawingImageUpdated: false,
                    }));
                    onClose();
                },
                onError: handleOnError,
            });
        }
    };

    const handleResetImage = () => {
        setDrawingImageCrop((prev) => ({
            ...prev,
            ...emptyResource,
        }));
    };

    // user has upload a new image, drawing image file change
    useEffect(() => {
        if (drawingImageCrop.file) {
            // Update name field when file change if name is empty (creation only)
            if (form.getValues("name") === "") {
                form.setValue("name", drawingImageCrop.file.name);
            }
            // enabled crop
            setDrawingImageCrop((prev) => ({
                ...prev,
                isCropEnabled: true,
                isDrawingImageUpdated: true,
            }));

            form.reset({
                name: drawing?.name,
                colorSetting: drawing?.grayscale ? "grayscale" : "colorful",
                textSize: drawing?.textSize,
            });
        }
    }, [drawingImageCrop.file]);

    const handleCropChange = () => {
        // update state to know that image crop change
        if (!drawingImageCrop.isDrawingImageUpdated) {
            setDrawingImageCrop((prev) => ({
                ...prev,
                isDrawingImageUpdated: true,
            }));
        }
    };

    const getCroppedImage = async () => {
        return await canvastoDataURL(
            cropperRef.current?.getCanvas() as HTMLCanvasElement,
            100,
            EImageType.PNG,
        );
    };

    return (
        <TmcFormProvider form={form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={"drawings"}>
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <>
                        <Stack rowGap={6}>
                            <Stack
                                direction={"row"}
                                justifyContent="space-between"
                            >
                                <Typography
                                    variant="h3"
                                    data-testid="Form-Drawing-SectionFileTitle"
                                >
                                    {fmt("SectionFileTitle")}
                                </Typography>
                                {drawingImageCrop.image !== null && (
                                    <IconButton
                                        onClick={handleResetImage}
                                        data-testid="Form-Drawing-Image-Delete-Btn"
                                    >
                                        <Icon
                                            variant={"regular"}
                                            icon={"trash"}
                                        />
                                    </IconButton>
                                )}
                            </Stack>
                            {drawingImageCrop.image === null && (
                                <InputFile
                                    acceptFormats={["png", "jpg", "pdf"]}
                                    state={drawingImageCrop}
                                    setState={
                                        setDrawingImageCrop as Dispatch<
                                            SetStateAction<Type_Resource>
                                        >
                                    }
                                    data-testid="Form-Drawing-Input-Drawing-File"
                                />
                            )}
                            {drawingImageCrop.image &&
                                drawingImageCrop.isCropEnabled && (
                                    <Cropper
                                        ref={cropperRef}
                                        src={drawingImageCrop.image.src}
                                        imageRestriction={
                                            ImageRestriction.stencil
                                        }
                                        onChange={handleCropChange}
                                    />
                                )}
                            {drawingImageCrop.image &&
                                !drawingImageCrop.isCropEnabled && (
                                    <Box
                                        sx={(theme) => ({
                                            p: 2,
                                            border: theme.border.default,
                                            borderRadius:
                                                theme?.shape?.borderRadiusSmall,
                                        })}
                                    >
                                        <img
                                            src={drawingImageCrop.image.src}
                                            alt="drawing image"
                                            style={{
                                                width: "100%",
                                                height: "auto",
                                                display: "block",
                                                maxWidth: "100%",
                                                objectFit: "contain",
                                            }}
                                        />
                                    </Box>
                                )}

                            <Typography
                                variant="h3"
                                data-testid="Form-Drawing-SectionSettings"
                            >
                                {fmt("SectionSettings")}
                            </Typography>

                            <TextField
                                data-testid="Form-Drawing-Input-Name"
                                label={fmt(`Fields.DrawingName`)}
                                name="name"
                            />
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Typography variant="body1">
                                    {fmt("Fields.ColorSetting")}
                                </Typography>
                                <ToggleButtonGroup
                                    name="colorSetting"
                                    exclusive
                                    items={colorSettings.map((name) => ({
                                        label: fmt(
                                            `Fields.ColorSettings.${name}`,
                                        ),
                                        value: name,
                                    }))}
                                />
                            </Box>

                            <InputSliderTextSizeRhf
                                data-testid="Form-Drawing-Toggle-TextSize"
                                label={fmt("Fields.AdjustTextSize")}
                                name="textSize"
                            />
                        </Stack>
                    </>
                )}
            </form>
        </TmcFormProvider>
    );
};
