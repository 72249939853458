import { Typography } from "@mui/material";
import React, { MouseEvent } from "react";

import { Button } from "src/components/Components_Common/_MuiComponentsVariants/Button/Button.component";
import { Icon } from "src/components/Components_Common/Icon/Icon";

import { StyledEmpty } from "./Empty.style";

type Type_Props_Empty = {
    dataTestIdRef: string;
    message?: string;
    title?: string;
    actionMessage?: string;
    onClick?: (e: MouseEvent) => void;
    sx?: object;
    icon?: boolean;
};

export const Empty = ({
    message,
    title,
    actionMessage,
    sx,
    icon = false,
    onClick = () => {},
    dataTestIdRef,
}: Type_Props_Empty) => {
    return (
        <StyledEmpty
            justifyContent={"center"}
            alignItems={"center"}
            sx={sx}
            gap={2}
            data-testid={`${dataTestIdRef}-Empty`}
        >
            {icon && (
                <Icon
                    variant={"solid"}
                    icon={"empty-set"}
                    color={"secondary"}
                    sx={{ color: "text.secondary" }}
                    data-testid={`${dataTestIdRef}-Empty-icon`}
                />
            )}
            {title && (
                <Typography
                    color={"text.secondary"}
                    variant="h3"
                    data-testid={`${dataTestIdRef}-Empty-title`}
                >
                    {title}
                </Typography>
            )}
            {message && (
                <Typography
                    color={"text.secondary"}
                    variant="body2"
                    data-testid={`${dataTestIdRef}-Empty-message`}
                >
                    {message}
                </Typography>
            )}
            {actionMessage && (
                <Button
                    onClick={onClick}
                    color="primary"
                    data-testid={`${dataTestIdRef}-Empty-button`}
                >
                    <Typography
                        color={"inherit"}
                        variant="body3Medium"
                        data-testid={`${dataTestIdRef}-Empty-button-message`}
                    >
                        {actionMessage}
                    </Typography>
                </Button>
            )}
        </StyledEmpty>
    );
};
