import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import {
    getFilterJoin,
    getParamsJoin,
    Type_Filter_Areas,
    Type_Params,
} from "src/api/filters";
import { TasksPath, Url } from "src/api/paths";
import {
    Type_sch_post_task,
    Type_sch_put_task,
} from "src/api/tms-scheduling/tasks/types";

export const indexTasks = (
    requestConfig: Type_RequestConfig,
    filters: Type_Filter_Areas = {},
    params: Type_Params = {},
): GetReturnType => {
    const urlFilters: string = getFilterJoin(filters);
    const urlParams: string = getParamsJoin(params, urlFilters);

    return get(
        `${Url.SCHEDULING}${TasksPath.TASKS}${urlFilters}${urlParams}`,
        requestConfig,
    );
};

export const showTask = (
    id: number,
    requestConfig: Type_RequestConfig,
    signal?: AbortSignal,
): GetReturnType => {
    return get(
        `${Url.SCHEDULING}${TasksPath.TASKS}/${id}`,
        requestConfig,
        null,
        signal,
    );
};

export const createTask = (
    data: Type_sch_post_task,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(data, `${Url.SCHEDULING}${TasksPath.TASKS}`, requestConfig);
};

export const updateTask = (
    id: number,
    data: Type_sch_put_task,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.SCHEDULING}${TasksPath.TASKS}/${id}`,
        requestConfig,
    );
};

export const removeTask = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(`${Url.SCHEDULING}${TasksPath.TASKS}/${id}`, requestConfig);
};
