import * as React from "react";
import { useState } from "react";

import { useShowTaskGantt } from "src/api/tms-scheduling/taskArea";
import { Type_show_taskArea } from "src/api/tms-scheduling/taskArea/types";
import { LoadingBox } from "src/components";
import { MEDIUM_STALE_TIME } from "src/configurations/app";
import { GanttTaskForm } from "src/forms/tasks/GanttTaskForm";
import {
    Type_event_idsTaskArea,
    Type_event_message,
    useChannel,
} from "src/hooks/useChannel";
import { Styled_PaperContextualLayout } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

//////////////////////////////////////////////

type Type_Props_GanttTaskContextualDrawer = {
    id: number; // TaskAreaId
};

export const GanttTaskContextualDrawer = ({
    id: taskAreaId,
}: Type_Props_GanttTaskContextualDrawer) => {
    const [id, setId] = useState<number>(taskAreaId);

    useChannel({
        eventHandler: (data: Type_event_message): void => {
            if (data.event == "updateIdsTaskArea") {
                setId(
                    (data?.data as Type_event_idsTaskArea).taskAreaId as number,
                );
            }
        },
    });

    const { isLoading, data } = useShowTaskGantt(id, MEDIUM_STALE_TIME);

    return (
        <Styled_PaperContextualLayout
            id="ganttTask"
            data-testid="GanttTaskContextualDrawer"
        >
            {isLoading ? (
                <LoadingBox />
            ) : (
                data && (
                    <GanttTaskForm data={data || ({} as Type_show_taskArea)} />
                )
            )}
        </Styled_PaperContextualLayout>
    );
};
