import * as React from "react";
import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import {
    Type_post_taskArea,
    Type_show_taskArea,
} from "src/api/tms-scheduling/taskArea/types";
import { CompleteButtonRhf } from "src/components/Components_Common/forms/reactHookFormComponents/CompleteButtonRhf/CompleteButtonRhf";
import { HeaderToolbar } from "src/components/Components_Teamoty/contextualDrawers/HeaderToolbar/HeaderToolbar";
import { Type_Props_HeaderForm } from "src/forms/tasks/HeaderForm/HeaderForm";
import { NameForm } from "src/forms/tasks/NameForm/NameForm";
import { shouldDisplay } from "src/forms/tasks/utils";
import { Header } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { invertColor } from "src/utils/colors";

type Type_Props_GanttHeaderForm = Omit<Type_Props_HeaderForm, "data"> & {
    data: Type_show_taskArea;
    onSubmit: (values: Type_post_taskArea) => void;
};

export const GanttHeaderForm = ({
    data,
    onClose,
    onRefresh,
    onSubmit,
    readonly,
    modalMatrix,
}: Type_Props_GanttHeaderForm) => {
    const { control } = useFormContext();

    ///////////////////////////////////////
    ///         Task header color       ///
    ///////////////////////////////////////

    const [taskColor, withTradeColor, subTrade, taskType] = useWatch({
        name: ["color", "withTradeColor", "subTrade", "type"],
        control: control,
    });

    const headerBackgroundColor = useMemo(() => {
        const isWithTradeColorEnabled = shouldDisplay({
            name: "withTradeColor",
            taskType: Number(taskType),
        });

        return withTradeColor && subTrade && isWithTradeColorEnabled
            ? subTrade.trade?.color
            : taskColor;
    }, [withTradeColor, subTrade, taskColor, taskType]);

    return (
        <Header
            data-testid="GanttTask-Header"
            ownerState={{ borderBottom: true }}
            sx={{
                backgroundColor: headerBackgroundColor,
                color: invertColor(headerBackgroundColor),
            }}
        >
            <HeaderToolbar
                dataTestIdRef="GanttTask-Header-Toolbar"
                onClose={onClose}
                notes={"taskNotes"}
                taskId={data?.id}
                notesCount={data?.notesCount}
                onRefresh={onRefresh}
                modalMatrix={modalMatrix}
            >
                <CompleteButtonRhf
                    name="progressValue"
                    task={data}
                    taskType={data.type!}
                    backgroundColor={data.realColor}
                    onSubmit={onSubmit}
                />
            </HeaderToolbar>
            <NameForm
                id={data.id}
                headerBackgroundColor={headerBackgroundColor}
                readonly={readonly}
            />
        </Header>
    );
};
