import {
    Box,
    AppBar as MuiAppBar,
    Toolbar as MuiToolbar,
    Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { LeftToolbar } from "src/components/Components_Teamoty/views/ViewPlanning/toolbar/LeftToolbar";
import { RightToolbar } from "src/components/Components_Teamoty/views/ViewPlanning/toolbar/RightToolbar";

////////////////////////////////////////////
// --- STYLES                             //
////////////////////////////////////////////

export const Styled_AppBar = styled(MuiAppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    borderBottom: theme.border.default,
}));

export const ToolbarSection = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    alignItems: "center",
    color: theme.palette.text.primary,
    gap: theme.spacing(2),
}));

export const PlanningToolbar = () => {
    const handleAddTaskClick = () => {
        console.log("add task click");
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Styled_AppBar position="static" elevation={0}>
                <MuiToolbar variant="dense">
                    <LeftToolbar handleAddTaskClick={handleAddTaskClick} />
                    <Box sx={{ flexGrow: 1 }} />
                    <RightToolbar />
                </MuiToolbar>
            </Styled_AppBar>
        </Box>
    );
};
