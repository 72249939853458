import { get, GetReturnType, post, Type_RequestConfig } from "src/api/fetch";
import { getParamsJoin, Type_Params } from "src/api/filters";
import { SequenceArea, Url } from "src/api/paths";

import { Type_sch_post_sequenceArea } from "./types";

export const indexSequenceAreas = (
    requestConfig: Type_RequestConfig,
    params: Type_Params = {},
): GetReturnType => {
    const urlParams: string = getParamsJoin(params);

    return get(
        `${Url.SCHEDULING}${SequenceArea.SEQUENCE_AREAS}${urlParams}`,
        requestConfig,
    );
};

export const createOrUpdateMultipleSequenceAreas = (
    data: Type_sch_post_sequenceArea[],
    requestConfig: Type_RequestConfig,
) => {
    return post(
        { areas: data },
        `${Url.SCHEDULING}${SequenceArea.SEQUENCE_AREAS}`,
        requestConfig,
    );
};
