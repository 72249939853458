import {
    Divider,
    FormControlLabel,
    Stack,
    Switch,
    Typography,
} from "@mui/material";
import {
    gridFilteredSortedRowIdsSelector,
    GridToolbarContainer,
    GridValidRowModel,
    useGridApiRef,
} from "@mui/x-data-grid-premium";
import _ from "lodash";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { UseMutateAsyncFunction } from "react-query";

import {
    mutationCreateOrUpdateMultipleSequenceAreas,
    useIndexSequenceAreasForMatrix,
} from "src/api/tms-scheduling/sequenceAreas";
import { TMC_Button } from "src/components";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import {
    getDifferentAttributes,
    Matrix,
} from "src/components/Components_Common/matrix/Matrix";
import {
    formatAreasForTable,
    getMaxDepth,
    getMaxOrder,
    Type_Matrix_SequenceArea_Row,
} from "src/components/Components_Scheduling/Matrix/helpers/MatrixSequenceAreasHelpers";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    getLocalStorageItem,
    setLocalStorageItem,
} from "src/utils/localStorageServices";

import { MatrixSequenceAreasColumns } from "./MatrixSequenceAreasColumns";

type Type_Props_TableSequenceAreas = {
    sequenceId: number;
    onClose: () => void;
};

export const MatrixSequenceAreas = ({
    sequenceId,
    onClose,
}: Type_Props_TableSequenceAreas) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Sequences.Table",
    );

    const apiRef = useGridApiRef();

    const localStorageMatrixOrderKey = `matrix_sequenceareas_${sequenceId}_manual_order`;

    const isManualOrder =
        getLocalStorageItem(localStorageMatrixOrderKey) || false;

    const [areas, setAreas] = useState<Type_Matrix_SequenceArea_Row[]>([]);
    const [maxDepth, setMaxDepth] = useState<number>(0);

    const [showSelected, setShowSelected] = useState<boolean>(false);
    const [manualOrder, setManualOrder] = useState<boolean>(isManualOrder);

    const [gridDataLayer, setGridDataLayer] = useState<GridValidRowModel[]>([]);

    // Fetch Sequence/Area Order
    const { data: sequenceAreasData, isFetching: isFetchingSequenceAreas } =
        useIndexSequenceAreasForMatrix(sequenceId, true);

    const { mutateAsync } = mutationCreateOrUpdateMultipleSequenceAreas(
        sequenceId,
        true,
    );

    useEffect(() => {
        if (sequenceAreasData) {
            setMaxDepth(getMaxDepth(sequenceAreasData));
            const formattedAreas = formatAreasForTable(sequenceAreasData);

            if (
                formattedAreas.some(
                    (area) => area.order !== null && area.order !== undefined,
                )
            ) {
                setManualOrder(true);
            }
            setAreas(formattedAreas);
        }
    }, [sequenceAreasData]);

    const areaSeqColumns = MatrixSequenceAreasColumns({
        depth: maxDepth,
        enableOrderEdition: manualOrder,
    });

    /**
     * Function to handle row changes in the matrix sequence areas
     * @param { Type_Matrix_SequenceArea_Row[] } updatedRows
     * @param { string } columnKey
     * @param { boolean } autoOrder
     * @param { Type_Matrix_SequenceArea_Row[] } _areas
     * @returns {Promise<void>}
     */
    const onRowsChange = (
        updatedRows: Type_Matrix_SequenceArea_Row[],
        columnKey: string,
        autoOrder: boolean = false,
        _areas: any[] = [],
    ): void => {
        // Find the maximum order value in the areas
        let maxOrder = autoOrder ? 0 : getMaxOrder(areas);

        // Update an existing sequence area with a specified order
        const updateSequenceArea = (
            row: Type_Matrix_SequenceArea_Row,
            order: number,
        ) => {
            row.order = order;
            // Update the local state of areas with the new order
            setAreas((prev) =>
                prev.map((area) => {
                    if (area.id === row.id) {
                        return {
                            ...area,
                            selected: true,
                            order: +order,
                        };
                    }
                    return area;
                }),
            );
        };

        const selectSequenceArea = (
            row: Type_Matrix_SequenceArea_Row,
            selected: boolean,
        ) => {
            row.selected = selected;
            setAreas((prev) =>
                prev.map((area) => {
                    if (area.id === row.id) {
                        return {
                            ...area,
                            selected,
                        };
                    }
                    return area;
                }),
            );
        };

        // Process each updated row in the list
        for (const row of updatedRows) {
            const rowOrder = row.order;

            const selectedColumn = columnKey === "selected";
            const orderColumn = columnKey === "order";

            // Handle selection column updates
            if (selectedColumn) {
                // If the row is selected
                if (row.selected) {
                    if (autoOrder) {
                        maxOrder = maxOrder + 1;
                        updateSequenceArea(row, maxOrder);
                    } else if (manualOrder) {
                        maxOrder = maxOrder + 1;
                        updateSequenceArea(row, maxOrder);
                    } else {
                        setAreas((prev) =>
                            prev.map((area) => {
                                if (area.id === row.id) {
                                    return {
                                        ...area,
                                        selected: true,
                                        order: null,
                                    };
                                }
                                return area;
                            }),
                        );
                    }
                } else if (!row.selected) {
                    // If the row is unselected, adjust the order
                    if (rowOrder) {
                        row.order = null;
                        setAreas((prev) =>
                            prev.map((area) => {
                                if (area.order && area.order > rowOrder) {
                                    return { ...area, order: +area.order - 1 };
                                }
                                if (area.id === row.id) {
                                    return { ...area, order: null };
                                }
                                return area;
                            }),
                        );
                    } else {
                        setAreas((prev) =>
                            prev.map((area) => {
                                if (area.id === row.id) {
                                    return {
                                        ...area,
                                        selected: false,
                                    };
                                }
                                return area;
                            }),
                        );
                    }
                }
            } else if (orderColumn && manualOrder) {
                // Handle order column updates with manual ordering
                maxOrder = getMaxOrder(_areas || []);
                if (rowOrder == null || isNaN(+rowOrder)) {
                    selectSequenceArea(row, false);
                } else {
                    selectSequenceArea(row, true);

                    const orderAlreadyExist = _areas.find(
                        (area) => area.order == rowOrder,
                    );

                    if (orderAlreadyExist) {
                        if (orderAlreadyExist.id === row.id) {
                            return;
                        }

                        // Check if current cell is empty or has an order
                        const prevOrder = _areas.find(
                            (area) => area.id === row.id,
                        ).order;

                        // Swap orders if the cell already contains an order
                        if (prevOrder) {
                            updateSequenceArea(row, +rowOrder);
                            updateSequenceArea(orderAlreadyExist, prevOrder);
                        } else {
                            // Create and update the sequence area and order

                            for (const _area of areas) {
                                if (_area.order && _area.order >= rowOrder) {
                                    updateSequenceArea(_area, +_area.order + 1);
                                }
                            }
                            updateSequenceArea(row, +rowOrder);
                        }
                    } else if (row.order) {
                        const currentArea = _areas.find(
                            (area) => area.id == row.id,
                        );

                        if (currentArea.order) {
                            // Adjust order if the current area order matches max order
                            if (currentArea.order == maxOrder) {
                                _areas.map((area) => {
                                    if (area.id === row.id) {
                                        row.order = maxOrder;
                                        area.order = maxOrder;
                                    }
                                    return area;
                                });
                            } else if (rowOrder > maxOrder) {
                                row.order = maxOrder;
                                const areaWithMaxOrder = _areas.findLast(
                                    (area) => area.order === maxOrder,
                                );
                                updateSequenceArea(row, maxOrder);
                                if (areaWithMaxOrder) {
                                    updateSequenceArea(
                                        areaWithMaxOrder,
                                        currentArea.order,
                                    );
                                }
                            }
                        } else {
                            // Set order to max + 1 if no current order exists
                            updateSequenceArea(row, maxOrder + 1);
                        }
                    }
                }
            }
        }
    };

    /**
     * Toggles the selection of areas.
     * If 'showSelected' is true, shows only selected areas, otherwise shows all areas.
     * @returns {void}
     */
    const toggleSelection = (): void => {
        setShowSelected(!showSelected);
    };

    /**
     * This function establishes an incremental order for selected areas.
     * @returns {void}
     */
    const autoOrder = (): void => {
        const filteredRows = getFilteredRows()?.filter(
            (area): area is Type_Matrix_SequenceArea_Row => !!area, // Type guard to ensure no `undefined` values
        );

        const onlySelectedArea = filteredRows.filter((area) => area.selected);

        onRowsChange(onlySelectedArea, "selected", true);
        const gridDataForMatrix = onlySelectedArea.map(
            ({ id, selected, order }) => ({
                id,
                selected,
                order,
            }),
        );
        setGridDataLayer(gridDataForMatrix);
    };

    const getFilteredRows = () => {
        if (!apiRef.current) return [];
        const filteredSortedIds = gridFilteredSortedRowIdsSelector(apiRef);
        // Sort areas to match the order of filteredSortedIds
        const sortedAreas = filteredSortedIds
            .map((id) => areas.find((area) => area.id === id))
            .filter(Boolean); // Remove any `undefined` values in case of mismatch

        return sortedAreas;
    };

    const isAutoOrderEnabled = (): boolean => {
        const filteredRows = getFilteredRows();
        // Check if all selected rows are in the filtered rows
        const isAllSelectedRowsVisible = areas
            .filter((area) => area.selected) // Get all selected rows
            .every((selectedRow) =>
                filteredRows?.some(
                    (filteredRow) => filteredRow?.id === selectedRow.id,
                ),
            );

        return manualOrder && isAllSelectedRowsVisible;
    };

    const switchManualOrder = () => {
        setManualOrder(!manualOrder);
        setLocalStorageItem(localStorageMatrixOrderKey, !manualOrder);

        if (manualOrder) {
            setAreas((prev) => {
                const updatedAreas = prev.map((area) => {
                    if (area.selected) {
                        return {
                            ...area,
                            order: null, // Set order to null for selected areas
                        };
                    }
                    return area; // Leave other areas unchanged
                });

                const gridDataForMatrix = updatedAreas
                    .filter((area) => area.selected)
                    .map(({ id, selected }) => ({
                        id,
                        selected,
                    }));

                setGridDataLayer(gridDataForMatrix);

                return updatedAreas; // Update the state with modified areas
            });
        }
    };

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer sx={{ mb: 3, px: 6 }}>
                <Stack
                    justifyContent={"flex-end"}
                    display={"flex"}
                    width="100%"
                    direction="row"
                    alignItems="center"
                    spacing={2}
                >
                    <IconButton
                        data-testid={`AreaSequence_Matrix_${showSelected ? "hide" : "show"}_btn`}
                        onClick={toggleSelection}
                        size="small"
                    >
                        <Stack
                            spacing={1}
                            direction={"row"}
                            alignItems={"center"}
                        >
                            <Icon
                                icon={showSelected ? "eye-slash" : "eye"}
                                variant="light"
                                fontSize="medium"
                            />
                        </Stack>
                    </IconButton>

                    {/* ManualSelection & AutoOrder */}
                    <Stack direction="row" alignItems={"center"} spacing={2}>
                        <FormControlLabel
                            control={
                                <Switch
                                    sx={{ mr: 2 }}
                                    checked={manualOrder}
                                    onChange={() => switchManualOrder()}
                                    data-testid="AreasSequence_Matrix_manual_switch"
                                />
                            }
                            label="Manuel order"
                        />

                        <Divider
                            textAlign="center"
                            orientation="vertical"
                            variant="middle"
                            flexItem
                        />

                        <TMC_Button
                            data-testid="AreaSequence_Matrix_AutoOrder_btn"
                            onClick={() => autoOrder()}
                            disabled={!isAutoOrderEnabled()}
                            variant="text"
                            sx={{ color: "text.primary" }}
                        >
                            <Stack
                                direction="row"
                                alignItems={"center"}
                                spacing={2}
                            >
                                <Icon
                                    variant="light"
                                    icon={"wand-magic-sparkles"}
                                />
                                <Typography variant="body1">
                                    {fmt("AutoOrder")}
                                </Typography>
                            </Stack>
                        </TMC_Button>
                    </Stack>
                </Stack>
            </GridToolbarContainer>
        );
    };

    const onRowUpdate = (
        newRow: GridValidRowModel,
        oldRow: GridValidRowModel,
    ): GridValidRowModel => {
        const diff = getDifferentAttributes(newRow, oldRow);
        const updatedRows = filteredAreasRows.findLast(
            (item) => item.id === diff.id,
        );

        const _areas = _.cloneDeep(areas);

        if (updatedRows) {
            if ("selected" in diff) {
                updatedRows.selected = diff.selected;
                onRowsChange([updatedRows], "selected", false, _areas);
            }
            if ("order" in diff) {
                updatedRows.order = diff.order;
                onRowsChange([updatedRows], "order", false, _areas);
            }

            const { selected, order, id } = areas.findLast(
                (item) => item.id === newRow.id,
            ) as Type_Matrix_SequenceArea_Row;

            return { selected, order, id } as GridValidRowModel;
        }
        return {};
    };

    const filteredAreasRows = useMemo(() => {
        return showSelected ? areas.filter(({ selected }) => selected) : areas;
    }, [areas, showSelected]);

    const mutateUpdate = () => {
        if (!manualOrder) {
            return mutateAsync(
                areas
                    .filter((area) => area.selected)
                    .map((area) => ({ area_id: area.id, order: null })),
            );
        } else {
            return mutateAsync(
                areas
                    .filter((area) => area.order)
                    .map((area) => ({ area_id: area.id, order: area.order })),
            );
        }
    };

    const handleSave = async (
        _gridData: GridValidRowModel[],
        setProgressBarValue: Dispatch<SetStateAction<number>>,
        mutateUpdate: UseMutateAsyncFunction<any, any, any, unknown>,
    ) => {
        return await mutateUpdate({}).then(() => {
            setProgressBarValue(100);
            onClose();
        });
    };

    return (
        <Stack mt={5} spacing={5}>
            <Matrix
                apiRef={apiRef}
                loading={isFetchingSequenceAreas}
                processRowUpdateAdditionalBehaviors={onRowUpdate}
                handleSave={handleSave}
                gridDataLayer={gridDataLayer}
                mutateUpdate={mutateUpdate}
                columns={areaSeqColumns}
                rows={filteredAreasRows}
                disableColumnSorting={false}
                slots={{ toolbar: CustomToolbar }}
                headerFilters
            />
        </Stack>
    );
};
