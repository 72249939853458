import { Box, Slider, Stack, Typography } from "@mui/material";
import { SliderProps } from "@mui/material/Slider/Slider";
import React, { forwardRef } from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";
import {
    Type_DataTestIdComponent,
    Type_ReadonlyComponent,
} from "src/components/Components_Common/types";

export type Type_Props_InputSliderTextSize = SliderProps &
    Type_ReadonlyComponent &
    Type_DataTestIdComponent & {
        label?: string;
        readonly?: boolean;
    };

export const InputSliderTextSize = forwardRef<
    HTMLInputElement,
    Type_Props_InputSliderTextSize
>(({ label, readonly = false, ...props }, ref) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            {label && <Typography variant={"body1"}>{label}</Typography>}
            <Stack direction="row" alignItems="center" gap={2}>
                <Icon
                    variant="solid"
                    icon="text"
                    fontSize="small"
                    sx={{ ml: 2 }}
                />
                <Slider
                    defaultValue={4}
                    disabled={readonly || props.disabled}
                    ref={ref}
                    step={1}
                    min={1}
                    max={7}
                    valueLabelDisplay="auto"
                    id={"textSize"}
                    sx={{ width: 250 }}
                    marks
                    {...props}
                />
                <Icon variant="solid" icon="text" fontSize="large" />
            </Stack>
        </Box>
    );
});

InputSliderTextSize.displayName = "InputSliderTextSize";
