import {
    Type_sch_index_taskAreaProductTypesMatrix_taskAreasProductType,
    Type_sch_index_taskAreaProductTypesMatrix_taskProductType,
} from "src/api/tms-scheduling/taskAreaProductTypes/types";
import {
    Type_sch_index_taskAreaResource_forMatrix_taskAreasResource,
    Type_sch_index_taskAreaResource_forMatrix_taskResource,
} from "src/api/tms-scheduling/taskAreaResources/types";
import {
    Type_index_dataColumns,
    Type_index_taskAreaMatrix_task,
    Type_index_taskAreaMatrix_taskArea,
} from "src/components/Components_Scheduling/Matrix/helpers/types";

export const formatterMatrix_dataColumns = <
    T extends
        | Type_sch_index_taskAreaProductTypesMatrix_taskProductType
        | Type_sch_index_taskAreaResource_forMatrix_taskResource,
>(
    data: T,
    fieldName: string,
    keyId: keyof T,
    headerSuffix?: keyof T,
): Type_index_dataColumns => {
    return {
        id: data[keyId] as number,
        field: `${fieldName}_${data[keyId]}`,
        headerName: `${data.name} ${headerSuffix ? `(${data[headerSuffix]})` : ""}`,
    };
};

export const formatterMatrix_taskAreas = <
    T extends
        | Type_sch_index_taskAreaProductTypesMatrix_taskAreasProductType
        | Type_sch_index_taskAreaResource_forMatrix_taskAreasResource,
>(
    data: T[] | null,
    fieldName: string,
    keyId: keyof T,
) => {
    const taskAreas: Type_index_taskAreaMatrix_taskArea[] = [];
    if (data) {
        data.forEach((item) => {
            const elementIndex: number = taskAreas.findIndex(
                (area) => area.areaId === item.area_id,
            );
            if (elementIndex > -1) {
                taskAreas[elementIndex] = {
                    ...taskAreas[elementIndex],
                    [`${fieldName}_${item[keyId]}`]: item.quantity,
                };
            } else {
                taskAreas.push({
                    [`${fieldName}_${item[keyId]}`]: item.quantity,
                    areaId: item.area_id,
                });
            }
        });
    }
    return taskAreas;
};

export const formatterIndexMatrix_task = <
    T extends
        | Type_sch_index_taskAreaProductTypesMatrix_taskProductType
        | Type_sch_index_taskAreaResource_forMatrix_taskResource,
>(
    data: T[] | null,
    fieldName: string,
    keyId: keyof T,
): Type_index_taskAreaMatrix_task => {
    const taskProductType: Type_index_taskAreaMatrix_task = {};
    if (data) {
        data.forEach((item: T) => {
            taskProductType[`${fieldName}_${item[keyId]}`] = item.quantity;
        });
    }
    return taskProductType;
};
