import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Type_Filter_Areas, Type_Params } from "src/api/filters";
import {
    formatterCreateArea,
    formatterFlattenSelectAreas,
    formatterIndexArea,
    formatterIndexAreas,
    formatterIndexAreasForMatrix,
    formatterSelectListAreas,
    formatterShowArea,
    formatterUpdateArea,
    formatterUpdateAreaForMatrix,
} from "src/api/tms-projects/areas/formatters";
import {
    createArea,
    duplicateArea,
    exportAreas,
    importAreas,
    indexAreas,
    removeArea,
    selectListAreas,
    showArea,
    updateArea,
} from "src/api/tms-projects/areas/services";
import {
    Type_index_area,
    Type_index_area_forMatrix,
    Type_post_area,
    Type_prj_put_area,
    Type_put_area,
    Type_select_area,
    Type_show_area,
} from "src/api/tms-projects/areas/types";
import { AreaKeys } from "src/api/tms-projects/keys";
import { SMALL_STALE_TIME } from "src/configurations/app";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { downloadBlobFile } from "src/utils/file";

//INDEX
export const useIndexAreas = (
    filters: Type_Filter_Areas = {},
    enabled: boolean = true,
) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [AreaKeys.INDEX, requestConfig, filters],
        queryFn: () => indexAreas(requestConfig, filters),
        enabled:
            !!requestConfig.projectId &&
            !!requestConfig.subProjectId &&
            enabled,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexAreas");
            }
            return formatterIndexAreas(data.data.data);
        },
    });
};

export const useIndexAreasForMatrix = (
    filters: Type_Filter_Areas = {},
    params: Type_Params = {},
) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [AreaKeys.INDEX_FOR_MATRIX, requestConfig, filters, params],
        queryFn: () =>
            indexAreas(requestConfig, filters, { forMatrix: true, ...params }),
        enabled: !!requestConfig.projectId && !!requestConfig.subProjectId,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexAreasForMatrix");
            }
            return formatterIndexAreasForMatrix(data.data.data);
        },
    });
};

//SHOW
// TODO: Enlever le callback (attente du fix de l autocompleteAreasCustom)
export const useShowArea = (
    id: number,
    callback?: (area: Type_show_area) => void,
) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [AreaKeys.SHOW, id, requestConfig],
        queryFn: () => showArea(id, requestConfig),
        refetchOnWindowFocus: false,
        select: (data) => {
            callback && callback(formatterShowArea(data?.data?.data));
            return formatterShowArea(data?.data?.data);
        },
        enabled: !!requestConfig.projectId && !!id,
    });
};

//SELECT LIST

export const useSelectListAreas = (enabled = true) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [AreaKeys.SELECT_LIST, requestConfig],
        queryFn: () => selectListAreas(requestConfig),
        refetchOnWindowFocus: false,
        select: (data): Type_select_area[] => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useSelectListAreas");
            }
            return formatterSelectListAreas(data.data.data);
        },
        enabled: !!requestConfig.projectId && enabled,
    });
};

/**
 * Use by Tree components:
 * components_Teamoty/forms/select/SelectArea
 *
 */
export const useSelectAreas = (enabled = true) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [AreaKeys.SELECT, requestConfig],
        queryFn: () => selectListAreas(requestConfig),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useSelectAreas");
            }
            return formatterFlattenSelectAreas(data.data.data);
        },
        enabled: !!requestConfig.projectId && enabled,
        staleTime: SMALL_STALE_TIME,
    });
};

//CREATE
export const mutationCreateArea = (
    withInvalidation: boolean = false,
    withData: boolean = false,
) => {
    const { requestConfig } = useProject();
    const { sendEvent } = useChannel({});
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Areas",
    );
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (area: Type_post_area) =>
            createArea(formatterCreateArea(area), {
                ...requestConfig,
                withData: withData,
            }),
        onSuccess: async (data: any) => {
            if (!data?.success) {
                throw new Error("Wrong format data: mutationCreateArea");
            }

            const area =
                withData && data?.data?.data
                    ? formatterIndexArea(data.data.data)
                    : null;

            if (withInvalidation) {
                queryClient.invalidateQueries({
                    queryKey: [AreaKeys.INDEX, requestConfig],
                });
            } else if (withData && area) {
                sendEvent("addArea", {
                    ...area,
                });
            }

            addSuccess({
                description: fmt("Toast.ToastSuccessCreate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        area: area?.name,
                    },
                }),
            });

            return data;
        },
        onError: (err: any) => {
            console.debug("ERROR mutationCreateArea", err);
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};

//DUPLICATE
type Type_props_mutationDuplicateArea = {
    callback?: (area: Type_index_area) => void;
    showToast?: boolean;
    withData?: boolean;
};

export const mutationDuplicateArea = (
    { callback, showToast, withData }: Type_props_mutationDuplicateArea = {
        withData: true,
    },
) => {
    const { requestConfig } = useProject();
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Areas",
    );
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) =>
            duplicateArea(id, { ...requestConfig, withData: withData }),
        onSuccess: async (data: any) => {
            if (!data?.success) {
                throw new Error("Wrong format data: mutationDuplicateArea");
            }

            const area = data?.data?.data && formatterIndexArea(data.data.data);

            if (callback) {
                callback(area);
            } else {
                await Promise.all([
                    queryClient.invalidateQueries({
                        queryKey: [AreaKeys.INDEX, requestConfig],
                    }),
                    queryClient.invalidateQueries({
                        queryKey: [AreaKeys.INDEX_FOR_MATRIX, requestConfig],
                    }),
                ]);
            }

            if (showToast) {
                addSuccess({
                    description: fmt("Toast.ToastSuccessDuplicate", {
                        values: {
                            b: (chunks: string) => <b>{chunks}</b>,
                            area: area?.name ?? "",
                        },
                    }),
                });
            }
        },
        onError: (err: any) => {
            console.debug("ERROR mutationDuplicateArea", err);
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};

//UPDATE
export const mutationUpdateArea = (
    showToast: boolean = true,
    shouldInvalidateQueries: boolean = true,
    withData: boolean = false,
) => {
    const { requestConfig } = useProject();
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Areas",
    );
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: { id: number; data: Type_put_area }) =>
            updateArea(data.id, formatterUpdateArea(data.data), {
                ...requestConfig,
                withData: withData,
            }),
        onSuccess: async (data: any) => {
            if (!data?.success) {
                throw new Error("Wrong format data: mutationUpdateArea");
            }

            const area = data?.data?.data && formatterIndexArea(data.data.data);

            if (shouldInvalidateQueries) {
                await queryClient.invalidateQueries({
                    queryKey: [AreaKeys.INDEX, requestConfig],
                });
            }

            if (showToast) {
                addSuccess({
                    description: fmt("Toast.ToastSuccessUpdate", {
                        values: {
                            b: (chunks: string) => <b>{chunks}</b>,
                            area: area?.name ?? "",
                        },
                    }),
                });
            }
        },
        onError: (err: any) => {
            console.debug("ERROR mutationUpdateArea", err);
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};

//UPDATE MATRIX
export const mutationUpdateAreaForMatrix = (
    showToast: boolean = true,
    shouldInvalidateQueries: boolean = true,
    withData: boolean = false,
) => {
    const { requestConfig } = useProject();
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Areas",
    );
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: { id: number; data: Type_index_area_forMatrix }) =>
            updateArea(data.id, formatterUpdateAreaForMatrix(data.data), {
                ...requestConfig,
                withData: withData,
            }),
        onSuccess: async (data: any) => {
            if (!data?.success) {
                throw new Error(
                    "Wrong format data: mutationUpdateAreaForMatrix",
                );
            }

            const area = data?.data?.data && formatterIndexArea(data.data.data);

            if (shouldInvalidateQueries) {
                await queryClient.invalidateQueries({
                    queryKey: [AreaKeys.INDEX, requestConfig],
                });
            }

            if (showToast) {
                addSuccess({
                    description: fmt("Toast.ToastSuccessUpdate", {
                        values: {
                            b: (chunks: string) => <b>{chunks}</b>,
                            area: area?.name ?? "",
                        },
                    }),
                });
            }
        },
        onError: (err: any) => {
            console.debug("ERROR mutationUpdateAreaForMatrix", err);
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};

//SELECTION
export const useIndexAreasSelection = (
    callback: (area: any[]) => void,
    filters: Type_Filter_Areas = {},
    enabled: boolean = true,
) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [AreaKeys.INDEX_SELECTION, requestConfig, filters],
        queryFn: () => indexAreas(requestConfig, filters),
        refetchOnWindowFocus: false,
        onSuccess: (data): void => {
            callback(formatterIndexAreas(data?.data?.data));
        },
        enabled: Boolean(requestConfig.projectId) && enabled,
    });
};

//DELETE
export const mutationDeleteArea = (callback?: (id: number) => void) => {
    const { addSuccess, addWarning } = useToast();
    const { sendEvent } = useChannel({});
    const { formatMessageWithPartialKey: fmtError } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Areas",
    );
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => removeArea(id, requestConfig),
        onSuccess: async (data: any, id: number) => {
            if (!data?.success) {
                throw new Error("Wrong format data: mutationDeleteArea");
            }

            addSuccess({
                description: fmt("Toast.ToastSuccessDelete"),
            });

            sendEvent("deleteArea", { id: id });
            if (callback) {
                callback(id);
            } else {
                await queryClient.invalidateQueries({
                    queryKey: [AreaKeys.INDEX, requestConfig],
                });
            }
        },
        onError: (err: any) => {
            addWarning({
                description: fmtError("GenericError"),
            });
            return err;
        },
    });
};

//EXPORT
export const useExportAreas = () => {
    const { requestConfig } = useProject();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Errors");

    return useQuery({
        queryKey: [AreaKeys.EXPORT, requestConfig],
        queryFn: ({ signal }) => exportAreas(requestConfig, signal),
        refetchOnWindowFocus: false,
        onSuccess: (response): void => {
            if (!response?.success || !response?.data) {
                throw new Error("Wrong format data: useExportAreas");
            }

            downloadBlobFile(
                response.data,
                `${Date.now()}_area.xlsx`,
                response.headers["content-type"],
            );
        },
        onError: (err: any) => {
            console.debug("ERROR useExportAreas", err);
            addWarning({
                description: fmt("GenericError"),
            });
            return err;
        },
        enabled: false,
    });
};

//IMPORT
export const mutationImportAreas = () => {
    const queryClient = useQueryClient();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Errors");
    const { requestConfig } = useProject();
    const { addSuccess, addWarning } = useToast();

    return useMutation({
        mutationFn: (data: any) => importAreas(data, requestConfig),
        onSuccess: async (response) => {
            if (!response?.success || !response?.data?.data) {
                throw new Error("Wrong format data: mutationImportAreas");
            }

            addSuccess({
                description: response.data.data,
            });

            await queryClient.invalidateQueries({
                queryKey: [AreaKeys.INDEX, requestConfig],
            });
        },
        onError: (err: any) => {
            console.debug("ERROR mutationImportAreas", err);
            addWarning({
                description: fmt("GenericError"),
            });
            return err;
        },
    });
};

//STATUS
type Type_props_mutationStatusArea = {
    withData?: boolean;
};

export const mutationStatusArea = ({
    withData = false,
}: Type_props_mutationStatusArea = {}) => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: { id: number; data: Type_prj_put_area }) => {
            return updateArea(data.id, data.data, {
                ...requestConfig,
                withData: !!withData,
            });
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [AreaKeys.INDEX, requestConfig],
            });
        },
    });
};
