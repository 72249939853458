import { Box, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";

import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_MatrixSequenceAreasColumns = {
    depth: number;
    enableOrderEdition: boolean;
    withSelected?: boolean;
    withOrder?: boolean;
};

export const MatrixSequenceAreasColumns = ({
    depth,
    enableOrderEdition,
    withSelected = true,
    withOrder = true,
}: Type_MatrixSequenceAreasColumns): GridColDef[] => {
    if (!depth) return [];
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Sequences.Table",
    );

    let columnArray: GridColDef[] = [];
    for (let i = 0; i < depth; i++) {
        const index = i + 1;
        columnArray.push(
            {
                field: `area_name_${i}`,
                headerName: `${fmt("AreaName")} ${index}`,
                flex: 1,
                resizable: true,
                sortable: true,
                editable: false,
                filterable: true,
            },
            {
                field: `area_type_name_${i}`,
                headerName: `${fmt("AreaType")} ${index}`,
                flex: 1,
                resizable: true,
                sortable: true,
                editable: false,
                filterable: true,
            },
        );
    }

    columnArray = [
        ...columnArray,
        ...(withSelected
            ? [
                  {
                      field: "selected",
                      headerName: fmt("Selection"),
                      resizable: true,
                      flex: 1,
                      type: "boolean",
                      editable: true,
                      filterable: true,
                      sortable: true,
                      align: "center",
                      headerAlign: "center",
                  } as GridColDef,
              ]
            : []),
        ...(withOrder
            ? [
                  {
                      field: "order",
                      headerName: fmt("CustomOrder"),
                      filterable: false,
                      flex: 1,
                      type: "number",
                      resizable: true,
                      editable: enableOrderEdition,
                      align: "center",
                      sortable: false,
                      headerAlign: "center",
                      renderCell: (params) => {
                          const { row } = params;
                          const value = params.value;

                          const isInvalid =
                              row.selected &&
                              !value &&
                              isNaN(row.value) &&
                              enableOrderEdition;

                          return (
                              <Box
                                  position="relative"
                                  width="100%"
                                  height="100%"
                              >
                                  {isInvalid && (
                                      <Box
                                          position="absolute"
                                          top="4px"
                                          right="4px"
                                          width="8px"
                                          height="8px"
                                          bgcolor="red"
                                          borderRadius="50%"
                                          zIndex={1}
                                      />
                                  )}
                                  {enableOrderEdition ? (
                                      <Typography>{value || ""}</Typography>
                                  ) : null}
                              </Box>
                          );
                      },
                  } as GridColDef,
              ]
            : []),
    ];

    return columnArray;
};
