import { yupResolver } from "@hookform/resolvers/yup";
import { TabContext, TabPanel } from "@mui/lab";
import { Stack, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as Yup from "yup";

import {
    mutationCreateArea,
    mutationUpdateArea,
    useShowArea,
} from "src/api/tms-projects/areas";
import {
    Type_post_area,
    Type_put_area,
} from "src/api/tms-projects/areas/types";
import { AreaKeys } from "src/api/tms-projects/keys";
import { LoadingBox } from "src/components";
import { Type_drawerTab } from "src/components/Components_Common/Drawer/Header/Header.container";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import {
    DrawerTab,
    DrawerTabList,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric.style";
import { ColorPicker } from "src/components/Components_Common/forms/reactHookFormComponents/ColorPicker/ColorPicker";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import { Switch } from "src/components/Components_Common/forms/reactHookFormComponents/Switch/Switch";
import { SectionTitle } from "src/components/Components_Common/SectionTitle";
import { AutocompleteAreas } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteAreas";
import { AutocompleteDrawings } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteDrawings";
import { AutocompleteProjectAreaTypes } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteProjectAreaTypes";
import { AutocompleteResourcesGeneric } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteResourcesGeneric";
import { AutocompleteResourceTypes } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteResourceType";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useProject } from "src/contexts/project";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { getLocalStorageItem } from "src/utils/localStorageServices";

export const Schema_Area = Yup.lazy(() => {
    return Yup.object().shape({
        names: Yup.lazy(() => inputMultiLanguagesSchema()),
        color: Yup.string(),
        areaType: Yup.object()
            .shape({
                id: Yup.number(),
                name: Yup.string(),
            })
            .required(FORM_ERR_FMT.REQUIRED),
        parent: Yup.object()
            .shape({
                id: Yup.number().nullable(),
                name: Yup.string(),
            })
            .nullable(),
        drawing: Yup.object()
            .shape({
                id: Yup.number(),
                name: Yup.string(),
            })
            .nullable(),
        resource: Yup.object()
            .shape({
                id: Yup.number(),
                name: Yup.string(),
            })
            .nullable()
            .test(
                "resource-null-condition",
                FORM_ERR_FMT.REQUIRED,
                function (value) {
                    const { assignResource } = this.parent;
                    return !assignResource || (assignResource && value);
                },
            ),
        resourceType: Yup.object()
            .shape({
                id: Yup.number(),
                name: Yup.string(),
            })
            .nullable()
            .test(
                "resource-null-condition",
                FORM_ERR_FMT.REQUIRED,
                function (value) {
                    const { assignResourceType, assignResource } = this.parent;
                    return (
                        !assignResourceType ||
                        !assignResource ||
                        (assignResourceType && value)
                    );
                },
            ),
        scheduling: Yup.boolean(),
        permanentUse: Yup.boolean(),
        assignResource: Yup.boolean(),
        assignResourceType: Yup.boolean(),
    });
});

const switchSx = {
    justifyContent: "space-between",
    ".MuiTypography-root": {
        paddingLeft: 0,
    },
};

type Type_props_AreaForm = {
    onClose: () => void;
    areaIdToUpdate?: number | null;
    areaIdParent?: number | null;
    action: Type_action;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
};

export const AreaForm = ({
    onClose,
    areaIdToUpdate,
    areaIdParent,
    action,
    setIsLoading,
}: Type_props_AreaForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Area");
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();

    const { mutateAsync: mutateUpdate } =
        mutationUpdateArea(true, true, false) || {};
    const { mutateAsync: mutateCreate } = mutationCreateArea(true, false) || {};

    // get area by id
    const { isFetching, data } = useShowArea(areaIdToUpdate as number) || {};

    // get parent area by id
    const { isFetching: isFetchingParentArea, data: parentArea } =
        useShowArea(areaIdParent as number) || {};

    const { projectDefaultLanguage } = useProject();
    const language = getLocalStorageItem("language") || projectDefaultLanguage;

    const form = useForm({
        ...useFormDefaultConfig,
        defaultValues: {
            id: areaIdToUpdate,
            names: {},
            scheduling: true,
            assignResource: false,
            assignResourceType: false,
            permanentUse: false,
            areaType: null,
            parentArea: null,
            drawing: null,
            resource: null,
            resourceType: null,
        },
        values: {
            // Important: 'parent' must be defined before 'data' to avoid overwriting the parent identifier by the useShowArea request response.
            parent:
                areaIdParent && parentArea
                    ? { id: parentArea.id, name: parentArea.names[language] }
                    : null,
            ...data,
            assignResource: !!data?.resource,
            assignResourceType: !!data?.resourceType,
        },
        resolver: yupResolver<any>(Schema_Area),
    });

    //handle tabs
    const [tab, setTab] = useState<"0" | "1">("0");
    const handleChange = (event: React.SyntheticEvent, newValue: "0" | "1") => {
        setTab(newValue);
    };

    const drawerTabs = [
        { label: fmt("Header.Tabs.Area"), value: "0" },
        { label: fmt("Header.Tabs.Scheduling"), value: "1" },
    ];

    // submit functions
    const handleSubmit = async (values: Type_put_area | Type_post_area) => {
        // TODO: isDirty

        if (
            !(values as Type_put_area).assignResourceType ||
            !values.scheduling
        ) {
            values.resourceType = null;
        }
        if (
            !(values as Type_put_area).assignResourceType ||
            !(values as Type_put_area).assignResource ||
            !values.scheduling
        ) {
            values.resource = null;
        }

        try {
            setIsLoading(true);
            if (action === "update") {
                await mutateUpdate({
                    id: areaIdToUpdate!,
                    data: values as Type_put_area,
                });
            } else if (action === "create") {
                await mutateCreate({
                    names: values.names,
                    color: values.color,
                    areaType: values.areaType,
                    parentArea: values.parent,
                    parent_id: values.parent?.id,
                    drawing: values.drawing,
                    resource: values.resource,
                    resourceType: values.resourceType,
                    scheduling: values.scheduling,
                    permanentUse: values.permanentUse,
                } as Type_post_area);
            }
            onClose();
            queryClient.invalidateQueries({
                queryKey: [AreaKeys.SELECT, requestConfig],
            });
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const { watch } = form;

    const [scheduling, assignResourceType, assignResource, resourceType] =
        watch([
            "scheduling",
            "assignResourceType",
            "assignResource",
            "resourceType",
        ]);

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={"areas"}>
                <TabContext value={tab}>
                    <DrawerTabList
                        onChange={handleChange}
                        data-testid="Role-TabList"
                    >
                        {drawerTabs.map(
                            (drawerTab: Type_drawerTab, key: number) => {
                                return (
                                    <DrawerTab
                                        data-testid={`Tab-${drawerTab.value}`}
                                        label={drawerTab.label}
                                        value={drawerTab.value}
                                        key={key}
                                    />
                                );
                            },
                        )}
                    </DrawerTabList>
                    {isFetching || isFetchingParentArea ? (
                        <LoadingBox />
                    ) : (
                        <>
                            <TabPanel value={"0"} sx={{ padding: 0 }}>
                                <Stack spacing={6}>
                                    <InputMultiLanguages
                                        name="names"
                                        label={fmt("Content.Labels.Name")}
                                    />
                                    <ColorPicker
                                        name="color"
                                        label={fmt("Content.Labels.Color")}
                                        data-testid="Areas-Drawer-AddArea-color"
                                    />
                                    <SectionTitle
                                        title={fmt(
                                            "Content.SubTitles.AreaOptions",
                                        )}
                                    />
                                    <AutocompleteProjectAreaTypes
                                        name={"areaType"}
                                        label={fmt("Content.Labels.AreaType")}
                                        renderInputProps={{
                                            variant: "outlined",
                                        }}
                                    />
                                    <AutocompleteAreas
                                        label={fmt("Content.Labels.ParentArea")}
                                        name="parent"
                                        renderInputProps={{
                                            variant: "outlined",
                                        }}
                                    />
                                    <SectionTitle
                                        title={fmt(
                                            "Content.SubTitles.DrawingOptions",
                                        )}
                                    />
                                    <AutocompleteDrawings
                                        name={"drawing"}
                                        label={fmt("Content.Labels.Drawing")}
                                        renderInputProps={{
                                            variant: "outlined",
                                        }}
                                    />
                                </Stack>
                            </TabPanel>
                            <TabPanel
                                value={"1"}
                                sx={{ padding: 0, marginTop: 2 }}
                            >
                                <Stack gap={6}>
                                    <Stack
                                        flexDirection={"column"}
                                        width={"100%"}
                                    >
                                        <Stack
                                            display={"inline-flex"}
                                            flexDirection={"row"}
                                            justifyContent={"space-between"}
                                            width={"100%"}
                                        >
                                            <Typography variant={"body1"}>
                                                {fmt(
                                                    "Content.Labels.Scheduling",
                                                )}
                                            </Typography>
                                            <Switch name={"scheduling"} />
                                        </Stack>
                                        <Typography
                                            variant={"body2"}
                                            color={"sectionTitle.contrastText"}
                                        >
                                            {fmt("Content.Desc.Scheduling")}
                                        </Typography>
                                    </Stack>
                                    {scheduling && (
                                        <>
                                            <SectionTitle
                                                title={fmt(
                                                    "Content.Labels.ResourceConfiguration",
                                                )}
                                            />
                                            <Switch
                                                name="assignResourceType"
                                                label={fmt(
                                                    "Content.Labels.AssignResourceType",
                                                )}
                                                data-testid={
                                                    "Form-Switch-AssignResourceType"
                                                }
                                                sx={switchSx}
                                            />
                                        </>
                                    )}

                                    {assignResourceType && scheduling && (
                                        <>
                                            <AutocompleteResourceTypes
                                                name="resourceType"
                                                label={fmt(
                                                    "Content.Labels.AssignResourceType",
                                                )}
                                                desc={fmt(
                                                    "Content.Desc.ResourceType",
                                                )}
                                                renderInputProps={{
                                                    variant: "outlined",
                                                }}
                                            />
                                            <Switch
                                                name="assignResource"
                                                label={fmt(
                                                    "Content.Labels.AssignResource",
                                                )}
                                                data-testid={
                                                    "Form-Switch-AssignResource"
                                                }
                                                sx={switchSx}
                                            />
                                        </>
                                    )}
                                    {scheduling &&
                                        assignResource &&
                                        assignResourceType && (
                                            <>
                                                <AutocompleteResourcesGeneric
                                                    name="resource"
                                                    label={fmt(
                                                        "Content.Labels.AssignResource",
                                                    )}
                                                    desc={fmt(
                                                        "Content.Desc.Resource",
                                                    )}
                                                    renderInputProps={{
                                                        variant: "outlined",
                                                    }}
                                                    resourceType={resourceType}
                                                />
                                                <Switch
                                                    name="permanentUse"
                                                    label={fmt(
                                                        "Content.Labels.PermanentUse",
                                                    )}
                                                    data-testid={
                                                        "Form-Switch-permanentUse"
                                                    }
                                                    sx={switchSx}
                                                    caption={fmt(
                                                        "Content.Desc.PermanentUse",
                                                    )}
                                                />
                                            </>
                                        )}
                                </Stack>
                            </TabPanel>
                        </>
                    )}
                </TabContext>
            </form>
        </FormProvider>
    );
};
