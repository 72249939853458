import { useEffect, useState } from "react";

import { useIndexTaskAreaProductTypes } from "src/api/tms-scheduling/taskAreaProductTypes";
import { Type_index_taskAreaProductType } from "src/api/tms-scheduling/taskAreaProductTypes/types";
import { Empty } from "src/components";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";
import { MEDIUM_STALE_TIME } from "src/configurations/app";
import { ProductTypesList } from "src/forms/tasks/AccordionForms/ProductTypes/ProductTypesTask";
import {
    Type_event_productTypesTaskArea,
    useChannel,
} from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

type Type_Props_ProductTypesTaskArea = Type_ReadonlyComponent & {
    taskId: number;
    areaId: number;
    taskAreaId: number;
};

export const ProductTypesTaskArea = ({
    taskId,
    areaId,
    taskAreaId,
    readonly,
}: Type_Props_ProductTypesTaskArea) => {
    const [productsTaskArea, setProductsTaskArea] = useState<
        Type_index_taskAreaProductType[]
    >([]);
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.ProductType",
    );
    const { openPaper } = useContextualDrawer();

    const { data, isLoading } = useIndexTaskAreaProductTypes(
        {
            taskAreaId: taskAreaId,
            areaId: areaId,
            taskId: taskId,
        },
        MEDIUM_STALE_TIME,
    );

    useEffect(() => {
        if (data && !isLoading) {
            setProductsTaskArea(data);
        }
    }, [isLoading]);

    useChannel({
        eventHandler: ({ event, data }) => {
            const productTypesTasksData =
                data as Type_event_productTypesTaskArea;
            if (event === "updateProductTypesTaskArea") {
                setProductsTaskArea(productTypesTasksData);
            }
        },
    });

    const handleNewProductTypeTaskArea = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        openPaper("productTypeTaskArea", {
            taskId: taskId,
            taskAreaId: taskAreaId,
            areaId: areaId,
            focus: false,
        });
    };

    return isLoading ? (
        <FullSpinner />
    ) : productsTaskArea.length > 0 ? (
        <ProductTypesList products={productsTaskArea} />
    ) : (
        <Empty
            message={fmt("EmptyProductTypeMessage")}
            {...(!readonly && {
                actionMessage: fmt("AddFirstProductType"),
                onClick: handleNewProductTypeTaskArea,
            })}
            dataTestIdRef={"ProductType"}
        />
    );
};
