import { GridRowModel } from "@mui/x-data-grid-premium";
import { useMemo, useState } from "react";

import {
    mutationDeleteArea,
    mutationDuplicateArea,
    mutationStatusArea,
    mutationUpdateArea,
    useIndexAreas,
} from "src/api/tms-projects/areas";
import {
    Type_index_area,
    Type_prj_put_area,
} from "src/api/tms-projects/areas/types";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { TableAreasComponent } from "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_Areas/Table_Areas/TableAreas.component";

type Type_Props_TableAreasContainer = {
    handleAreaEdit: (row: GridRowModel) => void;
    handleAreaCreate: () => void;
    handleCloseAreaDrawer: () => void;
    handleOpenAreaDrawer: (id: number | null, action: Type_action) => void;
    page: string;
    openAreaDrawer: boolean;
    selectedArea: number | null;
    action: Type_action;
};

export type Type_modalDeleteArea = {
    isOpen: boolean;
    props?: Type_index_area;
};

export type Type_changeVisibilityAreaParams = Pick<
    Type_index_area,
    "id" | "show"
>;

export const TableAreasContainer = ({
    page,
    handleAreaEdit,
    handleAreaCreate,
    handleCloseAreaDrawer,
    handleOpenAreaDrawer,
    selectedArea,
    openAreaDrawer,
    action,
}: Type_Props_TableAreasContainer) => {
    const { isFetching, data: fetchedAreas = [] } = useIndexAreas() || {};

    // DELETE
    const [modalDelete, setModalDelete] = useState<Type_modalDeleteArea>({
        isOpen: false,
    });

    //STATUS
    const { mutate: mutateUpdateArea } = mutationStatusArea() || {};

    const changeStatusArea = (area: Type_index_area) => {
        mutateUpdateArea({
            id: area.id,
            data: {
                enabled: !area.enabled,
            } as Type_prj_put_area,
        });
    };

    //DELETE
    const { mutateAsync: mutateDeleteArea } = mutationDeleteArea() || {};

    const deleteArea = (id: number) => {
        mutateDeleteArea(id);
    };

    // DUPLICATE
    const { mutate: mutateDuplicateArea, isLoading: isLoadingDuplicate } =
        mutationDuplicateArea({ withData: false });

    const duplicateArea = (id: number) => {
        mutateDuplicateArea(id);
    };

    const { mutate: mutateVisibilityArea } = mutationUpdateArea();

    const changeVisibilityArea = ({
        id,
        show,
    }: Type_changeVisibilityAreaParams) => {
        mutateVisibilityArea({ id, data: { show: !show } });
    };

    const disableChildrenIfParentDisabled = (
        areas: Type_index_area[],
    ): Type_index_area[] => {
        return areas.map((area) => {
            if (!area.enabled) {
                return {
                    ...area,
                    enabled: false,
                    children: area.children.map((child) => ({
                        ...child,
                        enabled: false,
                        hasParentDisabled: true,
                        children: disableChildrenIfParentDisabled(
                            child.children,
                        ).map((subChild) => ({
                            ...subChild,
                            enabled: false,
                            hasParentDisabled: true,
                        })),
                    })),
                };
            }

            return {
                ...area,
                children: disableChildrenIfParentDisabled(area.children),
            };
        });
    };

    const hideChildrenIfParentHidden = (
        areas: Type_index_area[],
    ): Type_index_area[] => {
        return areas.map((area) => {
            if (!area.show) {
                return {
                    ...area,
                    children: area.children.map((child) => ({
                        ...child,
                        hasParentHidden: true,
                        children: hideChildrenIfParentHidden(
                            child.children,
                        ).map((subChild) => ({
                            ...subChild,
                            hasParentHidden: true,
                        })),
                    })),
                };
            }

            return {
                ...area,
                children: hideChildrenIfParentHidden(area.children),
            };
        });
    };

    const updatedAreas = useMemo(() => {
        if (isFetching || !fetchedAreas.length) return [];
        return hideChildrenIfParentHidden(
            disableChildrenIfParentDisabled(fetchedAreas),
        );
    }, [isFetching, fetchedAreas]);

    return (
        <TableAreasComponent
            isFetching={isFetching || isLoadingDuplicate}
            areas={updatedAreas || []}
            page={page}
            changeStatusArea={changeStatusArea}
            modalDelete={modalDelete}
            setModalDelete={setModalDelete}
            deleteArea={deleteArea}
            duplicateArea={duplicateArea}
            changeVisibilityArea={changeVisibilityArea}
            handleAreaEdit={handleAreaEdit}
            handleAreaCreate={handleAreaCreate}
            handleCloseAreaDrawer={handleCloseAreaDrawer}
            selectedArea={selectedArea}
            openAreaDrawer={openAreaDrawer}
            handleOpenAreaDrawer={handleOpenAreaDrawer}
            action={action}
        />
    );
};
