import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { ReportTemplatesPath, Url } from "src/api/paths";
import {
    Type_sch_post_reportTemplate,
    Type_sch_put_reportTemplate,
} from "src/api/tms-scheduling/reportTemplate/types";

export const indexReportTemplates = (
    requestConfig: Type_RequestConfig,
    signal?: AbortSignal,
): GetReturnType => {
    return get(
        `${Url.SCHEDULING}${ReportTemplatesPath.REPORT_TEMPLATES}`,
        requestConfig,
        null,
        signal,
    );
};

export const showReportTemplate = (
    requestConfig: Type_RequestConfig,
    reportId: number,
    signal?: AbortSignal,
): GetReturnType => {
    return get(
        `${Url.SCHEDULING}${ReportTemplatesPath.REPORT_TEMPLATES}/${reportId}`,
        requestConfig,
        null,
        signal,
    );
};

export const downloadReportTemplate = (
    requestConfig: Type_RequestConfig,
    reportId?: number,
    signal?: AbortSignal,
): GetReturnType => {
    return get(
        `${Url.SCHEDULING}${ReportTemplatesPath.REPORT_TEMPLATES}/${reportId}/file`,
        requestConfig,
        null,
        signal,
    );
};

export const createReportTemplate = (
    report: Type_sch_post_reportTemplate,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        report,
        `${Url.SCHEDULING}${ReportTemplatesPath.REPORT_TEMPLATES}`,
        requestConfig,
    );
};

export const updateReportTemplate = (
    id: number,
    report: Type_sch_put_reportTemplate,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        report,
        `${Url.SCHEDULING}${ReportTemplatesPath.REPORT_TEMPLATES}/${id}`,
        requestConfig,
    );
};

export const statusReportTemplate = (
    id: number,
    report: Type_sch_put_reportTemplate,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        report,
        `${Url.SCHEDULING}${ReportTemplatesPath.REPORT_TEMPLATES}/${id}`,
        requestConfig,
    );
};

export const deleteReportTemplate = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        `${Url.SCHEDULING}${ReportTemplatesPath.REPORT_TEMPLATES}/${id}`,
        requestConfig,
    );
};

export const selectListReportTemplate = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        `${Url.SCHEDULING}${ReportTemplatesPath.REPORT_TEMPLATES}/${id}`,
        requestConfig,
    );
};
