import { styled } from "@mui/material/styles";
import React from "react";
import { Outlet } from "react-router-dom";

import { Spinner } from "src/components/Components_Common";
import { TopBar } from "src/components/Components_Teamoty/TopBar";
import { useProject } from "src/contexts/project";

const Styled_LayoutDashboardContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    height: "100vh",
    width: "100vw",
}));

const Content = styled("div")(() => ({
    flexGrow: 1,
    overflow: "hidden",
    display: "flex",
}));

const Styled_SpinnerContainer = styled("div")(() => ({
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
}));

export const LayoutDashboard = () => {
    const { isLoading } = useProject();

    return (
        <Styled_LayoutDashboardContainer data-testid="LayoutDashboard">
            {isLoading ? (
                <Styled_SpinnerContainer>
                    <Spinner size="large" />
                </Styled_SpinnerContainer>
            ) : (
                <>
                    <TopBar />
                    <Content data-testid="LayoutDashboard_Content">
                        <Outlet />
                    </Content>
                </>
            )}
        </Styled_LayoutDashboardContainer>
    );
};
