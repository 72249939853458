import { Box, Toolbar as MuiToolbar } from "@mui/material";

import { mutationUpsertTaskArea } from "src/api/tms-scheduling/taskArea";
import { Styled_AppBar } from "src/components/Components_Teamoty/views/ViewPlanning/PlanningToolbar";
import { LeftToolbar } from "src/components/Components_Teamoty/views/ViewPlanning/toolbar/LeftToolbar";
import { RightToolbar } from "src/components/Components_Teamoty/views/ViewPlanning/toolbar/RightToolbar";
import { useProject } from "src/contexts/project";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

export const GanttToolbar = () => {
    const { projectDefaultLanguage } = useProject();
    const { openPaper } = useContextualDrawer();

    ///////////////////////////////////////
    ///         Queries                 ///
    ///////////////////////////////////////

    const { mutateAsync } = mutationUpsertTaskArea();

    ///////////////////////////////////////
    ///         Actions                 ///
    ///////////////////////////////////////

    const handleAddTaskClick = async () => {
        await mutateAsync({
            // Default values
            names: {
                [projectDefaultLanguage as string]: "New task",
            },
            duration: 1, // 1 day
        }).then((data) => {
            if (data.success && data?.data?.data?.id) {
                openPaper("ganttTask", { id: data.data.data.id }, true);
            }
        });
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Styled_AppBar position="static" elevation={0}>
                <MuiToolbar variant="dense">
                    <LeftToolbar handleAddTaskClick={handleAddTaskClick} />
                    <Box sx={{ flexGrow: 1 }} />
                    <RightToolbar />
                </MuiToolbar>
            </Styled_AppBar>
        </Box>
    );
};
