import React, { useState } from "react";

import {
    DrawerGeneric,
    Type_action,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { AreaForm } from "src/forms/areas/AreaForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_AreaDrawer = {
    areaIdToUpdate: number | null;
    areaIdParent?: number | null;
    formId: string;
    page: string;
    onClose: any;
    open: boolean;
    action: Type_action;
};

export const AreaDrawer = ({
    formId,
    page,
    onClose,
    open,
    action,
    areaIdToUpdate,
    areaIdParent,
}: Type_Props_AreaDrawer) => {
    const [isLoading, setIsLoading] = useState(false);

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.Area.Header.Titles",
    );

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            dataTestIdTitle={`Area-Drawer-${action}-Title`}
            title={fmt(action === "update" ? "Update" : "Create")}
            isFormLoading={isLoading}
            page={page}
            formId={formId}
        >
            <AreaForm
                onClose={onClose}
                action={action}
                setIsLoading={setIsLoading}
                areaIdToUpdate={areaIdToUpdate}
                areaIdParent={areaIdParent}
            />
        </DrawerGeneric>
    );
};
